import { useIsMounted } from '../utils/ReactUtils.js'
import { Fragment, useContext, useEffect, useMemo, useCallback, useState } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { CircularProgress, Stack, FormControl, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, Box, Grid, Paper, IconButton, Button, Typography, Radio, Slide, Collapse, InputLabel } from '@mui/material'
import { Edit, EditOutlined } from '@mui/icons-material'
import UserSearch from '../form/UserSearch.js'
import { capitalizeFirst } from '../utils/TextUtils.js'
import Event from '../utils/EventNames.js'
import bus from '../utils/EventBus.js'
import axios from 'axios'
import { getRequestConfig } from '../auth/auth.js'
import { ApiGateway } from '../config/config.js';
import ContactAutocomplete from '../facilityManagement/ContactAutocomplete.js'
import RoleContext from '../auth/RoleContext.js'
import { ROLES } from '../auth/role.js'
import ContactSearch from '../form/ContactSearch.js'

export default function ReferencesTable(props = {}) {

  const roleDetails = useContext(RoleContext)

  const isMounted = useIsMounted()
  const [selectedRows, setSelectedRows] = useState([])
  const [reassignDialogOpen, setReassignDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [editDialogState, setEditDialogState] = useState({})
  const [reassignToContact, setReassignToContact] = useState()

  const isQsePlus = roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])
  const isBottler = !isQsePlus && roleDetails.hasRole([ROLES.BOTTLER_CORP, ROLES.BOTTLER])

  useEffect(() => {
    setSelectedRows([])
    setEditDialogState({})
    setReassignToContact()
  }, [props.rows, props.user])

  const handleSelectRow = useCallback((id) => {
    const _selectedRows = [...selectedRows]
    const selectedIndex = _selectedRows.indexOf(id)
    if (selectedIndex > -1) {
      _selectedRows.splice(selectedIndex, 1)
    } else {
      _selectedRows.push(id)
    }
    setSelectedRows(_selectedRows)
  }, [selectedRows])

  const selectionState = useMemo(() => {
    if (selectedRows.length === 0) return 'none'
    if (props.rows?.length === selectedRows.length) return 'all'
    return 'some'
  }, [props.rows, selectedRows])

  const handleSelectAllRows = useMemo(() => {
    return () => {
      if (selectionState === 'some' || selectionState === 'none') {
        const _selectedRows = props.rows?.map(a => a.id)
        setSelectedRows(_selectedRows)
      }
      if (selectionState === 'all') {
        setSelectedRows([])
      }
    }
  }, [props.rows, selectionState])

  const table = useMaterialReactTable({
    layoutMode: 'grid',
    columnFilterModeOptions: [
      'fuzzy',
      'greaterThan',
      'lessThan'
    ],
    columns: [
      {
        id: 'selected',
        size: 40,
        Cell: ({ row }) => {
          return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Checkbox
              checked={selectedRows.includes(row.original.id)}
              onChange={() => handleSelectRow(row.original.id)}
              sx={{
                color: 'gray',
                paddingTop: '0px',
                paddingBottom: '0px',
              }}
              size='small'
            />
          </div>
        },
        Header: ({ row }) => {
          return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Checkbox
              indeterminate={selectionState === 'some' ? true : undefined}
              checked={selectionState === 'all' ? true : selectionState === 'none' ? false : false}
              onChange={handleSelectAllRows}
              sx={{
                color: 'gray',
                paddingTop: '0px',
                paddingBottom: '0px',
              }}
              size='small' />
          </div>
        }
      },
      {
        accessorKey: props.parentType,
        header: capitalizeFirst(props.parentType),
        editable: false,
        filterVariant: 'text',
        grow: true,
        Cell: ({ row }) => {
          return `${row.original?.[capitalizeFirst(props.parentType)]?.[`${props.parentType}Id`]} - ${row.original?.[capitalizeFirst(props.parentType)]?.[`${props.parentType}Name`]}`
        }
      },
      {
        accessorKey: 'contactReference',
        header: 'Contact Reference',
        editable: false,
        filterVariant: 'text',
        grow: true,
        Cell: ({ row }) => {
          return row.original.ContactType?.name
        }
      },
    ],
    data: props.rows || [],
    enableColumnActions: false,
    enablePagination: false,
    enableSorting: false,
    enableBottomToolbar: false,
    enableEditing: false,
    muiTableBodyProps: props.loading ? {
      children: [
        <tr style={{ display: 'flex', justifyContent: 'center' }}>
          <td colspan={3}>
            <div style={{
              display: 'flex',
              paddingTop: '2rem',
              paddingBottom: '2rem',
              textAlign: 'start',
              width: '100%',
              height: '300px'
            }}
            >
              <CircularProgress />
            </div>
          </td>
        </tr>
      ],
    } : undefined,
    muiTableContainerProps: {
      sx: { 
        width: '100%',
        height: '100%',
        overflowY: 'auto' ,
        maxHeight: '500px'
      }
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        handleSelectRow(row.original.id)
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.82rem',
        padding: '4px !important'
      }
    },
    renderTopToolbar: false,
  });

  const onClickEditRows = () => {
    const selectedRowData = props.rows?.filter(r => selectedRows.includes(r.id))
    setEditDialogState({
      rows: selectedRowData
    })
    setReassignDialogOpen(true)
  }

  const onClickDeleteRows = () => {
    setEditDialogState({
      rows: props.rows?.filter(r => selectedRows.includes(r.id))
    })
    setDeleteDialogOpen(true)
  }

  const onSubmitReassign = async () => {
    const rowsToReassign = props.rows?.filter(r => selectedRows.includes(r.id))

    const cb = () => {
      setReassignToContact(null)
      setReassignDialogOpen(false)
    }

    await props.onSubmitReassign(props.parentType, rowsToReassign, reassignToContact, cb)
  }

  const onSubmitDelete = async () => {
    const rowsToDelete = props.rows?.filter(r => selectedRows.includes(r.id))
    const cb = () => {
      setDeleteDialogOpen(false)
    }
    await props.onSubmitDelete(props.parentType, rowsToDelete, cb)
  }

  useEffect(() => {
    if (!reassignDialogOpen) {
      setEditDialogState({})
    }
  }, [reassignDialogOpen])

  const confirmDialogColumns = [
    {
      id: props.parentType,
      header: capitalizeFirst(props.parentType),
      grow: true,
      Cell: ({ row }) => {
        return `${row.original?.[capitalizeFirst(props.parentType)]?.[`${props.parentType}Id`]} - ${row.original?.[capitalizeFirst(props.parentType)]?.[`${props.parentType}Name`]}`
      }
    },
    {
      accessorKey: 'contactReference',
      header: 'Contact Reference',
      editable: false,
      filterVariant: 'text',
      grow: true,
      Cell: ({ row }) => {
        return row.original.ContactType?.name
      }
    }
  ]

  const selectedReferences = `Selected Reference${selectedRows.length > 1 ? 's' : ''}`

  return <>
    {/* Reassign Dialog */}
    <Dialog
      open={reassignDialogOpen}
      onClose={() => {
        setReassignToContact(null)
        setReassignDialogOpen(false)
      }}
    >
      <DialogTitle>Reassign {selectedRows.length} {selectedReferences}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>

            <Stack direction='column' spacing={1}>
              <Typography variant='body' fontWeight={600} color='rgba(52, 58, 64, 0.8)'>Remove {selectedReferences} From:</Typography>
              <Typography variant='body' className='pill'>{props.user?.name} {'<'}{props.user?.email?.toLowerCase()}{'>'}</Typography>
              <Typography variant='body' fontWeight={600} color='rgba(52, 58, 64, 0.8)'>Reassign {selectedReferences} To:</Typography>
            </Stack>

            <FormControl fullWidth size='small'>
              {!props.isInternal && <ContactSearch
                onChange={(newValue) => {
                  setReassignToContact(newValue)
                }}
                selected={reassignToContact}
                getOptionLabel={(option) => option ? `${option.name} <${option.email}>` : ""}
              />}
              {props.isInternal && <UserSearch
                havingRole
                onChange={(newValue) => {
                  setReassignToContact(newValue)
                }}
                selected={reassignToContact}
                getOptionLabel={(option) => option ? `${option.firstName} ${option.lastName} <${option.email}>` : ""}
              />}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div style={{ overflowY: 'auto' }}>
              <MaterialReactTable
                layoutMode='grid'
                pageCount={1}
                columns={confirmDialogColumns}
                enablePagination={false}
                data={props.rows?.filter(r => selectedRows.includes(r.id)) || []}
                renderTopToolbar={false}
                enableSorting={false}
                enableColumnActions={false}
                renderBottomToolbar={false}
              />
            </div>
          </Grid>

        </Grid>

      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => {
          setReassignToContact(null)
          setReassignDialogOpen(false)
        }}>Cancel</Button>
        <Button variant='outlined' disabled={!reassignToContact} onClick={onSubmitReassign}>Reassign</Button>
      </DialogActions>
    </Dialog>

    {/* Delete Dialog */}
    <Dialog
      open={deleteDialogOpen}
      onClose={() => setDeleteDialogOpen(false)}
    >
      <DialogTitle>Delete {selectedRows.length} selected references</DialogTitle>
      <DialogContent>
        <Typography variant='h6' color='error'>
          This action cannont be undone.
        </Typography>
        {/* <Typography variant='body'>
          Are you sure you want to delete the following references?
        </Typography> */}
        <Stack direction='column' spacing={1}>
          <Typography variant='body' fontWeight={600} color='rgba(52, 58, 64, 0.8)'> Confirm you want to remove the following {selectedReferences} from:</Typography>
          <Typography variant='body' className='pill'>{props.user?.name} {'<'}{props.user?.email?.toLowerCase()}{'>'}</Typography>
        </Stack>

        <div style={{ overflowY: 'auto' }}>
          <MaterialReactTable
            layoutMode='grid'
            pageCount={1}
            columns={confirmDialogColumns}
            enablePagination={false}
            data={props.rows?.filter(r => selectedRows.includes(r.id)) || []}
            renderTopToolbar={false}
            enableSorting={false}
            enableColumnActions={false}
            renderBottomToolbar={false}
          />
        </div>

      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => {
          setDeleteDialogOpen(false)
        }}>Cancel</Button>
        <Button variant='outlined' color='error' onClick={onSubmitDelete}>YES - DELETE</Button>
      </DialogActions>
    </Dialog>

    <Grid container gap={1}>
      {/* style={{ padding: '10px' }} className='flex-rows flex-gap-xs' */}
      <Grid item xs={12} className='contents-middle' sx={{ marginTop: '6px', height: '40px', width: '100%' }}>
        <Grid container gap={1}>
          {selectionState === 'none' && <Typography variant='h7' color='GrayText'>
            Select one or more references to edit
          </Typography>}
          {selectionState !== 'none' && <Grid item xs={5}>
            <Button variant='outlined' size='small' fullWidth
              onClick={onClickDeleteRows}
            >Delete {selectedRows.length} reference{selectedRows.length > 1 ? 's' : ''}
            </Button>
          </Grid>}
          {selectionState !== 'none' && <Grid item xs={5}>
            <Button variant='outlined' size='small' fullWidth
              onClick={onClickEditRows}
            >Reassign {selectedRows.length} reference{selectedRows.length > 1 ? 's' : ''}
            </Button>
          </Grid>}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <MaterialReactTable
          table={table}
        />
      </Grid>
    </Grid>
  </>
}