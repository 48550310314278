import { useEffect, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { Troubleshoot } from '@mui/icons-material'
import Page from '../layout/Page'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import { getRequestConfig } from '../auth/auth'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import { Grid, Paper, Typography } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import ComplaintTrendDetailCard from './ComplaintTrendDetailCard'
import ComplaintTrendComplaintsList from './ComplaintTrendComplaintsList'
import { Box, Stack } from '@mui/system'
import ComplaintTrendEventsCard from './ComplaintTrendEventsCard'
import ConfirmDialog from '../form/ConfirmDialog'
import TrendNotificationsCard from './TrendNotificationsCard'

function TabPanel (props) {
  const { children, selectedTab, thisTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== thisTab}
      id={`tabpanel-${thisTab}`}
      aria-labelledby={`tab-${thisTab}`}
      {...other}
    >
      {selectedTab === thisTab && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  thisTab: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
};

export default function ComplaintTrendPage (props = {}) {
  const location = useLocation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const [valid, setValid] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [submissionError, setSubmissionError] = useState('')
  const [editMode, setEditMode] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState(false)
  
  const tabChange = (event, toTab) => {
    setSelectedTab(toTab)
  }

  const confirmCancel = () => {
    if (isDirty) { showDialog() }
    else handleCancel()
  }

  const handleCancel = () => {
    setConfirmDialog(false)
    if (editMode) {
      setEditMode(false)
      setIsDirty(false)
    } else {
      if (location.key === 'default') {
        navigate(`/complaint-trends/list`)
      } else {
        navigate(-1)
      }
    }
  }

  const complaintTrendId = params.complaintTrendId
  const [trend, setTrend ] = useState(null)
  const [complaintList, setComplaintList] = useState([])

  const [trendState, setTrendState] = useState({})


  const onTrendStateValidation = (_record, valid) => {
    setIsDirty(true)
    setValid(valid)
    setTrendState(_record)
  }

  const showDialog = () => {
    setConfirmDialog(true)
  }

  const hideDialog = () => {
    setConfirmDialog(false)
  }

  const saveTrend = async () => {
    setIsLoading(true)
    setSubmissionError('')
    try {
      const requestConfig = await getRequestConfig()
      const basePath = `${ApiGateway.complaintTrends}/${complaintTrendId}`
      const trendData = {
        ...trendState
      }
      const saveResult = await axios.put(basePath, trendData, requestConfig)
      if (saveResult.data) {
        const _trend = saveResult.data
        setTrend(_trend)
      }

    } catch (error) {
      setSubmissionError(error.response?.data)
      console.error(error)
    } finally {
      setIsLoading(false)
      setValid(false)
      setIsDirty(false)
      setEditMode(false)
    }
  }

  const initializePage = async () => {
    setIsLoading(true)
    try {
      const requestConfig = await getRequestConfig()
      const basePath = `${ApiGateway.complaintTrends}/${complaintTrendId}`

      const result = await axios.get(basePath, requestConfig)
      if (result.data) {
        const _trend = result.data
        setTrend(_trend)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const getBreadCrumb = () => (<div><span><Troubleshoot />&nbsp;Complaint&nbsp;Trend:&nbsp;{
    trend ? 
    [ trend.trendNumber,
      trend.plantCode
    ].join(' - ') : ''
    }</span></div>)

  useEffect(() => {
    initializePage()
  },[])

  return (
    <Page { ...props} breadcrumb={getBreadCrumb()} isLoading={isLoading} commandBar={
      <FormViewCommandBar 
        showClose={!editMode}
        onClose={confirmCancel}
        showSave={editMode}
        enableSave={isDirty && valid}
        onSave={saveTrend}
        showCancel={editMode} 
        showEdit={!editMode} 
        onEdit={() => setEditMode(true)}
        onCancel={confirmCancel}
        />}>
      <Paper variant='elevation' sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
        <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} hideDialog={hideDialog} handleConfirm={handleCancel} yesLabel="Discard" noLabel="Stay on Page" />
      {trend && (
        <Stack spacing={2}>
          {submissionError && 
          <Grid item sm={12} md={12} paddingBottom={3}>
            <Typography className='tis-alert-error' sx={{ color: '#FFF', padding: '5px'}} >Error occurred attempting to save: {submissionError}</Typography>
          </Grid>
          }
          <ComplaintTrendDetailCard trend={trend} editMode={ editMode } onTrendStateValidation={onTrendStateValidation} />
          
          {/* <Paper elevation={1} sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', margin: '1em auto', padding: "1em" }}> */}
            <Grid item xs={12} md={12}>
              <Tabs value={selectedTab} onChange={tabChange} >
                <Tab id={`tab-0`} label='Trend Timeline' ></Tab>
                <Tab id={`tab-1`} label={`${complaintList.length === 0 ? '' : [complaintList.length, ' '].join('')}Complaints in Trend`} ></Tab>
                <Tab id={`tab-2`} label='Email Notification Lists'></Tab>
              </Tabs>
              <TabPanel selectedTab={selectedTab} thisTab={0}>
                <ComplaintTrendEventsCard trend={trend} onEventSaved={(_event) => { initializePage() }}/>
              </TabPanel>
              <TabPanel selectedTab={selectedTab} thisTab={1}>
                <ComplaintTrendComplaintsList complaintTrendId={trend.id} complaintList={complaintList} complaintListCallback={(list) => { setComplaintList(list) }} />
              </TabPanel>
              <TabPanel selectedTab={selectedTab} thisTab={2}>
                <TrendNotificationsCard manufacturerUuid={trend.Manufacturer.uuid} ownershipUuid={trend.Manufacturer.Ownership.uuid} />
              </TabPanel>
            </Grid>
          {/* </Paper> */}
        </Stack>
        )}
      </Paper>    
    </Page>
  )
}