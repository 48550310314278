import { default as React, useEffect, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { TaskAlt } from '@mui/icons-material'
import WizardCommandBar from '../commandBar/WizardCommandBar'
import Page from '../layout/Page'
import RoleContext from '../auth/RoleContext'
import { getRequestConfig } from '../auth/auth'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import AuthorizationDetailCard from './AuthorizationDetailCard'
import AuthorizationFilesCard from './AuthorizationFilesCard'

export default function AuthorizationFilesPage(props = {}) {
  const [isLoading, setIsLoading] = useState(false)
  const [mfrProfile, setMfrProfile] = useState({})
  const [authorization, setAuthorization] = useState({})

  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  const { authorizationId } = params
  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const breadcrumb = <span><TaskAlt />&nbsp;Facility Authorization Files</span>

  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const onNext = () => {
    navigate(`/facility-authorizations/${authorization.id}/edit`)
  }

  useEffect(() => {
    (async () => {

      if (authorizationId) {
        startLoading()

        const requestConfig = await getRequestConfig()
        const basePath = `${ApiGateway.authorizations}/search`
        const search = { id: authorizationId }
        const { data } = await axios.post(basePath, search, requestConfig)
        const { results } = data
        if (results?.length) {
          setAuthorization(results[0])
        }

        stopLoading()
      }

    })()
  }, [authorizationId])

  const handleClose = () => {
    if (location.key === 'default') {
      navigate(`/facility-authorizations/list`)
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    (async () => {

      if (authorization.manufacturerId) {
        startLoading()
        const requestConfig = await getRequestConfig()
        const basePath = `${ApiGateway.manufacturerProfile}/search`
        const search = { manufacturerId: authorization.manufacturerId }
        const { data } = await axios.post(basePath, search, requestConfig)
        const { results } = data
        if (results?.length) {
          setMfrProfile(results[0])
        }

        stopLoading()
      }

    })()
  }, [authorization])

  
  // useEffect(() => {
  // }, [mfrProfile, authorization])

  return (
    <RoleContext.Consumer>
      {roleCtx => (
        <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading}
          commandBar={
            <WizardCommandBar
              loading={isLoading}
              onCancel={handleClose}
              enableNext={true}
              showNext={authorization.workflowStatus === 'DRAFT'}
              onNext={onNext}
            />
          }>

          <AuthorizationDetailCard
            mfrProfile={mfrProfile}
            authorization={authorization}
            startLoading={startLoading}
            stopLoading={stopLoading}
            readOnly
            hideLetterLink={true}
          />
          <AuthorizationFilesCard
            loading={isLoading}
            authorization={authorization}
          />

        </Page>
      )}
    </RoleContext.Consumer>
  )
}