import { default as React, useState, useContext, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Contacts, MonitorHeart } from '@mui/icons-material'
import { Tabs, Tab, Button } from '@mui/material';
import ListContacts from './ListContacts'
import ListContactTypes from './ListContactTypes'

import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import ComplaintTrendsHelp from '../help/pages/complaint-trends/ComplaintTrendsHelp';

function TabPanel(props) {
  const { children, selectedTab, thisTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== thisTab}
      id={`tabpanel-${thisTab}`}
      aria-labelledby={`tab-${thisTab}`}
      {...other}
    >
      {selectedTab === thisTab && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  thisTab: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
};

export default function ListContactsPage(props = {}) {
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const roleDetails = useContext(RoleContext)
  const [selectedTab, setSelectedTab] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams({ view: 'open', page: '1' })
  const [editMode, setEditMode] = useState(false)

  const isQsePlus = roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])
  const isBottler = !isQsePlus && roleDetails.hasRole([ROLES.BOTTLER_CORP, ROLES.BOTTLER])

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const breadcrumb = <span><Contacts />&nbsp;Contacts & Assignments</span>

  const [showFilters, setShowFilters] = useState(false)
  const [internalContacts, setInternalConatacts] = useState(null)
  const [externalContacts, setExternalConatacts] = useState(null)
  const [contactTypes, setContactTypes] = useState(null)
  const [addContactTypeDialogOpen, setAddContactTypeDialogOpen] = useState(false)

  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const tabChange = (event, toTab) => {
    setSelectedTab(toTab)
  }

  const makeSearchProps = (isInternal) => {
    return {
      Contact: {
        or: [
          'OwnershipContact.ContactType.isInternal',
          'ManufacturerContact.ContactType.isInternal'
        ],
        filters: {
          OwnershipContact: {
            ContactType: { isInternal }
          },
          ManufacturerContact: {
            ContactType: { isInternal }
          },
        }
      },
      User: {
        attributes: ['firstName', 'lastName', 'koId', 'email']
      },
      UserRole: {
        attributes: ['role_id']
      },
      Role: {
        attributes: ['roleName']
      },
      OwnershipContact: { attributes: [] },
      ManufacturerContact: { attributes: [] },
      ContactType: { attributes: [] }
    }
  }

  return (
    <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading}
      commandBar={
        <ListViewCommandBar
          showFilters={true}
          helpPageDialog={<ComplaintTrendsHelp />}
          toggleFilters={toggleFilters}
          showAdd={selectedTab === 2 && isQsePlus && editMode}
          addRecord={() => setAddContactTypeDialogOpen(true)}
          showEdit={!editMode}
          onEdit={() => setEditMode(true)}
          showCancel={editMode}
          onCancel={() => setEditMode(false)}
        />
      }>
      <Paper>
        {isQsePlus && <>
          <Tabs value={selectedTab} onChange={tabChange}>
            <Tab label='Internal Contacts' id={`tab-0`}></Tab>
            <Tab label='External Contacts' id={`tab-1`}></Tab>
            <Tab label='Contact Types' id={`tab-2`}></Tab>
          </Tabs>

          <TabPanel selectedTab={selectedTab} thisTab={0}>
            <ListContacts
              editMode={editMode}
              isInternal={true} 
              cachedContacts={internalContacts}
              cacheContacts={setInternalConatacts}
              searchFilter={makeSearchProps(true)}
              showFilters={showFilters}
              startLoading={startLoading}
              stopLoading={stopLoading}
              rowsPerPage={15}
            />
          </TabPanel>
          <TabPanel selectedTab={selectedTab} thisTab={1}>
            <ListContacts editMode={editMode} isInternal={false} cachedContacts={externalContacts} cacheContacts={setExternalConatacts} searchFilter={makeSearchProps(false)} showFilters={showFilters} startLoading={startLoading} stopLoading={stopLoading} rowsPerPage={15} />
          </TabPanel>
          <TabPanel selectedTab={selectedTab} thisTab={2}>
            <ListContactTypes addContactTypeDialogOpen={addContactTypeDialogOpen} onCloseAddContactTypesDialog={() => setAddContactTypeDialogOpen(false)} editMode={editMode} cachedContactTypes={contactTypes} cacheContactTypes={setContactTypes} showFilters={showFilters} startLoading={startLoading} stopLoading={stopLoading} rowsPerPage={15} />
          </TabPanel>
        </>}
        {isBottler && <ListContacts isInternal={false} editMode={editMode} cachedContacts={externalContacts} cacheContacts={setExternalConatacts} searchFilter={makeSearchProps(false)} showFilters={showFilters} startLoading={startLoading} stopLoading={stopLoading} rowsPerPage={15} />}
      </Paper>

    </Page>
  )
}