import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Skeleton, CardContent, Grid, Card, CardMedia, IconButton, Typography, Box, CardActionArea } from "@mui/material";
import { Add, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ArticleControls from './ArticleControls'
import { getArticleDetail, getArticles, createArticleFromTemplate } from "./data/index.js";
import { SafetyHubSharepoint } from "../config/config-current.js";
import {
  editArticle,
} from './utils.js'
import ArticleCard from "./ArticleCard.js";
import CreateArticleDialog from "./CreateArticleDialog.js";
import DeleteArticleDialog from './DeleteArticleDialog.js'
import { default as EventBus } from '../utils/EventBus'
import Event from '../utils/EventNames.js';

export default function FeaturedArticle(props = {}) {
  const navigate = useNavigate()
  const {
    editable,
    news,
    featured,
    ouCorner,
    highlighted,
    ouCornerCategory
  } = props

  const [articles, setArticles] = useState([])
  const [startIndex, setStartIndex] = useState(0)
  const [hovering, setHovering] = useState()
  const [loading, setLoading] = useState()
  const [articleDialogOpen, setArticleDialogOpen] = useState(false)
  const [articleToDelete, setArticleToDelete] = useState()
  const [deleteArticleDialogOpen, setDeleteArticleDialogOpen] = useState(false)

  const viewArticle = (article) => {
    navigate(article.title)
  }

  const prevArticle = () => {
    setStartIndex(startIndex - 1)
  };

  const nextArticle = () => {
    setStartIndex(startIndex + 1)
  };

  const visibleArticles = useMemo(() => {
    if (!articles || articles.length === 0) return [];

    const visible = [];
    for (let i = 0; i < 3; i++) {
      const index = ((startIndex + i) % articles.length + articles.length) % articles.length;
      visible.push(articles[index]);
    }

    return visible;
  }, [articles, startIndex]);

  const initialize = useCallback(async () => {
    setLoading(true)
    const pages = await getArticles({
      featured: true,
      news: true
    })
    const articlePromises = []
    for (const newsPost of pages.filter(r => r.promotionKind === 'newsPost' || r['@odata.type'] === '#microsoft.graph.newsLinkPage')) {
      if (newsPost['@odata.type'] === '#microsoft.graph.newsLinkPage') {
        articlePromises.push(new Promise.resolve({
          title: newsPost.title,
          author: newsPost.createdBy.user.displayName,
          date: new Date(newsPost.createdDateTime).toLocaleDateString(),
          image: newsPost.bannerImageWebUrl,
          isDraft: newsPost.publishingState.level
        }))
      } else {
        articlePromises.push(getArticleDetail(newsPost.id))
      }
    }
    const _articles = await Promise.all(articlePromises)
    setArticles(_articles)
    setLoading(false)
  }, [])

  useEffect(() => {
    EventBus.on(Event.REFRESH_PAGE, initialize)
    return () => {
      EventBus.removeListener(Event.REFRESH_PAGE, initialize)
    }
  }, [initialize])

  useEffect(() => {
    initialize()
  }, [initialize])

  return (<>
    <DeleteArticleDialog
      open={deleteArticleDialogOpen}
      article={articleToDelete}
      onClose={() => {
        setArticleToDelete()
        setDeleteArticleDialogOpen(false)
      }}
    />
    <CreateArticleDialog
      open={articleDialogOpen}
      onClose={() => setArticleDialogOpen(false)}
      availableTags={['hi', 'dog']}
      featured={featured}
      news={news}
      ouCorner={ouCorner}
      highlighted={highlighted}
      ouCornerCategory={ouCornerCategory}
    />
    <Grid
      container
      gap={0}
      className={editable ? "article-section" : ''}
      sx={{
        position: 'relative',
        height: '350px'
      }}
    >
      {editable && <Grid item xs={12} className="add-icon">
        <div className="article-controls-backdrop">
          <IconButton size='medium' className="icon-button">
            <Add onClick={() => setArticleDialogOpen(true)} />
          </IconButton>
        </div>
      </Grid>}
      <Grid item xs={8} sx={{ padding: '4px', height: '100%' }}>
        {loading && <Skeleton className='fit-height'></Skeleton>}
        {!loading && <Card sx={{ display: 'flex' }} className="featured-article">
          <CardActionArea
            sx={{ display: 'flex' }}
            onMouseOver={() => setHovering(0)}
            onMouseOut={() => setHovering()}
          >
            <div className="article-click-container" onClick={() => viewArticle(visibleArticles[0])}>
              <CardMedia component="img" image={visibleArticles[0]?.image} alt="Featured Article" className="featured-image" />

              <Box className="gradient-overlay" />

              <Box className="article-info">
                <Typography variant="h6" className="article-title">
                  {visibleArticles[0]?.title}
                </Typography>
                <Typography variant="body2" className="article-subtitle">
                  {visibleArticles[0]?.author} • {visibleArticles[0]?.date}
                </Typography>
              </Box>
            </div>
            <Box className="nav-buttons">
              <IconButton size='small' className="nav-button left" onClick={prevArticle}>
                <ChevronLeft sx={{ color: 'white' }} fontSize="large" />
              </IconButton>
              &nbsp;&nbsp;
              <IconButton size='small' className="nav-button right" onClick={nextArticle}>
                <ChevronRight sx={{ color: 'white' }} fontSize="large" />
              </IconButton>
            </Box>
            {editable && <ArticleControls
              isDraft={visibleArticles[0]?.isDraft}
              showAdd={false}
              hovering={hovering === 0}
              onClickEdit={() => editArticle(visibleArticles[0])}
              onClickDelete={() => {
                setArticleToDelete(visibleArticles[0])
                setDeleteArticleDialogOpen(true)
              }}
            />}
          </CardActionArea>

        </Card>}
      </Grid>

      <Grid item xs={4} sx={{ padding: '4px' }}>
        {loading && <Skeleton className='fit-height'></Skeleton>}
        {!loading && <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            flexGrow: 1,
          }}
          className="secondary-articles">

          {visibleArticles.map((article, index) => {
            if (index === 0) return undefined;
            return <ArticleCard
              editable={editable}
              key={index}
              article={article}
            />
          })}

        </Box>}
      </Grid>
    </Grid>
  </>);
}
