import { default as React, useEffect, useState, useContext } from 'react'
import { Grid, FormControl, Select, MenuItem, Typography, FormHelperText, Autocomplete, TextField } from '@mui/material'
import RoleContext from '../auth/RoleContext'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import QSEAutoComplete from '../form/QSEAutoComplete'
import { validateFieldChange } from '../form/Form'
import { getRequestConfig } from '../auth/auth'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import UserContext from '../auth/UserContext'

export default function ComplaintTrendDetailCard(props = { trend: undefined, editMode: false, onTrendStateValidation: undefined }) {
  const userDetails = useContext(UserContext)
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const roleDetails = useContext(RoleContext)

  const [trend, setTrend] = useState(false)
  const [trendDetail, setTrendDetail] = useState(props.trend)
  const [editMode, setEditMode] = useState(false)
  const [valid, setValid] = useState(false)
  const [associatedTrendOptions, setAssociatedTrendOptions] = useState([])

  const initializeTrendDetail = ( _trend ) => {
    setTrendDetail({
      manager: _trend.manager ?? undefined,
      status: _trend.status ?? '',
      includeMfi: _trend.includeMfi ?? true,
      excludeMfiReason: _trend.excludeMfiReason ?? '',
      investigationComplete: _trend.investigationComplete ?? false,
      associatedTrendId: _trend.associatedTrendId ?? ''
    })
  }

  const validateForm = (_record = false) => {
    let valid = false
    

    if ( !_record ) _record = trendDetail

    const validations = [
      _record.manager !== undefined,
      _record.status !== '',
      _record.includeMfi || ( !_record.includeMfi && 
        (
          (!['EXISTING_TREND', ''].includes(_record.excludeMfiReason))
          ||
          (_record.excludeMfiReason === 'EXISTING_TREND' && _record.associatedTrendId !== '')
        ))
    ]
    valid = validations.every(v => {
      return v
    })

    setValid(valid)
    if (props.onTrendStateValidation !== undefined) props.onTrendStateValidation(_record, valid)
    return valid
  }

  const loadAssociatedTrendChoices = async (_trend) => {
    const requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.complaintTrends}/search`
    const search = { manufacturerId: _trend.manufacturerId }
    try {
      const response = await axios.post(basePath, search, requestConfig )
      const { results } = response.data

      const options = results.reduce((p, r) => {
        if (_trend.trendNumber !== undefined && r.trendNumber !== _trend.trendNumber) {
          p.push({ 
            label: [
              r.trendNumber,
              new Date(r.trendDate).toLocaleDateString('en-US', {timeZone: 'America/New_York'}),
              r.trendIssue.split('~').join(','),
              `(${r.status})`
            ].join(' '), 
            value: r.id
          })
        }
        return p
     }, [])
      setAssociatedTrendOptions(options)

    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    setTrend(props.trend)
    if (props.trend) {
      loadAssociatedTrendChoices(props.trend)
    }
  }, [props.trend])

  useEffect(() => {
    setValid(false)
    setEditMode(props.editMode)
    initializeTrendDetail(props.trend)
  }, [props.trend, props.editMode])

  return (
    <>
    { trend && (
      // <Paper elevation={1} sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', margin: '1em auto', padding: "1em" }}>
        <Grid container rowGap={1} spacing={1}>

          <Grid item sm={12} md={6}>
            <Grid container columnGap={1} rowGap={0} spacing={1}>
              <Grid item xs={12} sm={4}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Trend Date:</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Typography fontWeight='regular'>{trend?.trendDate ? new Date(trend.trendDate).toLocaleDateString('en-US', {timeZone: 'UTC'}) : ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Trend Issue(s):</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Typography fontWeight='regular'>{(trend.trendIssue ?? '').split('~').join(', ')}</Typography>
              </Grid>
              {/* Facility */}
              <Grid item xs={12} sm={4}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Facility:</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Typography fontWeight='regular'>{trend.Manufacturer?.manufacturerId}&nbsp;-&nbsp;{trend.Manufacturer?.manufacturerName}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Ownership:</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Typography fontWeight='regular'>{trend.Manufacturer?.Ownership?.ownershipId}&nbsp;-&nbsp;{trend.Manufacturer?.Ownership?.ownershipName}</Typography>
              </Grid>
              
              {/* Trend Manager */}
              <Grid item xs={12} sm={4} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Trend Manager:</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                { !editMode && trend?.Manager &&
                  <Typography fontWeight='regular'>{[trend.Manager.firstName, trend.Manager.lastName].join(' ')}</Typography>
                }
                { editMode && <>
                  <QSEAutoComplete initializeDefaultUser={true} label={false} placeholder={'Select a Manager'} defaultUserId={ trend.manager ?? userDetails.koId } onChange={(value) => {
                    if (value !== trendDetail.manager) {                      
                      validateFieldChange('manager',value, trendDetail, setTrendDetail, validateForm)
                    }
                  }} />
                  </>
                }
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12} md={6}>
            <Grid container columnGap={0} rowGap={0} spacing={1}>
              
              {/* Workflow */}
              <Grid item xs={12} sm={3} md={5} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Trend Status:</Typography>
              </Grid>
              <Grid item xs={12} sm={8} md={7}>
                { !editMode &&
                  <Typography fontWeight='regular'> {trend.status}</Typography>
                }
                { editMode && 
                  <FormControl size='small' disabled={! editMode}>
                  <Select id='status' size='small' 
                    value={trendDetail.status} 
                    onChange={(event) => {
                      validateFieldChange('status',event.target.value, trendDetail, setTrendDetail, validateForm)
                    }}
                    >                  
                      <MenuItem value='NEW'>NEW</MenuItem>
                      <MenuItem value='OPEN'>OPEN</MenuItem>
                      <MenuItem value='CLOSED'>CLOSED</MenuItem>
                  </Select>
                  { !trendDetail.status && 
                    <FormHelperText error={true}>This field is required</FormHelperText>
                  }
                  </FormControl>
                }
              </Grid>
              <Grid item xs={12} sm={3} md={5} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Investigation Completed:</Typography>
              </Grid>
              <Grid item xs={12} sm={8} md={7}>
                { !editMode &&
                <Typography fontWeight='regular' color="text.secondary">{ trend.investigationComplete === true ? 'YES' : 'NO'}</Typography>
              }
              { editMode && 
                <FormControl size='small'>
                <Select id='investigationComplete' size='small' 
                  value={trendDetail.investigationComplete === true ? 'YES' : 'NO'} 
                  onChange={(event) => {
                    validateFieldChange('investigationComplete',event.target.value === 'YES', trendDetail, setTrendDetail, validateForm)
                  }}
                  >                  
                    <MenuItem value='YES'>YES</MenuItem>
                    <MenuItem value='NO'>NO</MenuItem>
                </Select>
                </FormControl>
              }
              </Grid>
              <Grid item xs={12} sm={3} md={5} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Include in MFI:</Typography>
              </Grid>
              <Grid item xs={12} sm={8} md={7}>
                { !editMode &&
                <Typography fontWeight='regular'>{trend.includeMfi === true ? 'YES' : 'NO'}</Typography>
                }
                
                { editMode && 
                  <FormControl size='small'>
                  <Select id='includeMfi' size='small' 
                    value={trendDetail.includeMfi === true ? 'YES' : 'NO'} 
                    onChange={(event) => {
                      const dt = { ...trendDetail }
                      dt.includeMfi = (event.target.value === 'YES')
                      if (dt.includeMfi) {
                        dt.excludeMfiReason = ''                        
                      }
                      setTrendDetail(dt)
                      validateForm(dt)
                    }}
                    >                  
                      <MenuItem value='YES'>YES</MenuItem>
                      <MenuItem value='NO'>NO</MenuItem>
                  </Select>
                  </FormControl>
                }
              </Grid>
              { trendDetail.includeMfi === false &&
                <Grid item xs={12} sm={3} md={5} alignContent={'center'}>
                  <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Reason:</Typography>
                </Grid>
              }
              {trendDetail.includeMfi === false &&
              <Grid item xs={12} sm={6}>
                { !editMode && 
                <Typography fontWeight='regular' color="text.secondary">{trendDetail.excludeMfiReason}</Typography>
                }
                { editMode &&
                <FormControl size='small' fullWidth>
                <Select id='excludeMfiReason' size='small' 
                  displayEmpty
                  fullWidth
                  required
                  value={trendDetail.excludeMfiReason} 
                  onChange={(event) => {
                    validateFieldChange('excludeMfiReason',event.target.value, trendDetail, setTrendDetail, validateForm)
                  }}
                  >
                    <MenuItem value=''>
                      <em>Select a Reason</em>
                    </MenuItem>
                    <MenuItem value='EXISTING_TREND'>Existing Trend</MenuItem>
                    <MenuItem value='SUPPLIER_FAILURE'>Supplier Failure</MenuItem>
                    <MenuItem value='OTHER'>Other</MenuItem>
                </Select>
                </FormControl>
                }
              </Grid>
              }
              { trendDetail.excludeMfiReason === 'EXISTING_TREND' && <>
                <Grid item xs={12} sm={3} md={5} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Related Trend:</Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={6.5}>
                  { !editMode &&
                  <Typography fontWeight='regular' color="text.secondary">{ 
                    associatedTrendOptions.find((o) => o.value === trendDetail.associatedTrendId )?.label ?? trendDetail.associatedTrendId
                  }</Typography>
                  }
                  { editMode && <FormControl fullWidth>
                      <Autocomplete id='associatedTrendId'
                      value={trendDetail.associatedTrendId}
                      options={associatedTrendOptions}
                      getOptionLabel={(option, optionList) => {
                        if ((option.value ?? option) === '') return ''
                        if (typeof option === 'string' || !option.label) {
                          const item = (optionList ?? associatedTrendOptions).find((p) => p.value === (option.value ?? option))
                          option = item ?? undefined
                        }
                        return !option ? undefined : option.label
                      }}
                      size='small'
                      onChange={(event, newValue) => {
                        const choice = newValue ?? event.target.value
                        const associatedId = choice?.value ?? ''
                        validateFieldChange('associatedTrendId',associatedId, trendDetail, setTrendDetail, validateForm)
                      }}
                      fullWidth
                      multiple={false}
                      ListboxProps={{
                        sx: { fontSize: '.8em !important', padding: '2px !important' }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder='Find/Select a Trend to Associate'
                          size='small'
                          required
                        />
                      )}
                      />
                    </FormControl>
                  }
                  </Grid>
                </>
                }
              </Grid>
            </Grid>
          </Grid>
      // </Paper>
    )}
  </>
  )
}