import { useState, useMemo, useEffect, useCallback } from 'react'
import { Grid, FormControlLabel, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography, IconButton, FormHelperText, Select, MenuItem } from '@mui/material'
import { LoadingButton } from '@mui/lab';
import UploadIcon from '@mui/icons-material/UploadFile'
import { handleFieldChange } from '../form/Form';
import { getResourceUploadUrl, updateFileResourceProperties, uploadResourceFile } from './data';

export default function SubmitResourceDialog({open, onClose, resourceCategories}) {
  const [loading, setLoading] = useState(false)
  const [targetFile, setTargetFile] = useState()
  const [targetFileName, setTargetFileName] = useState('')
  const [targetFileLabel, setTargetFileLabel] = useState(null)
  const [fileProperties, setFileProperties] = useState(null)

  const selectFile = ((event) => {
    const _targetFile = event.target.files[0]
    if(_targetFile) {
      setTargetFile(_targetFile)
      setTargetFileName(_targetFile.name)
      setFileProperties({ ...fileProperties, fileName: _targetFile.name, fileSize: _targetFile.size})
      setTargetFileLabel(`${_targetFile.name} | ${_targetFile.size} bytes`)
    }
  })

  const submitNewResource = async () => {
    const uploadUrl = await getResourceUploadUrl(fileProperties)
    const uploadResponseData = await uploadResourceFile(uploadUrl, fileProperties, targetFile)
    const { id: newResourceId } = uploadResponseData
    const resourcePropertyUpdate = await updateFileResourceProperties(newResourceId, fileProperties)
    dialogClose()
  }

  const dialogClose = () => {
    setTargetFile(null)
    setTargetFileName(null)
    setTargetFileLabel(null)
    setFileProperties(null)
    onClose()
  }

  return (
    <Dialog open={open} fullWidth maxWidth='md'>
      <DialogTitle><Typography variant='body1' fontWeight={'bold'}>Submit a Resource to Share</Typography></DialogTitle>
      <DialogContent sx={{padding: 2}}>
        <Grid container rowGap={2} columnGap={1} alignItems={'center'}>
        <Grid item xs={4} sm={2} textAlign={'right'}>Category:</Grid><Grid item xs={7} sm={9}>
          <Select multiple={false} 
            style={{width: '300px'}}
            placeholder={'Select a primary category'} 
            value={fileProperties?.category || ''}
            onChange={(event) => handleFieldChange('category', event.target.value, fileProperties, setFileProperties)}
            >
            { Array.from(new Set([...resourceCategories, fileProperties?.category ?? null])).filter( c => c).map( (c, idx) => {
              return (
              <MenuItem key={idx} value={c}>{c}</MenuItem>)}
            ) }
          </Select>
          </Grid>
          <Grid item xs={4} sm={2} textAlign={'right'}>Title:</Grid><Grid item xs={7} sm={9}>
            <Stack direction={'row'} alignItems={'end'} columnGap={1}>
              <TextField type='text' size='small' 
                style={{width: '300px'}}
                variant='outlined'
                value={fileProperties?.title}
                inputProps={{ maxLength: 35 }}
                onChange={(event) => handleFieldChange('title', event.target.value, fileProperties, setFileProperties)}
                />
              <FormHelperText>{(fileProperties?.title || '').length}/35</FormHelperText>
            </Stack>
          </Grid>
          <Grid item xs={4} sm={2} textAlign={'right'}>Description:</Grid><Grid item xs={7} sm={9}>
            <Stack direction={'row'} alignItems={'end'} columnGap={1}>
            <TextField type='text' multiline size='small' fullWidth 
              variant='outlined'
              value={fileProperties?.description}
              inputProps={{ maxLength: 200 }}
              onChange={(event) => handleFieldChange('description', event.target.value, fileProperties, setFileProperties)}
              />
              <FormHelperText>{(fileProperties?.description || '').length}/200</FormHelperText>
            </Stack>
          </Grid>
          <Grid item xs={4} sm={2} textAlign={'right'}>File:</Grid><Grid item xs={7} sm={9}>
            <Stack direction='row' spacing={2}>        
                    <TextField      
                      type="text"
                      size='small'
                      placeholder="Select a File"
                      disabled
                      style={{ width: targetFileLabel ? `${Math.max(Math.ceil(targetFileLabel.length * 8), 300)}px` : '300px' }}
                      value={targetFileLabel}
                      InputProps={{
                        endAdornment: (
                          <IconButton component="label" size="small">
                            <UploadIcon />
                            <input 
                              styles={{display:"none"}} 
                              hidden 
                              type="file" 
                              onChange={selectFile}
                              size='small'
                              />                
                          </IconButton>
                        ),
                      }}
                    />
                  </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <LoadingButton onClick={() => { submitNewResource(); }}>Submit</LoadingButton>
        <Button onClick={() => { dialogClose(); }}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};