import React from "react";
import { Box, LinearProgress, Typography, Modal, Backdrop } from "@mui/material";

const ProgressBarModal = ({ completed, progress, open, onClose }) => {
  const total = Math.max(completed, progress);
  const completedPercentage = total > 0 ? (completed / total) * 100 : 0;
  const progressPercentage = total > 0 ? (progress / total) * 100 : 0;

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition

      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500, // Ensure smooth transition
        sx: { backgroundColor: "rgba(0, 0, 0, 0.5)" }, // Semi-transparent background
      }}
    >
      <Box
        sx={{
          width: '100%',
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          // width: "90%",
          maxWidth: 400,
          textAlign: "center",
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Working...
        </Typography>

        <Box sx={{ width: "100%", position: "relative", display: "flex", alignItems: "center" }}>
          {/* Background Bar */}
          <LinearProgress
            variant="determinate"
            value={100}
            sx={{
              position: "absolute",
              width: "100%",
              backgroundColor: "#e0e0e0",
              "& .MuiLinearProgress-bar": { backgroundColor: "transparent" },
            }}
          />
          {/* Completed Bar */}
          <LinearProgress
            variant="determinate"
            value={completedPercentage}
            sx={{
              position: "absolute",
              width: "100%",
              "& .MuiLinearProgress-bar": { backgroundColor: "#1976d2" },
            }}
          />
          {/* Progress Bar (Overlay) */}
          <LinearProgress
            variant="determinate"
            value={progressPercentage}
            sx={{
              position: "absolute",
              width: "100%",
              "& .MuiLinearProgress-bar": { backgroundColor: "#64b5f6" },
            }}
          />
          {/* Centered Text */}
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              width: "100%",
              textAlign: "center",
              fontWeight: "bold",
              color: "black",
            }}
          >
            {/* {progress} / {total} */}
          </Typography>
        </Box>
      </Box>
      {/* </div> */}
    </Modal>
  );
};

export default ProgressBarModal;
