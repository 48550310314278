import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material';
import { deleteArticle } from './data';
import { default as bus } from '../utils/EventBus'
import Event from '../utils/EventNames.js';

export default function DeleteArticleDialog({ open, article, onClose }) {

  const startPageLoading = () => bus.emit(Event.START_PAGE_LOADING)
  const stopPageLoading = () => bus.emit(Event.STOP_PAGE_LOADING)

  const onConfirm = async () => {
    startPageLoading()
    await deleteArticle(article)
    stopPageLoading()
    onClose()
    bus.emit(Event.REFRESH_PAGE)
    bus.emit(Event.ALERT, {
      "text": 'Article Deleted.',
      "style": "success",
      "duration": 2000
    })
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Delete Article: {article?.title}</DialogTitle>
      <DialogContent dividers>
        <Typography>Are you sure you want to delete this article?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} variant="contained" color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
