import { default as React, useEffect, useState, useCallback } from 'react'
import { Grid, Stack, Skeleton, Typography, Paper, Button } from '@mui/material'
import { ChevronRight } from '@mui/icons-material'
import Page from '../layout/Page'
import { useParams, useNavigate } from 'react-router-dom'
import { getArticleByTitle } from './data'
import './Article.css'

export default function ArticleDetailPage(props = {}) {
  const navigate = useNavigate()
  const params = useParams()
  const { articleTitle } = params

  const {
    breadcrumbPrefix
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [article, setArticle] = useState({})
  const [bannerSection, setBannerSection] = useState()

  const elemRef = useCallback((node) => {
    if (node !== null) {
      const images = node.querySelectorAll([".imagePlugin"])
      images.forEach(image => {
        const url = image.getAttribute('data-imageurl')
        const width = image.getAttribute('data-width')
        const height = image.getAttribute('data-height')
        const alignment = image.getAttribute('data-alignment')
        const caption = image.getAttribute('data-captiontext')
        const figure = document.createElement('figure')
        image.parentNode.insertBefore(figure, image)

        const img = document.createElement('img')
        img.setAttribute('src', url)
        img.style.width = width + 'px'
        img.style.height = height + 'px'

        figure.appendChild(img)
        figure.className = image.className
        figure.style.width = width + 'px'
        figure.style.height = height + 'px'

        image.remove()
        
        const figcaption = document.createElement('figcaption')
        const figcaptionspan = document.createElement('span')
        figcaptionspan.innerHTML = caption
        figcaption.appendChild(figcaptionspan)
        figure.appendChild(figcaption)
      })
    }
  }, [])

  const renderWebPart = (webpart) => {
    if (webpart['@odata.type'] === '#microsoft.graph.textWebPart') {
      return <span className='webpart' ref={elemRef} dangerouslySetInnerHTML={{
        __html: webpart?.innerHtml || ''
      }} />
    }
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const _article = await getArticleByTitle(articleTitle)
      const { canvasLayout } = _article
      const _bannerSection = canvasLayout.horizontalSections.find(section => {
        return section?.columns?.some(col => col.webparts.some(wp => wp.data.title === 'Banner'))
      })

      const bannerSectionIndex = canvasLayout.horizontalSections.indexOf(_bannerSection)
      const __bannerSection = canvasLayout.horizontalSections.splice(bannerSectionIndex, 1)[0]
      setBannerSection(__bannerSection)
      setArticle(_article)
      setIsLoading(false)
    })()
  }, [articleTitle])

  const goBack = () => {
    navigate(-1)
  }

  const breadcrumb = <div><span><Button onClick={goBack}>{breadcrumbPrefix}</Button> <ChevronRight /> {articleTitle}</span></div>
  return (
    <Page {...props} breadcrumb={breadcrumb} isLoading={false}>

      <Paper
        component="form"
        sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', margin: '1em auto' }}
      >
        <div
          style={{
            width: '106%',
            marginLeft: '-3%',
            height: '380px',
            marginTop: '-80px',
            backgroundImage: `url("${article.image}")`,
            backgroundColor: 'white',
            backgroundSize: '100% auto',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            flexDirection: 'column-reverse'
          }}
        >
          <div style={{
            padding: '16px',
            backgroundColor: 'white',
            borderRadius: '2px',
            marginLeft: '6%',
            marginBottom: '-2px',
            width: '88%',
            display: 'flex',
            flexDirection: 'column-reverse',
            zIndex: 1,
          }}>
            <Typography variant='caption'>{article?.createdBy?.user?.displayName || <br />}</Typography>
            <Typography variant='h6'>{articleTitle}</Typography>
          </div>
          {isLoading && <Skeleton
            animation='wave'
            sx={{
              position: 'absolute',
              width: '106%',
              marginLeft: '-3%',
              height: '380px',
              marginTop: '-80px',
            }} />}
        </div>

        {isLoading && <Stack
          gap={2}
          sx={{
            margin: '20px',
            width: 'calc(94%)',
            marginLeft: 'calc(3%)',
            height: '60px',
          }}>
          <Skeleton width='100%' />
          <Stack gap={2} direction='row'
            sx={{
              width: '100%',
              height: '200px',
            }}>
            <Skeleton sx={{ flexGrow: 2 }} />
            <Skeleton sx={{ flexGrow: 1 }} />
          </Stack>
          <Stack gap={2} direction='row'
            sx={{
              width: '100%',
              height: '200px',
            }}>
            <Skeleton sx={{ flexGrow: 1 }} />
            <Skeleton sx={{ flexGrow: 2 }} />
          </Stack>
        </Stack>

        }

        {!isLoading && <div style={{
          backgroundColor: 'white',
          width: '100%',
          height: '100%',
          paddingLeft: 'calc(16px + 4%)',
          paddingRight: 'calc(16px + 4%)',
        }} className='article-body'>
          {article?.canvasLayout?.horizontalSections.map(horizontalSection => {
            return <Grid container className={`horizontalSection ${horizontalSection.layout}`}>
              {horizontalSection.columns.map(column => {
                return <Grid sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
                  item xs={column.width} className={`column`}>
                  {column.webparts.map(renderWebPart)}
                </Grid>
              })}
            </Grid>
          })}
        </div>}
      </Paper>
    </Page>
  )
}