import { Box, Grid, Paper, IconButton, Button, Typography, Radio, Slide, Collapse } from '@mui/material'
import PropTypes from 'prop-types';

export default function TabPanel (props) {
  const { children, selectedTab, thisTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== thisTab}
      id={`tabpanel-${thisTab}`}
      aria-labelledby={`tab-${thisTab}`}
      {...other}
    >
      {selectedTab === thisTab && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  thisTab: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
};