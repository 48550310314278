import { useMsal } from '@azure/msal-react'
import { useContext, useEffect, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { FactCheck } from '@mui/icons-material'
import { ROLES } from '../auth/role'
import axios from 'axios'
import { ApiGateway } from '../config/config'

import Page from '../layout/Page'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import { Autocomplete, Button, Card, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import { DateField, DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'

import { handleFieldChange, handleSelectChange, handleTextChange } from '../form/Form'
import qfsModels from './models/QualityAndFSModels'
import esModels from './models/EnvironmentalAndSafetyModels'
import { getRequestConfig, getToken } from '../auth/auth'
import RoleContext from '../auth/RoleContext'

import { loadAssessmentItems } from './context/AssessmentContext'
import TabbedAssessmentSummary from './summary/TabbedAssessmentSummary'
import ConfirmDialog from '../form/ConfirmDialog'
import { BU_TYPES, ASMT_STATUS } from '../utils/constants'
import LinearAssessmentSummary from './summary/LinearAssessmentSummary'
import QSEAutoComplete from '../form/QSEAutoComplete'

const assessmentModels = [...qfsModels, ...esModels]

export default function AssessmentGroupPage (props = {}) {
  const { instance } = useMsal()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const roleDetails = useContext(RoleContext)

  const typeLabels = {
    bu: 'OU Assessment',
    technical: 'Technical Visit'
  }

  const [assessmentId, setAssessmentId] = useState(params.assessmentId)
  const [manufacturerProfileId, setManufacturerProfileId] = useState()

  const [isDirty, setIsDirty] = useState(false)

  const [isValid, setIsValid] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [editMode, setEditMode] = useState(false)

  const [assessment, setAssessment] = useState()
  const [itemsList, setItemsList] = useState([])

  const [categoryChoices, setCategoryChoices] = useState()
  const [includedModels, setIncludedModels] = useState()
  const [submissionError, setSubmissionError] = useState()
  const [summaryView, setSummaryView] = useState('tabbed')

  const validateForm = (_newRecord = assessment) => {
    let _isValid = true
    if (_newRecord.assessmentType === 'technical' && _newRecord.filter.length === 0) {
      console.log('no valid due to filter')
      _isValid = false
    }
    if (!_newRecord.manufacturerId || _newRecord.manufacturerId == '0') {
      console.log('no valid due to manufacturerId')
      _isValid = false
    }
    if (!_newRecord.qseManager || _newRecord.assessors.length === 0) {
      console.log('no valid due to assessors')
      _isValid = false
    }

    if (!_newRecord.startDate) {
      console.log('no valid due to start date')
      _isValid = false
    }

    if (!validateDays(_newRecord.estimatedDays)) {
      console.log('not valid due to estimated days')
      _isValid = false
    }

    setIsValid(_isValid)
    return _isValid
  }

  const validateDays = (days = undefined) => {
    const a = days === undefined ? assessment.estimatedDays : days
    const valid = a !== undefined
      && parseInt(a) > 0
      && parseInt(a) == a
      && !isNaN(a)
      
    return valid
  }

  const setDirty = () => {
    setIsDirty(true)
  }

  const setClean = () => {
    setIsDirty(false)
    setIsValid(false)
  }

  const onSave = async () => {
    if (! validateForm()) return
    setIsLoading(true)
    setSubmissionError()
    try {
      const basePath = `${ApiGateway.assessments}/${assessmentId}`
      const requestConfig = await getRequestConfig()
      const result = await axios.post(basePath, assessment, requestConfig)

      // leave edit mode
      handleCancel()

    } catch (error) {
      setSubmissionError(error.response?.data ?? error.message ?? 'Unknown Error')
      console.error(error)
    } finally {
      setIsLoading(false)
    }
    
    
  }

  const [qseManagerOptions, setQseManagerOptions] = useState()
  const [assessorOptions, setAssessorOptions] = useState([])
  const [manufacturerProfileList, setManufacturerProfileList] = useState()

  const loadOptionLists = async(requestConfig) => {
    if ( !requestConfig ) requestConfig = await getRequestConfig()
    
    const qsePath = `${ApiGateway.user}/search`
    const mfrProfilesPath = `${ApiGateway.manufacturerProfile}/search`    
    const roleId = ROLES.QSE_MGR.id

    const responses = await Promise.all([
      axios.post(qsePath, { roleId }, requestConfig),
      axios.post(mfrProfilesPath, {}, requestConfig)
    ])
    const qseResults = responses[0]
    const mfrProfilesResults = responses[1]

    if(qseResults.data && qseResults.data.length) {
      setAssessorOptions(qseResults.data.filter((a) => a.isActive !== false))
      setQseManagerOptions(qseResults.data.map((q) => {return {label: [q.firstName, q.lastName].join(' '), value: q.koId}}))
    }
    if (mfrProfilesResults.data) {
      const { results } = mfrProfilesResults.data
      setManufacturerProfileList(results)
    }

  }

  const getAssessment = async (requestConfig) => {
    if ( !requestConfig ) requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.assessments}/${assessmentId}`
    
    const responses = await Promise.all([
      axios.get(basePath, requestConfig),
      loadAssessmentItems(assessmentId, requestConfig),
      loadOptionLists(requestConfig)
    ])
    const result = responses[0]
    const _itemsList = responses[1]

    if (result.data) {
      
      let filterOptions = []
      if (result.data.filter) {
        filterOptions = JSON.parse(result.data.filter)
      }

      setManufacturerProfileId(result.data.ManufacturerProfile.id)
      const _assessment = structuredClone(result.data)
      //delete _assessment.ManufacturerProfile
      _assessment.assessors = _assessment.BuAssessmentAssessors.map((a) => a.Users[0])
      _assessment.filter = (_assessment.filter && _assessment.filter.length) ? JSON.parse(_assessment.filter) : undefined
      setAssessment(_assessment)
      setItemsList(_itemsList)

      const _categoryChoices = {}
      const _includedModels = assessmentModels.reduce((p, c) => {
        const included = _assessment.assessmentType === 'bu' || filterOptions.includes(c.props.id)
        _categoryChoices[c.props.id] = included
        if (included) {
          p.push(c)
        }
        return p
      }, [])

      setIncludedModels(_includedModels)
      setCategoryChoices(_categoryChoices)
    }
  }

  const confirmCancel = () => {
    if (isDirty) { showDialog() }
    else handleCancel()
  }

  const handleCancel = async () => {
    setEditMode(false)
    initializePage()
    hideDialog()
  }

  const handleClose = () => {
    if (location.key === 'default') {
      navigate(`/my-assessments`)
    } else {
      navigate(-1)
    }
  }

  const [confirmDialog, setConfirmDialog] = useState(false)

  const showDialog = () => {
    setConfirmDialog(true)
  }

  const hideDialog = () => {
    setConfirmDialog(false)
  }

  const setAssessmentFilter = (choices) => {
    const assessmentFilter = assessmentModels.reduce((p,c) => {
      if (choices[c.props.id] === true) {
        p.push(c.props.id)
      }
      return p
    }, [])
    const _newRecord = {
      ...assessment,
      filter: assessmentFilter
    }
    setAssessment(_newRecord)
    validateForm(_newRecord)
  }

  const handleCategorySelection = (event) => {
    const newChoices = {      
        ...categoryChoices,
        [event.target.name]: event.target.checked      
    }
    setCategoryChoices(newChoices)
    setAssessmentFilter(newChoices)
    setDirty()
  }

  const checkCategorySelectionRequiredError = () => {
    return assessment.filter && assessment.filter.length === 0
  }

  const onEdit = () => {
    setEditMode(true)
  }

  const getAssessorName = (koId) => {
    const result = assessorOptions.find((a) => a.koId === koId)
    if (result) return [result?.firstName ?? koId, result?.lastName ?? ''].join(' ')
    return undefined
  }

  const closeConfirmDangerDialog = () => {
    setConfirmDangerDialog(confirmDangerDialogStub)
  }

  const confirmDangerDialogStub = {
    open: false,
    hideDialog: closeConfirmDangerDialog,
    title: 'WARNING! Cannot undo...',
    text: '',
    yesLabel: 'CONTINUE (FINAL)',
    yesButtonStyle: {backgroundColor: 'darkred'},
    noLabel: 'Go Back',
    noButtonVariant: 'contained',
    noButtonStyle: {backgroundColor: 'darkgreen'},
    handleConfirm: undefined,    
    isWorking: false
  }

  const handleConfirmCancelAssessment = async (value) => {    
    const requestConfig = await getRequestConfig()
    const basePath = `assessments/${assessmentId}/cancel-assessment`
    try {
      const requestBody = {
        assessmentId,
        statusComments: value,
        status: ASMT_STATUS.CANCELED
      }      
      await axios.post(`${ApiGateway.domain}/${basePath}`, requestBody, requestConfig)
      closeConfirmDangerDialog()
      handleClose()
    } catch (error) {
      console.error(error)
      closeConfirmDangerDialog()
      confirmCancelAssessment()
    }
  }
  const handleConfirmFinalizeAssessment = async (value) => {
    const requestConfig = await getRequestConfig()
    const basePath = `assessments/${assessmentId}/finalize-assessment`
    try {
      const requestBody = {
        assessmentId,
        status: ASMT_STATUS.FINALIZED
      }      
      await axios.post(`${ApiGateway.domain}/${basePath}`, requestBody, requestConfig)

      closeConfirmDangerDialog()
      handleClose()
    } catch (error) {
      console.error(error)
      closeConfirmDangerDialog()
      confirmFinalizeAssessment()
    }
  }

  const syncAssessmentFitnessIndex = async (value) => {
    const requestConfig = await getRequestConfig()
    const basePath = `assessments/${assessmentId}/sync-assessment`
    try {
      const requestBody = {
        assessmentId
      }      
      await axios.post(`${ApiGateway.domain}/${basePath}`, requestBody, requestConfig)

    } catch (error) {
      console.error(error)
    }
  }

  const [confirmDangerDialog, setConfirmDangerDialog] = useState(confirmDangerDialogStub)

  const confirmCancelAssessment = () => {
    setConfirmDangerDialog({
      ...confirmDangerDialogStub,
      requireInputValue: true,
      inputValue: undefined,
      text: "Cancelling an assessment is a PERMANENT action. Enter a reason for cancellation before continuing.",      
      handleConfirm: handleConfirmCancelAssessment,
      open: true      
    })
  }

  const confirmFinalizeAssessment = () => {
    setConfirmDangerDialog({
      ...confirmDangerDialogStub,
      title: 'Finalize this assessment?',
      text: "This action declares the assessment and findings fully complete and true, prevents further changes, and cannot be undone. Please confirm.",
      handleConfirm: handleConfirmFinalizeAssessment,
      open: true
    })
  }


  const getBreadcrumb = () => (<div><span><FactCheck />&nbsp;{typeLabels[assessment.assessmentType]}</span></div>)

  const initializePage = async () => {
    setIsLoading(true)
    await getAssessment()
    setClean()
    setIsLoading(false)
  }

  const isEditable = () => {
    return (
      assessment &&
      [ASMT_STATUS.NEW, ASMT_STATUS.ACTIVE, ASMT_STATUS.COMPLETED].includes(assessment.status) &&
      roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN])
    )
  }

  const isCanceled = () => {
    return (assessment.status === ASMT_STATUS.CANCELED)
  }

  useEffect(() => {
    if (! assessment) {
      initializePage()
    }
  }, [editMode])

  return (
    <>
      { assessment && 
      <Page { ...props} breadcrumb={getBreadcrumb()} isLoading={isLoading} commandBar={
          <FormViewCommandBar  onSave={onSave} onCancel={confirmCancel} onClose={handleClose} onEdit={onEdit}
            showClose={ !editMode }
            showCancel={ editMode }
            showEdit={ !editMode && isEditable() }
            showSave={ editMode && isEditable() }
            enableSave={ isDirty }
            actionButtons={[
              {
                label: 'Finalize Assessment',
                show: roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN])
                      && assessment 
                      && assessment.status === ASMT_STATUS.COMPLETED 
                      && !isDirty,
                enabled: !editMode,
                onClick: confirmFinalizeAssessment
              },
              {
                label: 'Sync Fitness Index',
                show: roleDetails.hasRole([ROLES.MFI_ADMIN, ROLES.ADMIN])
                      && assessment 
                      && assessment.status === ASMT_STATUS.FINALIZED 
                      && !isDirty
                      && summaryView === 'tabbed',
                enabled: !editMode,
                onClick: syncAssessmentFitnessIndex
              },
              {
                label: 'View Single Page',
                show: assessment 
                      && [ASMT_STATUS.FINALIZED, ASMT_STATUS.COMPLETED].includes(assessment.status)
                      && !isDirty
                      && summaryView === 'tabbed',
                enabled: !editMode,
                onClick: () => setSummaryView('linear')
              },
              {
                label: 'View Tabbed Page',
                show: assessment 
                      && [ASMT_STATUS.FINALIZED, ASMT_STATUS.COMPLETED].includes(assessment.status)
                      && !isDirty
                      && summaryView === 'linear',
                enabled: !editMode,
                onClick: () => setSummaryView('tabbed')
              }
            ]}
          />
        }>
        <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} hideDialog={hideDialog} handleConfirm={handleCancel} yesLabel="Discard" noLabel="Stay on Page"/>
        <ConfirmDialog { ...confirmDangerDialog } ></ConfirmDialog>
        <Paper sx={{ border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', padding: '.5em'}}>
          <Stack spacing={1}>
          <Grid container rowGap={1}>
            {submissionError && 
            <Grid item xs={12} paddingBottom={3}>
              <Typography className='tis-alert-error' sx={{ color: '#FFF', padding: '5px'}} wrap='wrap'>Error occurred attempting to save: {submissionError}</Typography>
            </Grid>
            }
            <Grid item xs={12} sm={7}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3} textAlign={'right'} alignContent={'center'}><Typography fontWeight={'bold'}>Manufacturer:</Typography></Grid>
                <Grid item xs={12} sm={8}>
                  <Typography>{assessment.manufacturerId} - {assessment.ManufacturerProfile.Manufacturer.manufacturerName}</Typography></Grid>
                <Grid item xs={12} sm={3} textAlign={'right'} alignContent={'center'}><Typography fontWeight={'bold'}>Start Date:</Typography></Grid>
                <Grid item xs={12} sm={8}>
                  { (!editMode || assessment.status !== ASMT_STATUS.NEW ) && <Typography>{new Date(assessment.startDate).toLocaleDateString()}</Typography>}
                  { editMode && assessment.status === ASMT_STATUS.NEW &&
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                      format="MM/DD/YYYY" id='startDate'
                      defaultValue={assessment.startDate ? dayjs(assessment.startDate) : undefined} 
                        slotProps={{
                          textField: {
                            size: 'small',
                            required: true,
                            onBlur: (event) => {
                              
                            }
                          },
                        }}
                        onChange={(value) => {
                          handleFieldChange('startDate',value, assessment, setAssessment, setDirty)
                        }}
                      />
                      { 
                        !assessment.startDate && 
                        <FormHelperText error={true}>This field is required</FormHelperText>
                      }
                    </LocalizationProvider>
                    
                  }
                </Grid>
                <Grid item xs={12} sm={3} textAlign={'right'} alignContent={'center'}><Typography fontWeight={'bold'}>Est. # of Days:</Typography></Grid>
                <Grid item xs={12} sm={8}>
                  { (!editMode || assessment.status !== ASMT_STATUS.NEW) && <Typography>{assessment.estimatedDays}</Typography>}
                  { (editMode && assessment.status === ASMT_STATUS.NEW) &&
                    <TextField
                    id="estimatedDays"
                    size='small'
                    value={assessment.estimatedDays}
                    onChange={(event) => handleTextChange(event, assessment, setAssessment, setDirty)}
                    FormHelperTextProps={{ error: true,  hidden: !editMode || (assessment.estimatedDays !== undefined && parseInt(assessment.estimatedDays) > 0) }}
                    helperText="Must be greater than 0"
                  />
                  }
                </Grid>
                <Grid item xs={12} sm={3} textAlign={'right'} alignContent={'center'}><Typography fontWeight={'bold'}>Assessment Type:</Typography></Grid>
                <Grid item xs={12} sm={8}>
                  { (!editMode || assessment.status !== ASMT_STATUS.NEW ) && 
                  <Typography>{BU_TYPES[assessment.assessmentType]}</Typography>
                  }
                  { editMode && assessment.status === ASMT_STATUS.NEW &&
                    <FormControl fullWidth size='small' disabled={! editMode}>
                      <Select size='small' fullWidth id="assessmentType"
                      defaultValue={assessment.assessmentType}
                      onChange={(event) => handleSelectChange('assessmentType', event, assessment, setAssessment, setDirty)} >
                        <MenuItem value='bu'>{BU_TYPES['bu']}</MenuItem>
                        <MenuItem value='technical'>{BU_TYPES['technical']}</MenuItem>
                      </Select>
                  </FormControl>
                  }
                </Grid>
                { assessment.assessmentType === 'technical' && <>
                  <Grid item xs={12} sm={3} textAlign={'right'} alignContent={'start'}><Typography fontWeight={'bold'}>Including:</Typography></Grid>
                  <Grid item xs={12} sm={8}>
                    { (!editMode || assessment.status !== ASMT_STATUS.NEW ) && assessmentModels.reduce((p,a) => {
                      if (categoryChoices[a.props.id]) {
                        p.push(a)
                      }
                      return p
                    }, []).map((m, idx) => {
                        return (<Typography key={idx}>{[m.props.id, m.props.title].join(' - ')}</Typography>)
                      })
                    }
                    { (editMode && assessment.status === ASMT_STATUS.NEW ) &&                 
                      <Card variant="outlined">
                      <FormControl 
                        component="fieldset" 
                        variant="filled"
                        size='small'
                        fullWidth
                        disabled={! editMode}
                        sx={{padding: 1, maxHeight: 300, overflow: 'auto'}}
                        >
                        <FormHelperText variant='filled' error={checkCategorySelectionRequiredError()}>Select included topics**</FormHelperText>
                        <FormGroup style={{width: '100%'}} row={false}
                        id="filter"
                        >
                          { assessmentModels.map((m, idx) => {
                            return (<FormControlLabel key={idx} 
                              control={
                                <Checkbox checked={categoryChoices[m.props.id]} 
                                  onChange={handleCategorySelection} name={m.props.id} size='small' 
                                  sx={{padding: 0, paddingLeft: 1, paddingRight: 1}}/>
                              }
                              label={[m.props.id, m.props.title].join(' - ')}
                            />)
                            })
                          }
                        </FormGroup>                  
                      </FormControl>
                      </Card>
                    }
                  </Grid>
                </>
                }

                
              </Grid>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Grid container spacing={1}>
              <Grid item xs={12} sm={4} textAlign={'right'} alignContent={'center'}><Typography fontWeight={'bold'}>Status:</Typography></Grid>
                <Grid item xs={12} sm={8}>
                  { !editMode && <Typography>{assessment.status}</Typography>}
                  { editMode && 
                    <FormControl fullWidth size='small'>
                    <InputLabel>Status:</InputLabel>
                    { !isCanceled() && 
                    <Select id='status' size='small' 
                      value={assessment.status} 
                      onChange={(event) => handleSelectChange('status', event, assessment, setAssessment, setDirty)}
                      fullWidth
                      >                  
                        <MenuItem value={ASMT_STATUS.NEW}>{ASMT_STATUS.NEW}</MenuItem>
                        <MenuItem value={ASMT_STATUS.ACTIVE}>{ASMT_STATUS.ACTIVE}</MenuItem>
                        <MenuItem value={ASMT_STATUS.COMPLETED}>{ASMT_STATUS.COMPLETED}</MenuItem>
                    </Select>
                    }
                    { isCanceled() && 
                      <Select id='status' size='small' 
                      value={assessment.status}                 
                      disabled
                      fullWidth
                      >
                        <MenuItem value={ASMT_STATUS.CANCELED}>{ASMT_STATUS.CANCELED}</MenuItem>
                      </Select>
                    }
                    </FormControl>
                  }
                </Grid>
                <Grid item xs={12} sm={4} textAlign={'right'} alignContent={'center'}><Typography fontWeight={'bold'}>QSE Manager:</Typography></Grid>
                <Grid item xs={12} sm={8}>
                  { !editMode && <Typography style={{ textTransform: 'capitalize'}}>{[assessment.QSEManager.firstName,assessment.QSEManager.lastName].join(' ').toLowerCase()}</Typography>}
                  { editMode && <>
                    <FormControl fullWidth>
                      <QSEAutoComplete label={false} placeholder={'Select a Manager'} defaultUserId={ assessment.qseManager } onChange={(value) => {
                        if (value !== assessment.qseManager) {                      
                          handleFieldChange("qseManager", value, assessment, setAssessment, setDirty)
                        }
                      }} />
                      {/* <Autocomplete
                        multiple={false}
                        id="qseManager"
                        size='small'
                        options={qseManagerOptions}
                        value={assessment.qseManager}
                        openOnFocus
                        disabled={! editMode}
                        loading={ !assessorOptions }
                        getOptionLabel={(option) => {
                          if (typeof option === 'string') return getAssessorName(option)
                          return option.label ?? option
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            placeholder="QSE Manager"
                          />
                        )}
                        onChange={(_event, value) => {
                          handleFieldChange("qseManager", value, assessment, setAssessment, setDirty)
                        }}
                      /> */}
                      {
                        !assessment.qseManager && 
                        <FormHelperText error={true}>This field is required</FormHelperText>
                      }
                    </FormControl>
                    </>
                  }
                </Grid>
                <Grid item xs={12} sm={4} textAlign={'right'} alignContent={'center'}><Typography fontWeight={'bold'}>Assessor(s):</Typography></Grid>
                <Grid item xs={12} sm={8}>
                  { !editMode && assessment.BuAssessmentAssessors.map((a, idx) => 
                  <Typography style={{ textTransform: 'capitalize'}} key={idx}>{(a.Users.length) ? [a.Users[0].firstName,a.Users[0].lastName].join(' ').toLowerCase() : `UnKnown(${a.koId})`}</Typography>)}
                  { editMode && 
                    <FormControl fullWidth>
                    <Autocomplete
                      multiple
                      id="assessors"
                      size='small'
                      options={assessorOptions}
                      getOptionLabel={(option) =>[option?.firstName ?? '', option?.lastName ?? ''].join(' ')}
                      value={assessment.assessors}
                      openOnFocus
                      disabled={! editMode}
                      onChange={(_event, values) => {
                        handleFieldChange("assessors", values, assessment, setAssessment, setDirty)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                        />
                      )}
                    />
                    { 
                      (assessment.assessors.length === 0) && 
                      <FormHelperText error={true}>This field is required</FormHelperText>
                    }
                    </FormControl>                  
                  }
                </Grid>
                
              </Grid>
            </Grid>

            { editMode && 
              <Grid item xs={12} md={12}>
                <TextField label="Assessor Comments"
                  inputProps={{ maxLength: 4000 }}
                  helperText={`${assessment.assessorComments?.length ?? 0}/4000`}
                  fullWidth
                  variant='outlined'
                  id={`assessorComments`} 
                  value={assessment.assessorComments}
                  multiline rows={20}
                  onChange={(event) => handleFieldChange('assessorComments', event.target.value, assessment, setAssessment, setDirty)}
                  />
              </Grid>
            }
            { assessment && isCanceled() && 
              <Grid item xs={12} md={12}>
              <TextField label="Status Comments"
                fullWidth
                variant='standard'
                id={`statusComments`} 
                value={assessment.statusComments}
                multiline rows={10}
                disabled
                sx={{opacity: 'unset'}}
                />
            </Grid>
            }
            { (assessment && !isCanceled() && !editMode)
            &&  <>
              { summaryView === 'tabbed' && <TabbedAssessmentSummary
                assessmentItems={itemsList}
                includedModels={includedModels}
                assessment={assessment} />}
            { summaryView === 'linear' && <LinearAssessmentSummary
                assessmentItems={itemsList}
                includedModels={includedModels}
                assessment={assessment} />}
              </>
            }
            
          </Grid>
          <Grid container>
          <Grid item xs={12} md={12}><hr/></Grid>
          <Grid item xs={12} md={12} paddingBottom={2} textAlign='center'>
            { !editMode && roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
              assessment && [ASMT_STATUS.NEW, ASMT_STATUS.ACTIVE].includes(assessment.status) &&
              <Button variant='contained' color='error' onClick={confirmCancelAssessment}>Cancel Assessment</Button>
            }
            </Grid>
          </Grid>
          </Stack>
        </Paper>      
      </Page>
      }
    </>
  )

}