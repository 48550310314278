import React, { useState, useEffect, useCallback } from "react";
import { Skeleton, Grid, CardActionArea, Card, CardContent, Typography, Box, IconButton } from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import ArticleControls from './ArticleControls'
import { getArticleDetail, getArticles } from "./data/index.js";
import { editArticle, createArticle } from './utils.js'
import CreateArticleDialog from './CreateArticleDialog'
import DeleteArticleDialog from "./DeleteArticleDialog.js";
import { default as EventBus } from '../utils/EventBus'
import Event from '../utils/EventNames.js';
import { useNavigate } from "react-router-dom";

export default function TertiaryArticles(props = {}) {
  const navigate = useNavigate()
  const {
    editable,
    news,
    featured,
    ouCorner,
    highlighted,
    ouCornerCategory
  } = props

  const [articles, setArticles] = useState([])
  const [hovering, setHovering] = useState()
  const [loading, setLoading] = useState()
  const [articleDialogOpen, setArticleDialogOpen] = useState(false)
  const [deleteArticleDialogOpen, setDeleteArticleDialogOpen] = useState(false)
  const [articleToDelete, setArticleToDelete] = useState()

  const viewArticle = (article) => {
    navigate(article.title)
  }

  const initialize = useCallback(async () => {
    setLoading(true)
    const pages = await getArticles({
      news: true,
      featured: false
    })
    const articlePromises = []
    for (const newsPost of pages.filter(r => r.promotionKind === 'newsPost' || r['@odata.type'] === '#microsoft.graph.newsLinkPage')) {
      if (newsPost['@odata.type'] === '#microsoft.graph.newsLinkPage') {
        articlePromises.push(Promise.resolve({
          title: newsPost.title,
          author: newsPost.createdBy.user.displayName,
          date: new Date(newsPost.createdDateTime).toLocaleDateString(),
          image: newsPost.bannerImageWebUrl
        }))
      } else {
        articlePromises.push(getArticleDetail(newsPost.id))
      }
    }
    const _articles = await Promise.all(articlePromises)
    setArticles(_articles)
    setLoading(false)
  }, [])

  useEffect(() => {
    EventBus.on(Event.REFRESH_PAGE, initialize)
    return () => {
      EventBus.removeListener(Event.REFRESH_PAGE, initialize)
    }
  }, [initialize])

  useEffect(() => {
    initialize()
  }, [initialize])

  const containerClass = editable ? "tertiary-articles article-section" : "tertiary-articles"
  return (<>
    <DeleteArticleDialog
      open={deleteArticleDialogOpen}
      article={articleToDelete}
      onClose={() => {
        setDeleteArticleDialogOpen(false)
        setArticleToDelete()
      }}
    />
    <CreateArticleDialog
      open={articleDialogOpen}
      onClose={() => setArticleDialogOpen(false)}
      availableTags={['hi', 'dog']}
      featured={featured}
      news={news}
      ouCorner={ouCorner}
      highlighted={highlighted}
      ouCornerCategory={ouCornerCategory}
    />
    <Box className={containerClass}>
      <Grid container gap={0} sx={{ position: 'relative' }}>

        {editable && <Grid item xs={12} className="add-icon">
          <div className="article-controls-backdrop">
            <IconButton size='medium' className="icon-button">
              <Add onClick={() => setArticleDialogOpen(true)} />
            </IconButton>
          </div>
        </Grid>}
        {loading && <Grid item xs={12} sx={{ height: '200px' }}>
          <Skeleton className="fit-height"></Skeleton>
        </Grid>}
        {!loading && <Grid item xs={12}>

          <Grid container gap={1}>
            {articles.map((article, index) => (
              <Card key={index} className="tertiary-article-card">
                <CardActionArea
                  sx={{
                    display: 'flex',
                  }}
                  onMouseOver={() => setHovering(index)}
                  onMouseOut={() => setHovering()}
                  onClick={() => viewArticle(article)}
                >
                  <Box className="tertiary-article-image" style={{ backgroundImage: `url(${article.image})` }} />

                  <CardContent
                    className="tertiary-article-content"
                  >
                    <Box className="tertiary-article-header">
                      <Typography variant="h6" className="tertiary-article-title">
                        {article.title}
                      </Typography>
                    </Box>

                    <Typography variant="body2" className="tertiary-article-description">
                      {article.description}
                    </Typography>

                    <Typography variant="caption" className="tertiary-article-meta">
                      {article.author} - {article.date}
                    </Typography>

                  </CardContent>

                  {editable && <ArticleControls
                    hovering={hovering === index}
                    isDraft={article.isDraft}
                    layout='horizontal'
                    onClickAdd={createArticle}
                    onClickEdit={() => editArticle(article)}
                    onClickDelete={() => {
                      setArticleToDelete(article)
                      setDeleteArticleDialogOpen(true)
                    }}
                  />}
                </CardActionArea>

              </Card>
            ))}

          </Grid>
        </Grid>}
      </Grid>
    </Box>
  </>);
}
