import axios from 'axios'
import { SafetyHubSharepoint } from '../../config/config'
import { getToken } from '../../auth/auth'
import { removeODataContext } from '../utils'

const { siteId, listIds, templateIds } = SafetyHubSharepoint

export const Tags = [
  'Intro/About',
  'Regulatory Updates',
  'Announcements',
  'Polls/Surveys',
  'Calls to Action/Safety Alerts',
  'Monthly Safety Calendars',
  'Links to Global and OU Safety Resources',
  'RTM Corner',
  'Ergonomic Corner',
  'List of Vendors/Solutions',
  'Translation table + images',
  'KORE Corner',
  'Instructions',
  'Sample Policies',
  'Tools',
  'Helpful Resources',
  'Successful Practice Gallery',
  'Request for engagement/volunteers/etc.',
]
const getAccessToken = async () => {
  return window.sessionStorage.mockGraphToken ?? (await getToken()).accessToken
}

export const getArticles = async (search = {}, limit = 10) => {
  const {
    featured,
    highlighted,
    news,
    ouCorner,
    ouCornerCategory
  } = search

  const token = await getAccessToken()
  const options = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const filters = []
  const makeFilter = (value, fieldName) => {
    if(value === undefined) {
      return 
    } else if(value === true || value === false) {
      if(value) {
        filters.push(`fields/${fieldName} eq 1`)
      } else {
        filters.push(`(fields/${fieldName} eq 0 or fields/${fieldName} eq null)`)  
      }
    } else {
      const quote = typeof value === 'string' ? "'" : ''
      filters.push(`fields/${fieldName} eq ${quote}${value}${quote}`)  
    }
  }

  makeFilter(featured, 'QSEPortalFeatured')
  makeFilter(news, 'QSEPortalNews')
  makeFilter(highlighted, 'QSEPortalHighlighted')
  makeFilter(ouCorner, 'QSEPortalOUCorner')
  makeFilter(ouCornerCategory, 'QSEPortalOUCornerCategory')

  const beginningAnd = Object.keys(search).length ? ' and ' : ''
  const params = [
    '$orderby=createdDateTime desc',
    `$filter=${filters.join(' and ')}${beginningAnd}contentType/name eq 'Site Page'`,
    `$top=${limit}&$expand=fields`
  ]
  const url = `https://graph.microsoft.com/v1.0/sites/${siteId}/lists/${listIds.pages}/items?${params.join('&')}`
  const { data } = await axios.get(url, options)
  const { value } = data
  const pagePromises = []
  for(const item of value) {
    const etag = item['@odata.etag'];
    const pageId = etag.split(',')[0].replace(/"/g, '');
    const pageUrl = `https://graph.microsoft.com/beta/sites/${siteId}/pages/${pageId}`
    pagePromises.push(
      new Promise((f,r) => axios.get(pageUrl, options)
      .then(({data}) => f(data))
      .catch(r))
    )
  }
  const pages = await Promise.all(pagePromises)
  return pages
}

export const getArticleDetail = async (articleId) => {
  const token = await getAccessToken()
  const detailedUrl = `https://graph.microsoft.com/beta/sites/${siteId}/pages/${articleId}/microsoft.graph.sitePage?$expand=canvasLayout`
  const options = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const { data: article } = await axios.get(detailedUrl, options)
  const image = article.bannerImageWebUrl ?? article.thumbnailWebUrl.indexOf('getpreview.ashx') > -1
    ? `${article.thumbnailWebUrl}&resolution=6`
    : article.thumbnailWebUrl.replace(/large.jpg/, 'xxlarge.jpg')
  return {
    id: article.id,
    title: article.title,
    author: article.createdBy.user.displayName,
    date: new Date(article.createdDateTime).toLocaleDateString(),
    image,
    webUrl: article.webUrl,
    isDraft: article.publishingState.level === 'draft'
  }
}

export const getArticleByTitle = async (title) => {
  const token = window.sessionStorage.mockGraphToken ?? (await getToken()).accessToken
  const url = `https://graph.microsoft.com/v1.0/sites/${siteId}/pages/microsoft.graph.sitePage?$filter=title eq '${title}'&$expand=canvasLayout`
  const options = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const { data } = await axios.get(url, options)
  const article = data?.value?.[0]
  const image = article.bannerImageWebUrl ?? article.thumbnailWebUrl.indexOf('getpreview.ashx') > -1
    ? `${article.thumbnailWebUrl}&resolution=6`
    : article.thumbnailWebUrl.replace(/large.jpg/, 'xxlarge.jpg')
  return {
    ...article,
    date: new Date(article.createdDateTime).toLocaleDateString(),
    image,
  }
}

export const getPageListCategories = async (category) => {
  const token = await getAccessToken()
  const options = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const url = `https://graph.microsoft.com/v1.0/sites/${siteId}/lists/${listIds.pages}/columns`
  const { data } = await axios.get(url, options)
  const { value } = data
  const categoryColumnDef = value.find(v => v.displayName === category)
  return categoryColumnDef.choice.choices
}

export const getResourceLibraryCategories = async (accessToken) => {
  const url = `https://graph.microsoft.com/v1.0/sites/${siteId}/lists/${listIds.documentLibrary}/columns`
  const { data } = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  const { value } = data
  const categoryColumnDef = value.find(v => v.displayName === 'ResourceCategory')
  return categoryColumnDef.choice.choices
}

export const getResourceLibraryFilesByCategory = async (category, accessToken) => {
  //`https://graph.microsoft.com/v1.0/sites/groups.coca-cola.com,18f2d0a2-e373-4c4a-b16f-64378e8812b8,fceaa800-42ab-44b0-8014-8f7a0d40e45c/lists/e1031f5f-9507-444b-84a6-690bd6630e4c/items?expand=driveItem&filter=fields/ResourceCategory eq 'Emergency Response' and fields/ContentType+eq+'Document'`
  const url = `https://graph.microsoft.com/v1.0/sites/${siteId}/lists/${listIds.documentLibrary}/items?$expand=DriveItem&$filter=fields/ResourceCategory+eq+'${category}'+and+fields/ContentType+eq+'Document'+and+fields/Status+ne+'DRAFT'`
  const { data } = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  const { value } = data
  return value
}

export const getResourceUploadUrl = async (fileProperties) => {
  const accessToken = await getAccessToken()
  const url = `https://graph.microsoft.com/v1.0/sites/${siteId}/drive/items/root:/Submitted/${encodeURIComponent(fileProperties.fileName)}:/createUploadSession`
  const postBody = {
    "item": {
      "@microsoft.graph.conflictBehavior": "rename",
      "name": `${fileProperties.fileName}`
    }
  }
  const { status, data } = await axios.post(url, postBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

  const { uploadUrl } = data
  return uploadUrl
}

function getUploadRanges(fileLength, chunkSize = 320 * 1024) {
  const ranges = []

  if (fileLength <= chunkSize) {
    ranges.push({ start: 0, end: fileLength - 1, size: fileLength })
  } else {
    let start = 0
    while (start < fileLength) {
      const end = Math.min(start + chunkSize - 1, fileLength - 1)
      const size = end - start + 1
      ranges.push({ start, end, size })
      start = end + 1
    }
  }

  return ranges
}

export const uploadResourceFile = async (uploadUrl, fileProperties, file) => {
  const ranges = getUploadRanges(fileProperties.fileSize)
  let completedResponse
  try {
    for (const r of ranges) {
      const size = r.size
      const start = r.start
      const end = r.end
      const blob = file.slice(start, end + 1)
      completedResponse = await axios.put(uploadUrl, blob, { 
        headers: {
          "Content-Length": size,
          "Content-Range": `bytes ${start}-${end}/${fileProperties.fileSize}`
        }
      })  
    }
  } catch (error) {
    console.error(error)
    throw error
  }
  return completedResponse?.data
}

export const updateFileResourceProperties = async (resourceId, fileProperties) => {
  const accessToken = await getAccessToken()
  const baseUrl = `https://graph.microsoft.com/v1.0/sites/${siteId}/drive/items/${resourceId}`
  let patchBody = {
    "description": fileProperties.description,
    "title": fileProperties.title,
  }
  let patchResponse = await axios.patch(baseUrl, patchBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    }
  })
  console.log(patchResponse)

  patchBody = {
    "ResourceCategory": fileProperties.category,
    "Status": "DRAFT"
  }
  patchResponse = await axios.patch(baseUrl + '/listItem/fields', patchBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    }
  })
  console.log(patchResponse)
  return patchResponse?.data
}

// export const getResourceLibraryFolders = async (published = true, accessToken) => {
//   const folderId = published ? publishedDocumentFolderId : submittedDocumentFolderId
//   const url = `https://graph.microsoft.com/v1.0/sites/${siteId}/lists/${folderId}/items?$filter=fields/ContentType+eq+'Document'&$expand=fields`
//   const { data } = await axios.get(url, {
//     headers: {
//       Authorization: `Bearer ${accessToken}`
//     }
//   })
//   const { value } = data
//   return value
// }

// export const getResourceLibraryDocumentsInFolder = async (published = true, parentFolderId, accessToken) => {
//   const folderId = published ? publishedDocumentFolderId : submittedDocumentFolderId
//   const url = `https://graph.microsoft.com/v1.0/sites/${siteId}/lists/${folderId}/items?$filter=fields/ContentType+eq+'Document'+and+parentReference/id+eq+'${parentFolderId}'&$expand=fields`
//   const { data } = await axios.get(url, {
//     headers: {
//       Authorization: `Bearer ${accessToken}`
//     }
//   })
//   const { value } = data
//   return value
// }

export const searchLists = async(query) => {
  const token = await getAccessToken()
  const url = `https://graph.microsoft.com/v1.0/search/query`
  const body = {
    "requests": [
      {
        "entityTypes": [
          "listItem"
        ],
        "query": {
          "queryString": `${query} path:"${SafetyHubSharepoint.urls.baseSite}"`
        }
      }
    ]
  }
  const { data } = await axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return data?.value?.[0]?.hitsContainers?.[0]?.hits || []
}

export const getPageTemplateCanvasLayout = async (templatePageId) => {
  const token = await getAccessToken()
  const url = `https://graph.microsoft.com/v1.0/sites/${siteId}/pages/${templatePageId}/microsoft.graph.sitePage?$expand=canvasLayout`
  const { data } = await axios.get(url, {
    headers: {
      // Accept: 'application/json;odata.metadata=none',
      Authorization: `Bearer ${token}`
    }
  })
  const { canvasLayout } = data
  return removeODataContext(canvasLayout)
}

export const createArticle = async(article) => {
  const token = await getAccessToken()
  const url = `https://graph.microsoft.com/v1.0/sites/${siteId}/pages`
  const { data } = await axios.post(url, article, {
    headers: {
      // Accept: 'application/json;odata.metadata=none',
      Authorization: `Bearer ${token}`
    }
  })
  return data
}

export const deleteArticle = async (article) => {
  const token = await getAccessToken()
  const url = `https://graph.microsoft.com/v1.0/sites/${siteId}/pages/${article.id}`
  const { data } = await axios.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return data
}

export const createArticleFromTemplate = async (template, article, fields) => {
  console.log('template, article, fields', template, article, fields)
  if(!Object.keys(templateIds).includes(template)) {
    throw new Error(`Error: ${template} is not one of: ${Object.keys(templateIds).join(', ')}`)
  }
  const templatePageId = templateIds[template]
  const canvasLayout = await getPageTemplateCanvasLayout(templatePageId)
  const newArticle = await createArticle({ ...article, canvasLayout })
  const fieldsResult = await updateListItemFields(newArticle.id, fields)
  return newArticle
}

// response:
// {
//   "@odata.context": "https://graph.microsoft.com/v1.0/$metadata#sites('groups.coca-cola.com%2C18f2d0a2-e373-4c4a-b16f-64378e8812b8%2Cfceaa800-42ab-44b0-8014-8f7a0d40e45c')/pages/$entity",
//   "@odata.type": "#microsoft.graph.sitePage",
//   "@odata.etag": "\"{783BBAD4-D66B-4CC0-9139-5BEAD98F28CE},4\"",
//   "createdDateTime": "2025-03-31T16:13:17Z",
//   "description": "Created with graph 1",
//   "eTag": "\"{783BBAD4-D66B-4CC0-9139-5BEAD98F28CE},4\"",
//   "id": "783bbad4-d66b-4cc0-9139-5bead98f28ce",
//   "lastModifiedDateTime": "2025-03-31T16:13:18Z",
//   "name": "Created with graph 1.aspx",
//   "webUrl": "https://groups.coca-cola.com/sites/NAOUQSE/safety_nonprod/SitePages/Created%20with%20graph%201.aspx",
//   "title": "Created with graph 1",
//   "pageLayout": "article",
//   "thumbnailWebUrl": "https://media.akamai.odsp.cdn.office.net/groups.coca-cola.com/_layouts/15/images/sitepagethumbnail.png",
//   "promotionKind": "newsPost",
//   "showComments": true,
//   "showRecommendedPages": true,
//   "contentType": {
//       "id": "0x0101009D1CB255DA76424F860D91F20E6C4118007BB0515AC3F54E4EBB201BC4B68C91F6",
//       "name": "Site Page"
//   },
//   "createdBy": {
//       "user": {
//           "displayName": "Adam Monica [C]",
//           "email": "admonica@coca-cola.com"
//       }
//   },
//   "lastModifiedBy": {
//       "user": {
//           "displayName": "Adam Monica [C]",
//           "email": "admonica@coca-cola.com"
//       }
//   },
//   "parentReference": {
//       "siteId": "18f2d0a2-e373-4c4a-b16f-64378e8812b8"
//   },
//   "publishingState": {
//       "level": "checkout",
//       "versionId": "0.1",
//       "checkedOutBy": {
//           "user": {
//               "displayName": "Adam Monica [C]",
//               "email": "admonica@coca-cola.com"
//           }
//       }
//   },
//   "reactions": {}
// }

export const updateListItemFields = async(itemId, fields) => {
  const token = await getAccessToken()
  const url = `https://graph.microsoft.com/v1.0/sites/${siteId}/lists/${listIds.pages}/items/${itemId}/fields`
  const { data } = await axios.patch(url, fields, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return data
}

// $?filter=fields/ParentLeafNameLookupId eq 3

// get files by category tags
// https://graph.microsoft.com/v1.0/sites/dba6472d-10cd-4296-8f31-83add015c944/lists/aff38158-b52e-438f-9226-27c1d256fe3f/items?$filter=fields/QSEPortalCategory+eq+'scrapyard'


// Get categories:
// siteId: dba6472d-10cd-4296-8f31-83add015c944
// folderId (published): 01YMI7P73GSNXZGFKLTFFK66M6ATZ7ZT22
// https://graph.microsoft.com/beta/sites/${siteId}/drive/items/${folderId}/children?$select=id,name

// Get files for category:
// siteId: dba6472d-10cd-4296-8f31-83add015c944
// categoryId: 01YMI7P73L63J2XFO4LVHL672XTYTF2CMK
// https://graph.microsoft.com/beta/sites/${siteId}/drive/items/${categoryId}/children?$select=createdDateTime,id,lastModifiedDateTime,name,size,createdBy,lastModifiedBy,file

// Get file download link:
// siteId: dba6472d-10cd-4296-8f31-83add015c944
// fileId: 01YMI7P7YI6FWMKACU7ZGKM5BJCFE3LI24
// https://graph.microsoft.com/beta/sites/${siteId}/drive/items/${fileId}
// - @microsoft.graph.downloadUrl
