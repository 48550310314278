
import { default as React, useEffect, useState, useMemo, useCallback, useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import scrollToElement from 'scroll-to-element';

import { Skeleton, Autocomplete, Box, Grid, FormControl, FormHelperText, Radio, Tooltip, FormControlLabel, RadioGroup, Select, MenuItem, Tabs, Tab, TextField, Typography } from '@mui/material'
import { Info, MonitorHeart, ExpandLess, ExpandMore, ChevronRight } from '@mui/icons-material'
import FormViewCommandBar from '../commandBar/FormViewCommandBar.js'
import IndexChangesOverTimeChart from '../charts/IndexChangesOverTimeChart'
import Page from '../layout/Page.js'
import { handleFieldChange } from '../form/Form'
import { getRequestConfig } from '../auth/auth.js'
import { ApiGateway } from '../config/config.js'
import axios from 'axios'
import Collapse from '@mui/material/Collapse'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Stack } from '@mui/system'
import { CalendarIcon, DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'
import { GlobalStyles } from '@mui/system';
import Validate from '../validator/Validate'
import QSEAutoComplete from '../form/QSEAutoComplete.js';
import { searchManufacturers } from '../manufacturer/data.js';
import HistoryDetails from '../form/HistoryDetails.js'
import HistoryLabel from '../form/HistoryLabel.js'
import { ROLES } from '../auth/role.js'
import RoleContext from '../auth/RoleContext.js'
import { capitalizeFirst } from '../utils/TextUtils.js'

const globalStyles = (
  <GlobalStyles
    styles={{
      fieldset: {
        '&.MuiFormControl-root': {
          width: '100% !important',
          borderTop: '1px dashed #CCC'
        }
      }
    }}
  />
)

const formatPercentage = (value) => {
  return !isNaN(parseFloat(value)) ? ((value == 100 || value == 0) ?  (value + '%') : (Math.round(value * 100) / 100).toFixed(2) + '%') : <span className="text">Need Info</span>
}

const authStatusMap = {
  '0': 'Fully Authorized',
  '1': 'Conditional',
  '2': 'Limited',
  '3': 'Deauthorized',
  '4': 'Too New to Assess'
}

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#FFF', // Change the text color of the selected tab
          backgroundColor: '#d73925', // Change the background color of the selected tab
          border: '1px solid #FFF',
          borderRadius: '4px 4px 0 0',
          borderBottomWidth: 4,
          borderBottomColor: '#FFF',
          '&.Mui-selected': {
            color: '#FFF', // Change the text color of the selected tab
            backgroundColor: '#d73925', // Change the background color of the selected tab
            border: '1px solid #FFF',
            borderRadius: '4px 4px 0 0',
            borderBottomColor: '#FF0000',
            padding: '8px',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#FFF',
          height: 0
        }
      }
    }
  },
});

const optionValue = (option, value) => {
  let result
  if (option==value){
    result = true
  } else if (option && option.value && option.value==value){
    result = true
  } else if (option && option.label && option.label==value){
    result = true
  } else {
    result = false
  }
  return result
}

function AuthorizationStatusDropdown(props) {
  const [validationState, setValidationState] = useState(null);
  const [validationMessage, setValidationMessage] = useState(null);

  const validate = (event) => {
    let result = {isValid: true}
    if(props.onValidate) {
      result = Validate.validateAll(props.onValidate, props.id, event?.target?.value)
      const validationState = (result.isValid) ? null : 'error';
      const validationMessage = result.message;
      setValidationState(validationState);
      setValidationMessage(validationMessage);
    }
    return result.isValid;
  }

  const clearValidation = () => {
    setValidationState(null)
    setValidationMessage(null)
  }

  const reset = () => {
    clearValidation()
  }

  return (
    <FormControl component="fieldset" error={validationState === 'error'} variant="outlined" fullWidth>
      <Grid container spacing={2} paddingTop={1} paddingBottom={1}>
        <Grid item xs={12} sm={6} alignContent={'center'}>
          <Typography>
            {props.labelText}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl size='small' fullWidth error={validationState === 'error'} required={props.required} disabled={props.readOnly} sx={{marginLeft: 0}}>
            <Select
              labelId={`${props.id}-label`}
              size='small'
              id={props.id}
              disabled={props.disabled}
              value={props.value}
              onChange={props.onChange}
              onBlur={props.onBlur ?? validate}
              error={validationState === 'error'}
              // sx={{width:'200px'}}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="0">Fully Authorized</MenuItem>
              <MenuItem value="1">Conditional</MenuItem>
              <MenuItem value="2">Limited</MenuItem>
              <MenuItem value="3">Deauthorized</MenuItem>
              <MenuItem value="4">Too New to Assess</MenuItem>
            </Select>
            {validationState === 'error' &&
              <FormHelperText>{validationMessage}</FormHelperText>
            }
          </FormControl>
        </Grid>
        {props.changes && <Grid className='columnar-layout flex-gap-sm' item xs={12} sm={2}>
        <HistoryDetails loading={props.changeHistoryLoading} id={props.id} changes={props.changes}/></Grid>}
      </Grid>
    </FormControl>
  )
}

function MiniStatBox(props) {

  const getValue = () => {
    if(props.loading) return 'Loading...'
    return props.value?.replace ? props.value.replace(/%/g, '') : props.value;
  }

  const isPercentage = () => {
    return props.value?.indexOf ? (props.value.indexOf('%') > -1) : false;
  }

  const getClassName = () => {
    var className = 'mini-stat-box ';
    if(props.theme) {
      className += props.theme;
    } else {
      if(isPercentage()) {
        const value = getValue();
        if(value > 90) {
          className += 'bg-green';
        } else if (value >= 80) {
          className += 'bg-aqua';
        } else if (value >= 70) {
          className += 'bg-yellow';
        } else {
          className += 'bg-red';
        }
      } else {
        className += 'bg-gray';
      }
    }

    return className;
  }

  let percent = isPercentage() ? <sup>%</sup> : '';

  return (
    <div className={getClassName()}>
      {props.loading && <Skeleton style={{width: '100%'}}><h3>Loading...</h3></Skeleton>}
      {!props.loading && (props.children ? props.children : <h3>{getValue()}{percent}</h3>)}
    </div>
  )
}

function YesNoRadio(props={id: undefined, labelText: undefined, value: undefined, onBlur: undefined, onChange: undefined, onValidate: undefined, changes: undefined, disabled: undefined}) {
  const [validationState, setValidationState] = useState(null);
  const [validationMessage, setValidationMessage] = useState(null);

  const validate = (event) => {
    let result = {isValid: true}
    if(props.onValidate) {
      result = Validate.validateAll(props.onValidate, props.id, event?.target?.value)
      const validationState = (result.isValid) ? null : 'error';
      const validationMessage = result.message;
      setValidationState(validationState);
      setValidationMessage(validationMessage);
    }
    return result.isValid;
  }

  const clearValidation = () => {
    setValidationState(null)
    setValidationMessage(null)
  }

  const reset = () => {
    clearValidation()
  }

  return (
    <FormControl component="fieldset" error={validationState === 'error'} variant="outlined" fullWidth >
      <Grid container columnGap={1}>
        <Grid item xs={12} sm={6} alignContent={'center'}>
          <Typography component="label" id={props.id}>
            {props.labelText}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <RadioGroup 
            // error={validationState === 'error'}
            // helperText={validationState === 'error' ? validationMessage : ''}
            row value={[undefined, null].includes(props.value) ? null : props.value.toString()} onChange={props.onChange} onBlur={props.onBlur ?? validate}>
            <FormControlLabel sx={{padding: '2px', margin: '0px'}} value="1" control={<Radio disabled={props.disabled}/>} label="Yes" />
            <FormControlLabel sx={{padding: '2px', margin: '0px'}} value="0" control={<Radio disabled={props.disabled}/>} label="No" />
          </RadioGroup>
        </Grid>
        {props.changes && <Grid className='columnar-layout flex-gap-sm' item xs={12} sm={2}>
          <HistoryDetails loading={props.changeHistoryLoading} id={props.id} changes={props.changes}/></Grid>}
      </Grid>
    </FormControl>
  )
}

function DatePickerInput(props) {
  const [validationState, setValidationState] = useState(null);
  const [validationMessage, setValidationMessage] = useState(null);
  
  const validate = (event) => {
    let result = {isValid: true}
    if(props.onValidate) {
      result = Validate.validateAll(props.onValidate, props.id, event?.target?.value)
      const validationState = (result.isValid) ? null : 'error';
      const validationMessage = result.message;
      setValidationState(validationState);
      setValidationMessage(validationMessage);
    }
    return result.isValid;
  }

  const clearValidation = () => {
    setValidationState(null)
    setValidationMessage(null)
  }

  const reset = () => {
    clearValidation()
  }

  return (
    <FormControl component="fieldset" error={validationState === 'error'} variant="outlined" fullWidth>
      <Grid container spacing={2} paddingTop={1} paddingBottom={1}>
        <Grid item xs={12} sm={6} style={props.noLabel ? {display:"none"} : {}} alignContent={'center'}>
          <Typography>
            {props.labelText}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          {props.readOnly && props.value}
          {!props.readOnly && 
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="MM/DD/YYYY"
                error={validationState === 'error'} 
                helperText={validationState === 'error' ? validationMessage : ''}
                disabled={props.disabled} 
                placeholder={props.placeholder} 
                value={ props.value?.['$d'] ? dayjs(props.value['$d']) : props.value ? dayjs(props.value) : null } 
                onChange={props.onChange} 
                onBlur={props.onBlur ?? validate}
                // sx={{width: '200px'}}
                slotProps={{
                  textField: {
                    id: props.id,
                    size: 'small',
                    onBlur: (event) => { },
                  },
                }}
              />
            </LocalizationProvider>
          }
        </Grid>
        {props.changes && <Grid className='columnar-layout flex-gap-sm' item xs={12} sm={2}>
          <HistoryDetails loading={props.changeHistoryLoading} id={props.id} changes={props.changes}/></Grid>}
      </Grid>
    </FormControl>
  )
}



function TextInput(props) {
  const [validationState, setValidationState] = useState(null);
  const [validationMessage, setValidationMessage] = useState(null);
  
  const validate = (event) => {
    let result = {isValid: true}
    if(props.onValidate) {
      result = Validate.validateAll(props.onValidate, props.id, event?.target?.value)
      const validationState = (result.isValid) ? null : 'error';
      const validationMessage = result.message;
      setValidationState(validationState);
      setValidationMessage(validationMessage);
    }
    return result.isValid;
  }

  const clearValidation = () => {
    setValidationState(null)
    setValidationMessage(null)
  }

  const reset = () => {
    clearValidation()
  }

  return (
    <FormControl component="fieldset" error={validationState === 'error'} variant="outlined" fullWidth>
      <Grid container spacing={2} paddingTop={1} paddingBottom={1}>
         <Grid item xs={12} sm={6} style={props.noLabel ? {display:"none"} : {}} alignContent={'center'}>
          {/* <InputLabel htmlFor={props.id}> */}
          <Typography>
            {props.labelText}
            </Typography>
          {/* </InputLabel> */}
        </Grid>
        <Grid item xs={12} sm={2}>
          {props.readOnly && props.value}
          {!props.readOnly && 
              <TextField 
                fullWidth
                error={validationState === 'error'} 
                helperText={validationState === 'error' ? validationMessage : ''}
                disabled={props.disabled} 
                placeholder={props.placeholder} 
                value={props.value} 
                onChange={props.onChange} 
                onBlur={props.onBlur ?? validate}
                size='small'
                />
          }
        </Grid> 
        {props.changes && <Grid className='columnar-layout flex-gap-sm' item xs={12} sm={3}>
        <HistoryDetails loading={props.changeHistoryLoading} id={props.id} changes={props.changes}/></Grid>}
      </Grid>
    </FormControl>
  )
}

 function CertificationStatusDropdown(props) {
  const [validationState, setValidationState] = useState(null);
  const [validationMessage, setValidationMessage] = useState(null);

  const onChange = (event) => {
    if (!props.readOnly) {
      props.onChange(event);
    }
  };

  const validate = (event) => {
    let result = {isValid: true}
    if(props.onValidate) {
      result = Validate.validateAll(props.onValidate, props.id, event?.target?.value)
      const validationState = result.isValid ? null : 'error';
      const validationMessage = result.message;
      setValidationState(validationState);
      setValidationMessage(validationMessage);
    }
    return result.isValid;
  };

  const clearValidation = () => {
    setValidationState(null);
    setValidationMessage(null);
  };

  const reset = () => {
    clearValidation();
  };
  

  return (
    <FormControl component="fieldset" error={validationState === 'error'} variant="outlined" fullWidth size='small'>
      <Grid container paddingTop={1} paddingBottom={1}>
        <Grid item xs={12} sm={6} alignContent={'center'}>
          <Typography>
            {props.labelText}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl size='small' fullWidth error={validationState === 'error'} required={props.required} disabled={props.readOnly}>
            <Select
              labelId={`${props.id}-label`}
              id={props.id}
              value={props.value}
              disabled={props.disabled}
              onChange={onChange}
              onBlur={props.onBlur ?? validate}
              error={validationState === 'error'}
              size='small'
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="0">No Information</MenuItem>
              <MenuItem value="1">Pass</MenuItem>
              <MenuItem value="2">Fail</MenuItem>
              <MenuItem value="3">None</MenuItem>
              {props.includeNa && <MenuItem value="4">N/A</MenuItem>}
            </Select>
            {validationState === 'error' &&
              <FormHelperText>{validationMessage}</FormHelperText>
            }
          </FormControl>
        </Grid>
        {props.changes && <Grid className='columnar-layout flex-gap-sm' item xs={12} sm={2}>
          &nbsp;<HistoryDetails loading={props.changeHistoryLoading} id={props.id} changes={props.changes}/></Grid>}
      </Grid>
    </FormControl>
  );
}

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div 
      role="tabpanel"
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-panel-${index}`}
      style={{ 
        width: 'calc(100% - 2em)',
        marginBottom: '2em'
      }}
      {...other}
    >
      {value === index && (
        <Box width='100%' sx={{ 
          boxShadow: theme.shadows[1], 
          margin: '0 1em', 
          padding: '2em', 
          backgroundColor: '#FFF',
          verticalAlign: 'middle'
        }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

export default function FitnessIndexFormPage(props = {}) {
  const location = useLocation()
  const params = useParams()
  const roleDetails = useContext(RoleContext)
  const isAdmin = roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN])

  const [indexChangesOverTimeIcon, setIndexChangesOverTimeIcon] = useState((false) ? <ExpandLess/> : <ExpandMore/>)
  const [additionalManufacturerDetailsIcon, setAdditionalManufacturerDetailsIcon] = useState((false) ? <ExpandLess/> : <ExpandMore/>)
  
  const { fitnessIndexId, snapshotId } = params
  // const { editable } = props
  const [qseManagerOptions, setQseManagerOptions] = useState([])
  const [customerOptions, setCustomerOptions] = useState(null)
  const [processTypeOptions, setProcessTypeOptions] = useState(null)
  const [regionOptions, setRegionOptions] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [manufacturer, setManufacturer] = useState({})
  const [fitnessIndex, setFitnessIndex] = useState({})
  let isIndexChangesOverTimeExpandedExpanded = true
  let isAdditionalManufacturerDetailsExpanded = false
  const [indexChangesOverTimeExpanded, setIndexChangesOverTimeExpanded] = useState(isIndexChangesOverTimeExpandedExpanded)
  const [additionalManufacturerDetailsExpanded, setAdditionalManufacturerDetailsExpanded] = useState(isAdditionalManufacturerDetailsExpanded)
  const [changeHistory, setChangeHistory] = useState([])
  const [changeHistoryLoading, setChangeHistoryLoading] = useState(true)

  isIndexChangesOverTimeExpandedExpanded = useMemo(() => indexChangesOverTimeExpanded === true,[indexChangesOverTimeExpanded])
  isAdditionalManufacturerDetailsExpanded = useMemo(() => additionalManufacturerDetailsExpanded === true,[additionalManufacturerDetailsExpanded])

  const ALERT_DURATION = 1000
  
  const toggleIndexChangesOverTime = () => {
    setIndexChangesOverTimeIcon(isIndexChangesOverTimeExpandedExpanded ? <ExpandMore/> : <ExpandLess/>)
    setIndexChangesOverTimeExpanded(!indexChangesOverTimeExpanded)
  }

  const toggleAdditionalManufacturerDetails = () => {
    setAdditionalManufacturerDetailsIcon(isAdditionalManufacturerDetailsExpanded ? <ExpandMore/> : <ExpandLess/>)
    setAdditionalManufacturerDetailsExpanded(!additionalManufacturerDetailsExpanded)
  }

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const navigate = useNavigate()

  useEffect(() => {

  }, [])

  const buildChangeHistory = (_changeHistory, _fitnessIndex) => {
    const changedProperties = _changeHistory.map((ch) => ch.propertyName)

    const syncKeysNotPresent = Object.keys(_fitnessIndex).reduce((s, key) => {
      const re = /SyncDate/
      const prop = key.replace(re, '')
      if(re.test(key) && !changedProperties.includes(prop)) {
        s.push(prop)
      }
      return s
    }, [])

    const baseChange = _changeHistory.length ? {
      entityName: _changeHistory[0].entityName,
      entityId: _changeHistory[0].entityId,
      entityIdsJson: _changeHistory[0].entityIdsJson
     } : {}
  
    const changesFromSyncFields = syncKeysNotPresent.map((syncKey) => {
      const syncDate = _fitnessIndex[`${syncKey}SyncDate`]
      return {
        ...baseChange,
        propertyName: syncKey,
        utcTimestamp: syncDate,
        changedBy: _fitnessIndex.modifiedOn === syncDate
          ? _fitnessIndex.modifiedBy : 'ScheduledProcess',
        source: 'Scheduled Job'
      }
    })

    const result = [..._changeHistory, ...changesFromSyncFields]
    return result
  }

  useEffect(() => {
    const loadChangeHistory = async () => {
      setChangeHistoryLoading(true)
      const requestConfig = await getRequestConfig()
      const changesPath = fitnessIndex.snapshotId ? `${ApiGateway.fitnessIndexes}/snapshots/${fitnessIndex.snapshotId}/changes`
      : `${ApiGateway.fitnessIndexes}/${fitnessIndex.id}/changes`
      const changeHistoryResult = await axios.get(changesPath, requestConfig)
      setChangeHistory(buildChangeHistory(changeHistoryResult?.data?.results, fitnessIndex))
      setChangeHistoryLoading(false)
    }

    if(fitnessIndex.id || fitnessIndex.snapshotId) {
      loadChangeHistory()
    }
  }, [fitnessIndex])

  const loadFitnessIndex = useCallback(async (optionsLists) => {
    if (fitnessIndexId) {
      const requestConfig = await getRequestConfig()

      const fiPath = snapshotId ? `${ApiGateway.fitnessIndexes}/snapshots/${snapshotId}`
        : `${ApiGateway.fitnessIndexes}/${fitnessIndexId}`
      const { data } = await axios.get(fiPath, requestConfig)

      const result = data.result ?? data

      if (result?.id) {
        if(snapshotId) {
          const [customerOptions, processTypeOptions] = optionsLists
          const newFitnessIndex = {
            ...result,
            processTypes: (result.processTypes ?? '').split(',').reduce((s, pt) => {
              const found = processTypeOptions.find(pto => pto.label === pt)
              if(found) {
                s.push(found.value)
              }
              return s
            }, []),
            keyCustomers: (result.keyCustomers ?? '').split(',').reduce((s, kc) => {
              const found = customerOptions.find(co => co.label === kc)
              if(found) {
                s.push(found.value)
              }
              return s
            }, []), 
          }
          setFitnessIndex(newFitnessIndex)
        } else {
          setFitnessIndex(result)
        }

        if (result?.manufacturerId) {
          const manufacturerResult = await searchManufacturers({manufacturerId: `${result.manufacturerId}`, searchIncludes: ["ownership"]}, requestConfig)
          const manufacturer = manufacturerResult && manufacturerResult.length ? manufacturerResult[0] : undefined
          if (manufacturer) {
            setManufacturer(manufacturer)
          }
        }
      }
    } 
  }, [fitnessIndexId, snapshotId])

  const initializePage = async () => {
    setIsLoading(true)
    const optionsLists = await loadOptionLists()
    await loadFitnessIndex(optionsLists)
    setIsLoading(false)
  }

  useEffect(() => {
    initializePage()
  }, [])

  const setDirty = () => {
    setIsDirty(true)
  }

  const setClean = () => {
    setIsDirty(false)
    // setIsValid(false)
  }


  const onTabSelect = (event, selectedTab) => {
    setActiveTab(selectedTab)
  }

  const getRegions = async(requestConfig) => {

    const response = await axios.get(`${ApiGateway.fitnessIndexes}/regions`, requestConfig)
    
    let results = []
    const dataResults = response.data?.results
    if(dataResults && dataResults.length) {
      results = dataResults.map((q) => {return {label: q.regionName, value: `${q.regionId}`}})
    }
    return results
  }

  const getProcessTypes = async(requestConfig) => {

    const response = await axios.get(`${ApiGateway.fitnessIndexes}/process-types`, requestConfig)
    
    let results = []
    const dataResults = response.data?.results
    if(dataResults && dataResults.length) {
      results = dataResults.map((q) => {return {label: q.processName, value: `${q.processTypeId}`}})
    }
    return results
  }

  const getCustomers = async(requestConfig) => {

    const response = await axios.get(`${ApiGateway.fitnessIndexes}/customers`, requestConfig)
    
    let results = []
    const dataResults = response.data?.results
    if(dataResults && dataResults.length) {
      results = dataResults.map((q) => {return {label: q.customerName, value: `${q.customerId}`}})
    }
    return results
  }

  const loadOptionLists = async(requestConfig) => {
    if ( !requestConfig ) requestConfig = await getRequestConfig()
    
    const [customers, processTypes, regions] = await Promise.all([getCustomers(requestConfig), getProcessTypes(requestConfig), getRegions(requestConfig)])

    setCustomerOptions(customers)
    setProcessTypeOptions(processTypes)
    setRegionOptions(regions)

    return [customers, processTypes, regions]
  }

  const breadCrumbDate = <><ChevronRight />&nbsp;<CalendarIcon />&nbsp;{fitnessIndex.snapshotDate?.split('T')?.[0]?.split('-')?.splice(0,2)?.join('-')}</>
  const breadcrumb = <span><MonitorHeart />&nbsp;{
    snapshotId && isAdmin ? <span>Edit Manufacturer Fitness Index Snapshot&nbsp;{breadCrumbDate}</span>
      : snapshotId ? <span>View Manufacturer Fitness Index Snapshot&nbsp;{breadCrumbDate}</span>
      : 'Edit Manufacturer Fitness Index'
  }</span>

  const dateIsInvalid = (d) => {
    return isNaN(new Date(d).getTime())
  }

  const onSave = async () => {
    setIsLoading(true)
    setIsSaving(true)
    try {
      const requestConfig = await getRequestConfig()
      
      const basePath = snapshotId ? `${ApiGateway.fitnessIndexes}/snapshots/${snapshotId}`
        : `${ApiGateway.fitnessIndexes}/${fitnessIndexId}`

      const payload = { ...fitnessIndex }

      if(dateIsInvalid(payload.lastAuthAssessDate)) payload.lastAuthAssessDate = null
      if(dateIsInvalid(payload.lastGaoQ1Date)) payload.lastGaoQ1Date = null
      if(dateIsInvalid(payload.lastGaoE1Date)) payload.lastGaoE1Date = null
      if(dateIsInvalid(payload.lastGaoS1Date)) payload.lastGaoS1Date = null
      
      if(snapshotId) {
        payload.processTypes = (fitnessIndex.processTypes ?? []).reduce((s, pt) => {
          const found = processTypeOptions.find(pto => pto.value === pt)
          if(found) {
            s.push(found.label)
          }
          return s
        }, []).join(',')
        payload.keyCustomers = (fitnessIndex.keyCustomers ?? []).reduce((s, kc) => {
          const found = customerOptions.find(co => co.value === kc)
          if(found) {
            s.push(found.label)
          }
          return s
        }, []).join(',')
      }

      const response = await axios.put(basePath, payload, requestConfig)
      if (response.data) {
        const result = response.data
      }

      setIsDirty(false)
      initializePage()
    } catch (error) {
      console.error(error)

    } finally {
      setIsSaving(false)
      setIsLoading(true)
    }

  }

  const confirmCancel = () => {

  }

  const onClose = () => {
    if (location.key === 'default' && !snapshotId) {
      navigate(`/fitness-indexes`)
    } else {
      navigate(-1)
    }
  }


  const editable = useMemo(() => {
    if(isAdmin) return true
    if(snapshotId) return false
    return true
  }, [snapshotId, isAdmin])

  return (
    <>
      {fitnessIndex &&
        <ThemeProvider theme={theme}>
          {globalStyles}
          <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading}
            commandBar={
              <FormViewCommandBar onSave={onSave} onCancel={confirmCancel}
                loading={isLoading || isSaving}
                enableSave={isDirty}
                showClose={true}
                onClose={onClose}
                showCancel={false}
                showSave={!snapshotId || isAdmin}
                debug={JSON.stringify(fitnessIndex, null ,2)}
              />}>
            <Grid container rowGap={1} xs={12} sm={12}>
              <Grid container rowGap={1} xs={12} sm={12} md={6} padding={1}>
                <Grid item xs={12} sm={12}>
                  <Typography fontWeight={'bold'}>MANUFACTURER </Typography>
                {manufacturer.manufacturerName}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography fontWeight={'bold'}>Ownership </Typography>
                {manufacturer.Ownership?.ownershipName ?? ''}
                </Grid>
                
                <Grid item xs={12} sm={12}>
                  <HistoryLabel label="QSE Manager" id="rtm" changes={changeHistory} />
                  {snapshotId && <FormControl fullWidth>
                    <TextField 
                      disabled={!editable}
                      value={fitnessIndex.rtm} 
                      aria-label="QSE Manager" 
                      onChange={(event) => {
                        handleFieldChange("rtm", event?.target?.value, fitnessIndex, setFitnessIndex, setDirty)
                      }}
                    />
                  </FormControl>}
                  {!snapshotId && <QSEAutoComplete initializeDefaultUser={false} defaultUserId={fitnessIndex.rtm} label={false} placeholder={'QSE Manager'} onChange={(value) => handleFieldChange("rtm", value, fitnessIndex, setFitnessIndex, setDirty)} />}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <HistoryLabel label="Comments" id="conclusion" changes={changeHistory}/>
                  <FormControl fullWidth>
                    <TextField 
                      disabled={!editable}
                      inputProps={{ maxLength: 500 }} 
                      value={fitnessIndex.conclusion} 
                      aria-label="Comments" 
                      multiline 
                      rows={5}
                      onChange={(event) => {
                        handleFieldChange("conclusion", event?.target?.value, fitnessIndex, setFitnessIndex, setDirty)
                      }}
                      helperText={`${fitnessIndex.conclusion?.length ?? 0}/500`}/>
                  </FormControl>
                </Grid>
                  
              </Grid>
              <Grid container spacing={0} xs={12} sm={12} md={6} padding={1}>
                <Grid container spacing={0} xs={12} sm={4} textAlign={'center'}>
                  <Grid item xs={12} sm={12}><Typography fontWeight={'bold'}>Score</Typography>
                    <MiniStatBox loading={isLoading} value={formatPercentage(fitnessIndex.totalScore)}/>
                  </Grid>
                  
                </Grid>
                <Grid container spacing={0} xs={12} sm={4} textAlign={'center'}>
                  <Grid item xs={12} sm={12}>
                    <Typography fontWeight={'bold'}>Ranked</Typography>
                    <MiniStatBox loading={isLoading} value={fitnessIndex.ranking}/>
                  </Grid>
                </Grid>
                <Grid container spacing={0} xs={12} sm={4} textAlign={'center'}>
                  <Grid item xs={12} sm={12}>
                    <Typography fontWeight={'bold'}>Group</Typography>
                    <MiniStatBox loading={isLoading} value={fitnessIndex.grouping}/>
                  </Grid>
                </Grid>

                <Grid container spacing={0} xs={12} sm={4} textAlign={'center'}>
                  <Grid item xs={12} sm={12}>
                    <Typography fontWeight={'bold'}>Commitment</Typography>
                    <MiniStatBox loading={isLoading} value={formatPercentage(fitnessIndex.commitment)}/>
                  </Grid>
                </Grid>
                <Grid container spacing={0} xs={12} sm={4} textAlign={'center'}>
                  <Grid item xs={12} sm={12}>
                    <Typography fontWeight={'bold'}>Compliance</Typography>
                    <MiniStatBox loading={isLoading} value={formatPercentage(fitnessIndex.compliance)}/>
                  </Grid>
                </Grid>
                <Grid container spacing={0} xs={12} sm={4} textAlign={'center'}>
                  <Grid item xs={12} sm={12}>
                    <Typography fontWeight={'bold'}>Capability</Typography>
                    <MiniStatBox loading={isLoading} value={formatPercentage(fitnessIndex.capability)}/>
                  </Grid>
                </Grid>

                <Grid container spacing={0} xs={12} sm={4} padding={.5} paddingTop={1} textAlign={'center'} alignItems={'start'}>
                  <Grid item xs={12} sm={12}>
                    <Typography fontWeight={'bold'}>Authorization Status</Typography>
                    <Typography className="clickable" onClick={() => {
                      setActiveTab(3)
                      scrollToElement('#authStatus');
                    }}>
                      {authStatusMap[fitnessIndex.authStatus] ?? ''}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={0} xs={12} sm={4} padding={1} alignItems={'start'}>
                  <Grid item xs={12} sm={12} alignContent={'center'}>
                    <Typography fontWeight={'bold'} textAlign={'center'}>Winning/Losing</Typography>
                    <FormControl fullWidth disabled={props.readOnly}>
                      <Select
                        labelId={`isWinning-label`}
                        id="isWinning"
                        disabled={!editable}
                        value={(fitnessIndex.isWinning || '').toLowerCase()}
                        className={ fitnessIndex.isWinning === 'Winning' ? 'fg-green' : 'fg-red' }
                        onChange={(_event, value)=>{
                          handleFieldChange("isWinning", capitalizeFirst(value?.props?.value ?? ''), fitnessIndex, setFitnessIndex, setDirty)
                        }}
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="winning" className="h1 fg-green">Winning</MenuItem>
                        <MenuItem value="losing" className="h1 fg-red">Losing</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}><HistoryDetails loading={changeHistoryLoading} id='isWinning' changes={changeHistory}/></Grid>
                </Grid>
                <Grid container spacing={0} xs={12} sm={4} padding={1} alignItems={'start'}>
                  <Grid item xs={12} sm={12} alignContent={'center'}>
                    <Typography fontWeight={'bold'} textAlign={'center'}>Facility Risk</Typography>
                    <FormControl disabled={props.readOnly} fullWidth>
                      <Select
                        labelId={`facilityRisk-label`}
                        id="facilityRisk"
                        disabled={!editable}
                        value={fitnessIndex.facilityRisk + ''}
                        onChange={(_event, value)=>{
                          handleFieldChange("facilityRisk", value?.props?.value ?? '', fitnessIndex, setFitnessIndex, setDirty)
                        }}
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="3">High</MenuItem>
                        <MenuItem value="2">Medium</MenuItem>
                        <MenuItem value="1">Low</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}><HistoryDetails loading={changeHistoryLoading} id='facilityRisk' changes={changeHistory}/></Grid>
                </Grid>
              </Grid>
              {!snapshotId && <Grid container spacing={0} xs={12} sm={12} style={{padding:'1em', textAlign:'center'}}>
                <Card className="index-changes-over-time" style={{width:'100%'}}>
                    <CardHeader titleTypographyProps={{ fontSize: '1rem !important', variant: 'body2' }} sx={{textAlign:'left'}} title="Index Changes Over Time" onClick={toggleIndexChangesOverTime} action={indexChangesOverTimeIcon}></CardHeader>
                    <Collapse in={indexChangesOverTimeExpanded} timeout="auto" unmountOnExit={false}>
                        <CardContent >
                          <IndexChangesOverTimeChart loading={isLoading} history={props.history} mfi={fitnessIndex}/>
                        </CardContent>
                    </Collapse>
                </Card>
              </Grid>}
              <Grid container spacing={0} xs={12} sm={12} style={{padding:'1em'}}>
                <Card className="additional-manufacturer-details" style={{width:'100%'}}>
                    <CardHeader titleTypographyProps={{ fontSize: '1rem !important', variant: 'body2' }} sx={{textAlign:'left'}} title="Additional Manufacturer Details" onClick={toggleAdditionalManufacturerDetails} action={additionalManufacturerDetailsIcon}></CardHeader>
                    <Collapse in={additionalManufacturerDetailsExpanded} timeout="auto" unmountOnExit={false}>
                        <CardContent >
                          <Grid container>
                            <Grid item xs={12}>
                              <Stack>
                              <FormControl component="fieldset" variant="outlined" fullWidth>
                                <Grid container spacing={2} paddingTop={2} paddingBottom={2}>
                                  <Grid item xs={12} sm={6} alignContent={'center'}>
                                    <Typography>Organization:</Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                    <Typography>{fitnessIndex.organizationName}</Typography>
                                  </Grid>
                                </Grid>
                              </FormControl>
                              <TextInput changeHistoryLoading={changeHistoryLoading} 
                                id="volume"
                                labelText="Volume:"
                                disabled={!editable}
                                onValidate={[Validate.required, Validate.nonNegativeFloat]}
                                onChange={(_event, value)=>{
                                  handleFieldChange("volume", value, fitnessIndex, setFitnessIndex, setDirty)
                                }}
                                value={fitnessIndex.volume}
                                changes={changeHistory}
                              />
                              <TextInput changeHistoryLoading={changeHistoryLoading} 
                                id="cpmPreviousYr"
                                labelText="CPM Last Year:"
                                disabled={!editable}
                                onValidate={[Validate.required, Validate.nonNegativeFloat]}
                                onChange={(_event, value)=>{
                                  handleFieldChange("cpmPreviousYr", value, fitnessIndex, setFitnessIndex, setDirty)
                                }}
                                value={fitnessIndex.cpmPreviousYr}
                                changes={changeHistory}
                              />
                              <TextInput changeHistoryLoading={changeHistoryLoading} 
                                id="cpmYTD"
                                labelText="CPM YTD:"
                                disabled={!editable}
                                onValidate={[Validate.required, Validate.nonNegativeFloat]}
                                onChange={(_event, value)=>{
                                  handleFieldChange("cpmYTD", value, fitnessIndex, setFitnessIndex, setDirty)
                                }}
                                value={fitnessIndex.cpmYTD}
                                changes={changeHistory}
                              />
                              <FormControl component="fieldset" variant="outlined" fullWidth>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6} alignContent={'center'}>
                                    <Typography>Platforms:</Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                    {processTypeOptions && <Autocomplete className='tis-form-field'
                                      isOptionEqualToValue={(option, value)=> optionValue(option, value)}
                                      multiple
                                      id="processTypes"
                                      size='small'
                                      disabled={!editable}
                                      options={processTypeOptions}
                                      getOptionLabel={(option) => { return option.label ?? (processTypeOptions.find(o => o.value === option)?.label ?? option)}}
                                      openOnFocus
                                      value={fitnessIndex.processTypes || []}
                                      onChange={(_event, values) => {
                                        const selections = (values ?? []).map((o) => typeof o === 'object' ? o.value : o)
                                        const updated = {
                                          ...fitnessIndex,
                                          processTypes: selections,
                                          processCount: selections.length
                                        }
                                        setFitnessIndex(updated)
                                        setDirty()
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          required
                                          label="Platforms"
                                        />
                                      )}
                                    />}
                                  </Grid>
                                  <Grid className='columnar-layout flex-gap-sm' item xs={12} sm={2}>
                                    <HistoryDetails loading={changeHistoryLoading} id='processTypes' changes={changeHistory}/></Grid>
                                </Grid>
                              </FormControl>
                              <FormControl component="fieldset" variant="outlined" fullWidth>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6} alignContent={'center'}>
                                    <Typography>Key Customers:</Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                    {customerOptions && <Autocomplete className='tis-form-field'
                                      isOptionEqualToValue={(option, value)=> optionValue(option, value)}
                                      multiple
                                      id="keyCustomers"
                                      size='small'
                                      options={customerOptions}
                                      disabled={!editable}
                                      getOptionLabel={(option) => { return option.label ?? (customerOptions.find(o => o.value === option)?.label ?? option)}}
                                      openOnFocus
                                      value={fitnessIndex.keyCustomers || []}
                                      onChange={(_event, values) => { 
                                        const selections = (values ?? []).map((o) => typeof o === 'object' ? o.value : o)
                                        
                                        const updated = {
                                          ...fitnessIndex,
                                          keyCustomers: selections
                                        }
                                        setFitnessIndex(updated)
                                        setDirty()
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          required
                                          label="Key Customers"
                                        />
                                      )}
                                    />}
                                  </Grid>
                                  <Grid className='columnar-layout flex-gap-sm' item xs={12} sm={2}>
                                  <HistoryDetails loading={changeHistoryLoading} id='keyCustomers' changes={changeHistory}/></Grid>
                                </Grid>
                              </FormControl>
                              <TextInput changeHistoryLoading={changeHistoryLoading} 
                                id="otherCustomers"
                                disabled={!editable}
                                labelText="Other Customers:"
                                onValidate={Validate.required}
                                onChange={(_event)=>{
                                  handleFieldChange("otherCustomers", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                                }}
                                value={fitnessIndex.otherCustomers}
                                changes={changeHistory}
                              />
                              </Stack>                
                            </Grid>
                          </Grid>
                        </CardContent>
                    </Collapse>
                </Card>
              </Grid>
              
              <Box sx={{ width:'100%', margin: '0 1em' }}>
                <Tabs
                  textColor="inherit"
                  id="create-index-tabs" 
                  value={activeTab} 
                  variant="fullWidth"
                  onChange={onTabSelect} 
                  TabIndicatorProps={{
                    style: {
                      transition: 'none', // Disable the transition animation
                    }
                  }}>
                    
                    <Tab label="Key Business Dependencies"/>
                    <Tab label="Certification Status"/>
                    <Tab label="Plant Capabilities"/>
                    <Tab label="KORE/Auth. Compliance"/>
                    <Tab label="GAO Q1"/>
                    <Tab label="GAO E1"/>
                    <Tab label="GAO S1"/>
                </Tabs>
              </Box>
              
              {
                //***************************************************************
                //*                   Key Business Dependencies
                //***************************************************************
              }
              <TabPanel value={activeTab} index={0}>
                <YesNoRadio changeHistoryLoading={changeHistoryLoading} id="allergens" labelText="Does the Facility Produce Allergens?" value={fitnessIndex.allergens} 
                  onValidate={Validate.required}
                  disabled={!editable}
                  onChange={(_event, value)=>{
                    handleFieldChange("allergens", value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  changes={changeHistory}/>
              
                <YesNoRadio changeHistoryLoading={changeHistoryLoading} id="highRisk" labelText="Does the facility have an on-site Regulatory Agent monitoring the process (i.e. dairy)?" 
                  value={fitnessIndex.highRisk} 
                  disabled={!editable}
                  onValidate={Validate.required}
                  onChange={(_event, value)=>{
                    handleFieldChange("highRisk", value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  changes={changeHistory}/>

                <YesNoRadio changeHistoryLoading={changeHistoryLoading} id="singleSource" labelText="Is the plant a single source for any product or geography?" value={fitnessIndex.singleSource}
                  onValidate={Validate.required}
                  disabled={!editable}
                  onChange={(_event, value)=>{
                    handleFieldChange("singleSource", value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  changes={changeHistory}/>

              </TabPanel>
              { 
                //***************************************************************
                //*                   Certification Status
                //*************************************************************** 
              }
              <TabPanel value={activeTab} index={1}>
                <CertificationStatusDropdown changeHistoryLoading={changeHistoryLoading} id="fssc22000"
                  labelText="FSSC 22000 or Approved GFSI?"
                  disabled={!editable}
                  onValidate={Validate.required}
                  value={fitnessIndex.fssc22000}
                  onChange={(_event, value) => {
                    handleFieldChange("fssc22000", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  changes={changeHistory}/>
              
                <CertificationStatusDropdown changeHistoryLoading={changeHistoryLoading} id="iso9001"
                  labelText="ISO 9001?"
                  disabled={!editable}
                  onValidate={Validate.required}
                  value={fitnessIndex.iso9001}
                  onChange={(_event) => {
                    handleFieldChange("iso9001", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  changes={changeHistory}/>

                <CertificationStatusDropdown changeHistoryLoading={changeHistoryLoading} id="iso14001"
                  labelText="ISO 14001 or Equivalent?"
                  disabled={!editable}
                  onValidate={Validate.required}
                  value={fitnessIndex.iso14001}
                  onChange={(_event) => {
                    handleFieldChange("iso14001", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  changes={changeHistory}/>

                <CertificationStatusDropdown changeHistoryLoading={changeHistoryLoading} id="ohsas18001"
                  labelText="OHSAS 18001 or Equivalent?"
                  disabled={!editable}
                  onValidate={Validate.required}
                  value={fitnessIndex.ohsas18001}
                  onChange={(_event) => {
                    handleFieldChange("ohsas18001", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  changes={changeHistory}/>
              </TabPanel>

              {
                // ***************************************************************
                // *                   Plant Capabilities
                // ***************************************************************
              }
              <TabPanel value={activeTab} index={2}>

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="marketActions"
                  labelText="Number of Market Actions and Stock Recovery for Past 12 Months:"
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event)=>{
                    handleFieldChange("marketActions", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  readOnly={!snapshotId || !editable}
                  value={fitnessIndex.marketActions}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="failuresEscalated"
                  labelText='Number of Failures Leading to an Escalated Issue for Past 12 Months:'
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event)=>{
                    handleFieldChange("failuresEscalated", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  readOnly={!snapshotId || !editable}
                  value={fitnessIndex.failuresEscalated}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading}
                  id="citations"
                  labelText='# NOV/Citations in the past 12 months that have not been closed through RCA/CAP:'
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event)=>{
                    handleFieldChange("citations", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  readOnly={!snapshotId || !editable}
                  value={fitnessIndex.citations}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading}
                  id="consentOrders"
                  labelText='Number of Active Consent Orders (Federal, State or Local):'
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  disabled={!editable}
                  onChange={(_event)=>{
                    handleFieldChange("consentOrders", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.consentOrders}
                  changes={changeHistory}
                />

                <YesNoRadio changeHistoryLoading={changeHistoryLoading} id="chilledAseptic" 
                  disabled
                  labelText={<span>Does the plant run Chilled or Aseptic Products? <Tooltip title='See "Platforms" in the "Additional Manufacturer Details" panel above to change this value'><Info/></Tooltip></span>}
                  onValidate={Validate.required}
                  value={fitnessIndex.processTypes?.some(pt => `${pt}` === '3') ? '1' : '0'}
                />    

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="minSterility"
                  disabled={fitnessIndex.chilledAseptic !== "1"}
                  labelText="Chilled/Aseptic Sterility (for worst line):"
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event)=>{
                    handleFieldChange("minSterility", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.minSterility}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading}
                  id="plantTrends"
                  labelText='Number of Plant Caused Trends for Past 12 Months:'
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event)=>{
                    handleFieldChange("plantTrends", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  readOnly={!snapshotId || !editable}
                  value={fitnessIndex.plantTrends}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading}
                  id="closedPlantTrends"
                  labelText='Number of Closed Plant Caused Trends for Past 12 Months:'
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event)=>{
                    handleFieldChange("closedPlantTrends", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  readOnly={!snapshotId || !editable}
                  value={fitnessIndex.closedPlantTrends}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading}
                  id="brix"
                  labelText='Brix (avg):'
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event)=>{
                    handleFieldChange("brix", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  readOnly={!snapshotId || !editable}
                  value={fitnessIndex.brix}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading}
                  id="assay"
                  labelText='Assay (avg):'
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event)=>{
                    handleFieldChange("assay", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  readOnly={!snapshotId || !editable}
                  value={fitnessIndex.assay}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading}
                  id="tds"
                  labelText='Tds (avg):'
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event)=>{
                    handleFieldChange("tds", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  readOnly={!snapshotId || !editable}
                  value={fitnessIndex.tds}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading}
                  id="ozone"
                  labelText='Ozone (avg):'
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event)=>{
                    handleFieldChange("ozone", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  readOnly={!snapshotId || !editable}
                  value={fitnessIndex.ozone}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading}
                  id="customerCreditsAndReturns"
                  labelText='Monthly Customer Credits and Returns:'
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event)=>{
                    handleFieldChange("customerCreditsAndReturns", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  readOnly={!snapshotId || !editable}
                  value={fitnessIndex.customerCreditsAndReturns}
                  changes={changeHistory}
                />

              </TabPanel>
              {
                // ***************************************************************
                // *                   KORE/Authorization Compliance
                // ***************************************************************
              }
              <TabPanel value={activeTab} index={3}>
                <DatePickerInput changeHistoryLoading={changeHistoryLoading} 
                  id="lastAuthAssessDate"
                  disabled={!editable}
                  labelText="Date of Last Authorization Assessment or Authorization Review:"
                  onValidate={[Validate.required]}
                  onChange={(_event)=>{
                    handleFieldChange("lastAuthAssessDate", _event, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.lastAuthAssessDate}
                  changes={changeHistory}
                />

                <AuthorizationStatusDropdown changeHistoryLoading={changeHistoryLoading}
                  id="authStatus"
                  labelText="Authorization Status:"
                  disabled={!editable}
                  value={fitnessIndex.authStatus}
                  onChange={(event) => {
                    handleFieldChange("authStatus", event?.target?.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="authTotalNc"
                  disabled={!editable}
                  labelText="Number of TOTAL Non-Conformances:"
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event)=>{
                    handleFieldChange("authTotalNc", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.authTotalNc}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="authRepeatNc"
                  disabled={!editable}
                  labelText="Number of REPEAT Non-Conformances:"
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event)=>{
                    handleFieldChange("authRepeatNc", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.authRepeatNc}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="authClosedNc"
                  disabled={!editable}
                  labelText="Number of CLOSED Non-Conformances:"
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event)=>{
                    handleFieldChange("authClosedNc", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.authClosedNc}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="authTotalHrNc"
                  disabled={!editable}
                  labelText="Number of TOTAL Conditional and High Risk Non-Conformances:"
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event)=>{
                    handleFieldChange("authTotalHrNc", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.authTotalHrNc}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="authRepeatHrNc"
                  disabled={!editable}
                  labelText="Number of REPEAT Conditional and High Risk Non-Conformances:"
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("authRepeatHrNc", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.authRepeatHrNc}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="authClosedHrNc"
                  disabled={!editable}
                  labelText="Number of CLOSED Conditional and High Risk Non-Conformances:"
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("authClosedHrNc", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.authClosedHrNc}
                  changes={changeHistory}
                />
              </TabPanel>
              {
                // ***************************************************************
                // *                   GAO Q1
                // ***************************************************************
              }
              <TabPanel value={activeTab} index={4}>

                <DatePickerInput changeHistoryLoading={changeHistoryLoading} 
                  id="lastGaoQ1Date"
                  labelText="Date of Last Audit:"
                  disabled={!editable}
                  onValidate={[Validate.required]}
                  onChange={(_event)=>{
                    handleFieldChange("lastGaoQ1Date", _event, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.lastGaoQ1Date}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="q1TotalMinor"
                  labelText="Number of TOTAL Q1 Minor Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("q1TotalMinor", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.q1TotalMinor}
                  changes={changeHistory}
                />
                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="q1RepeatMinor"
                  labelText="Number of REPEAT Q1 Minor Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("q1RepeatMinor", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.q1RepeatMinor}
                  changes={changeHistory}
                />  

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="q1ClosedMinor"
                  labelText="Number of CLOSED Q1 Minor Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("q1ClosedMinor", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.q1ClosedMinor}
                  changes={changeHistory}
                />          

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="q1TotalMajor"
                  labelText="Number of TOTAL Q1 Major Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("q1TotalMajor", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.q1TotalMajor}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="q1ClosedMajor"
                  labelText="Number of CLOSED Q1 Major Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("q1ClosedMajor", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.q1ClosedMajor}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="q1TotalCritical"
                  labelText="Number of TOTAL Q1 High Risk and Above Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("q1TotalCritical", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.q1TotalCritical}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="q1RepeatMajorCritical"
                  labelText="Number of REPEAT Q1 Major AND High Risk and Above Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("q1RepeatMajorCritical", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.q1RepeatMajorCritical}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="q1ClosedCritical"
                  labelText="Number of CLOSED Q1 High Risk and Above Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("q1ClosedCritical", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.q1ClosedCritical}
                  changes={changeHistory}
                />
              </TabPanel>
              {
                // ***************************************************************
                // *                   GAO E1
                // ***************************************************************
              }
              <TabPanel value={activeTab} index={5}>
                <DatePickerInput changeHistoryLoading={changeHistoryLoading} 
                  id="lastGaoE1Date"
                  labelText="Date of Last Audit:"
                  disabled={!editable}
                  onValidate={[Validate.required]}
                  onChange={(_event)=>{
                    handleFieldChange("lastGaoE1Date", _event, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.lastGaoE1Date}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="e1TotalMinor"
                  labelText="Number of TOTAL E1 Minor Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("e1TotalMinor", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.e1TotalMinor}
                  changes={changeHistory}
                />
                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="e1RepeatMinor"
                  labelText="Number of REPEAT E1 Minor Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("e1RepeatMinor", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.e1RepeatMinor}
                  changes={changeHistory}
                />  

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="e1ClosedMinor"
                  labelText="Number of CLOSED E1 Minor Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("e1ClosedMinor", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.e1ClosedMinor}
                  changes={changeHistory}
                />          

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="e1TotalMajor"
                  labelText="Number of TOTAL E1 Major Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("e1TotalMajor", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.e1TotalMajor}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="e1ClosedMajor"
                  labelText="Number of CLOSED E1 Major Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("e1ClosedMajor", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.e1ClosedMajor}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="e1TotalCritical"
                  labelText="Number of TOTAL E1 High Risk and Above Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("e1TotalCritical", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.e1TotalCritical}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="e1RepeatMajorCritical"
                  labelText="Number of REPEAT E1 Major AND High Risk and Above Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("e1RepeatMajorCritical", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.e1RepeatMajorCritical}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="e1ClosedCritical"
                  labelText="Number of CLOSED E1 High Risk and Above Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("e1ClosedCritical", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.e1ClosedCritical}
                  changes={changeHistory}
                />
              </TabPanel>
              {
                // ***************************************************************
                // *                   GAO S1
                // ***************************************************************
              }
              <TabPanel value={activeTab} index={6}>

                <DatePickerInput changeHistoryLoading={changeHistoryLoading} 
                  id="lastGaoS1Date"
                  labelText="Date of Last Audit:"
                  disabled={!editable}
                  onValidate={[Validate.required]}
                  onChange={(_event)=>{
                    handleFieldChange("lastGaoS1Date", _event, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.lastGaoS1Date}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="s1TotalMinor"
                  labelText="Number of TOTAL S1 Minor Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("s1TotalMinor", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.s1TotalMinor}
                  changes={changeHistory}
                />
                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="s1RepeatMinor"
                  labelText="Number of REPEAT S1 Minor Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("s1RepeatMinor", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.s1RepeatMinor}
                  changes={changeHistory}
                />  

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="s1ClosedMinor"
                  labelText="Number of CLOSED S1 Minor Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("s1ClosedMinor", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.s1ClosedMinor}
                  changes={changeHistory}
                />          

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="s1TotalMajor"
                  labelText="Number of TOTAL S1 Major Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("s1TotalMajor", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.s1TotalMajor}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="s1ClosedMajor"
                  labelText="Number of CLOSED S1 Major Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("s1ClosedMajor", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.s1ClosedMajor}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="s1TotalCritical"
                  labelText="Number of TOTAL S1 High Risk and Above Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("s1TotalCritical", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.s1TotalCritical}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="s1RepeatMajorCritical"
                  labelText="Number of REPEAT S1 Major AND High Risk and Above Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("s1RepeatMajorCritical", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.s1RepeatMajorCritical}
                  changes={changeHistory}
                />

                <TextInput changeHistoryLoading={changeHistoryLoading} 
                  id="s1ClosedCritical"
                  labelText="Number of CLOSED S1 High Risk and Above Findings:"
                  disabled={!editable}
                  onValidate={[Validate.required, Validate.nonNegativeInt]}
                  onChange={(_event, value)=>{
                    handleFieldChange("s1ClosedCritical", _event.target.value, fitnessIndex, setFitnessIndex, setDirty)
                  }}
                  value={fitnessIndex.s1ClosedCritical}
                  changes={changeHistory}
                />
              </TabPanel>
            </Grid>
          </Page>
        </ThemeProvider>
      }
    </>
  )
}