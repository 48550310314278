import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  OutlinedInput,
  Box
} from '@mui/material';
import { default as bus } from '../utils/EventBus'
import Event from '../utils/EventNames.js';
import { createArticleFromTemplate, Tags } from './data';
import { editArticle } from './utils.js';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CreateArticleDialog({
  open,
  onClose,
  featured,
  news,
  ouCorner,
  ouCornerCategory,
  highlighted,
}) {
  const [name, setName] = useState('');
  const [tags, setTags] = useState([]);

  const startPageLoading = () => bus.emit(Event.START_PAGE_LOADING)
  const stopPageLoading = () => bus.emit(Event.STOP_PAGE_LOADING)

  const onSubmitCreateArticle = (submission) => {
    const { name, tags } = submission
    onClose()
    createArticle(
      name,
      'news',
      {
        QSEPortalFeatured: featured,
        QSEPortalNews: news,
        QSEPortalOUCorner: ouCorner,
        QSEPortalOUCornerCategory: ouCornerCategory,
        QSEPortalHighlighted: highlighted,
        QSEPortalTags: tags.join(',')
      },
      startPageLoading,
      stopPageLoading
    )
  }

  const createArticle = async (name, template, fields) => {
    startPageLoading()
    const body = {
      "@odata.type": "#microsoft.graph.sitePage",
      "description": name,
      "name": name,
      "title": name,
      "pageLayout": "article",
      "promotionKind": "newsPost",
      "showComments": false,
      "showRecommendedPages": false,
    }
    const article = await createArticleFromTemplate(template, body, fields)
    stopPageLoading()
    editArticle(article)
  }

  const handleSubmit = () => {
    onSubmitCreateArticle({ name, tags });
    setName('');
    setTags([]);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Create New Article</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" gap={2} mt={1}>
          <TextField
            label="Article Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel id="tags-label">Tags</InputLabel>
            <Select
              labelId="tags-label"
              multiple
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              input={<OutlinedInput label="Tags" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((tag) => (
                    <Chip key={tag} label={tag} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {Tags.map((tag) => (
                <MenuItem key={tag} value={tag}>
                  {tag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
