import { default as React, useContext, useEffect, useState, useMemo } from 'react'
import { IconButton, Stack, Button, Typography, Grid, Paper } from '@mui/material'
import { Add } from '@mui/icons-material'
import Page from '../layout/Page'
import { useParams, useLocation, useNavigate, useBlocker } from 'react-router-dom'
import RoleContext from '../auth/RoleContext';
import { ROLES } from '../auth/role';
import EventBus from '../utils/EventBus';
import { getResourceLibraryCategories, getResourceLibraryFilesByCategory } from './data'
import DocumentCard from './DocumentCard'
import SubmitResourceDialog from './SubmitResourceDialog'
import ListViewCommandBar from '../commandBar/ListViewCommandBar'

export default function ResourceLibraryPage(props = {}) {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const roleDetails = useContext(RoleContext)
  const [isLoading, setIsLoading] = useState(true)
  const [files, setFiles] = useState({})
  const [categories, setCategories] = useState([])
  const [resourceDialogOpen, setResourceDialogOpen] = useState(false)

  useEffect(() => {
    (async () => {
      const cats = await getResourceLibraryCategories(window.sessionStorage.mockGraphToken)
      console.log(`cats: `, cats)
      setCategories(cats)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      const _files = {}
      for (const category of categories) {
        const files = await getResourceLibraryFilesByCategory(category, window.sessionStorage.mockGraphToken)
        _files[category] = files
      }
      console.log('_files', _files)
      setFiles(_files)
    })()
  }, [categories])

  const breadcrumb = <div><span>Resource Library</span></div>

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const initializePage = async () => {
    startLoading()
    // 
    stopLoading()
  }

  useEffect(() => {
    initializePage()
  }, [])

  const Category = (props = {}) => {
    return (
      <Grid container gap={0}>
        <Grid item xs={8}>
          <Typography variant='subtitle1' fontWeight='bold'>{props.title}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row-reverse" gap={1}>
            <Button size='small'>Sort By</Button>
            <Button size='small'>Filter By</Button>
            <IconButton size='small'>
              <Add onClick={props.onClickAdd} />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Grid container gap={1}>
            {props.files?.reduce((p, file, idx) => {
              if (file.driveItem.parentReference.name === 'Published') {                
                return [...p, 
                  <DocumentCard key={idx}
                    title={file?.fields?.LinkFilename}
                    size={file?.fields?.FileSizeDisplay}
                    preview={file?.fields?.ResourceDescription}
                    fileType={file?.fields?.DocIcon}
                    createdByUser={file?.createdBy.user}
                    openUrl={file?.webUrl}
                    downloadUrl={file?.driveItem['@microsoft.graph.downloadUrl'] ?? undefined}
                  />
                ]
              }
              return p
            },[])
            }
          </Grid>
        </Grid>
      </Grid>
    )

  }


  return (
    <>
    <SubmitResourceDialog
          open={resourceDialogOpen}
          resourceCategories={categories}
          onClose={() => setResourceDialogOpen(false)}
    />
    <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={
            <ListViewCommandBar
              showFilters={false}
              addRecord={() => setResourceDialogOpen(true)}
              showAdd={!resourceDialogOpen}
              addRecordLabel='Submit a Resource'
              showCancel={false}
            />
          }>

      <Paper
        sx={{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', margin: '1em auto', boxShadow: 'none' }}
      >
        <Grid container gap={2}>
            {categories.map((category, idx) => <Category key={idx}
              title={category}
              files={files?.[category]}
            />)}
        </Grid>
      </Paper>
    </Page>
    </>
  )
}