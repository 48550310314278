import React, { useState, useEffect } from "react";
import { Grid, CardActionArea, Card, CardContent, Typography, Box, IconButton } from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import ArticleControls from './ArticleControls'
import { getArticleDetail, getArticles } from "./data/index.js";
import { editArticle, createArticle } from './utils.js'
import DeleteArticleDialog from "./DeleteArticleDialog.js";
import { useNavigate } from "react-router-dom";
export default function ArticleCard(props = {}) {
  const navigate = useNavigate()
  const {
    key,
    article,
    maxWidth = '100%',
    editable
  } = props

  const [hovering, setHovering] = useState(false)
  const [deleteArticleDialogOpen, setDeleteArticleDialogOpen] = useState(false)

  const viewArticle = () => {
    navigate(article.title)
  }

  return (<>
    <DeleteArticleDialog
      open={deleteArticleDialogOpen}
      article={article}
      onClose={() => setDeleteArticleDialogOpen(false)}
    />

    <Card sx={{
      display: 'flex',
    }}
      key={key}
      className="secondary-article-card"
      style={{
        maxWidth,
        backgroundImage: `url(${article.image})`
      }}>
      <CardActionArea
        sx={{ display: 'flex' }}
        onMouseOver={() => setHovering(true)}
        onMouseOut={() => setHovering()}
        onClick={() => viewArticle()}
      >
        <Box className="image-overlay" />
        <CardContent className="secondary-article-content" >
          <Typography variant="body1" className="secondary-article-title">
            {article.title}
          </Typography>
          <Typography variant="body2">
            {article.author} - {article.date}
          </Typography>
        </CardContent>
        {editable && <ArticleControls
          isDraft={article?.isDraft}
          hovering={hovering}
          onClickEdit={() => editArticle(article)}
          onClickDelete={() => setDeleteArticleDialogOpen(true)}
          showAdd={false}
        />}
      </CardActionArea>

    </Card>
  </>)
}