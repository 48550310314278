import { default as React, useContext, useEffect, useState, useMemo } from 'react'
import { TextField, Button, Stack, Paper, Grid } from '@mui/material'
import { Factory } from '@mui/icons-material'
import Page from '../layout/Page'
import { useParams, useLocation, useNavigate, useBlocker } from 'react-router-dom'
import RoleContext from '../auth/RoleContext';
import { ROLES } from '../auth/role';
import EventBus from '../utils/EventBus';
import FeaturedArticle from './FeaturedArticle'
import SecondaryArticles from './SecondaryArticles'
import TertiaryArticles from './TertiaryArticles'
import './CommunityNewsPage.css'
import { default as bus } from '../utils/EventBus'
import Event from '../utils/EventNames.js';
import { Stage } from '../config/config.js'

export default function CommunityNewsPage(props = {}) {
  const roleDetails = useContext(RoleContext)
  const canEdit = roleDetails.hasRole([ROLES.QSE_SAFETY_MANAGER, ROLES.ADMIN])

  const [isLoading, setIsLoading] = useState(true)
  const [graphToken, setGraphToken] = useState('')
  const breadcrumb = <div><span>Safety Community News</span></div>

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const initializePage = async () => {
    startLoading()
    // 
    stopLoading()
  }

  useEffect(() => {
    initializePage()
  }, [])

  useEffect(() => {
    EventBus.on(Event.START_PAGE_LOADING, startLoading)
    EventBus.on(Event.STOP_PAGE_LOADING, stopLoading)
    return () => {
      EventBus.removeListener(Event.START_PAGE_LOADING, startLoading)
      EventBus.removeListener(Event.STOP_PAGE_LOADING, stopLoading)
    }
  }, [])

  return (
    <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading}
      commandBar={
        Stage === 'dev' && <Stack direction='row'>
          <TextField
            label='MS Graph Token'
            value={graphToken}
            onChange={(e) => setGraphToken(e.target.value)}
          />
          <Button
            onClick={() => {
              window.sessionStorage.mockGraphToken = graphToken
              window.location.reload()
            }}
          >SET</Button>
        </Stack>
      }
    >

      <Paper
        component="form"
        sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', margin: '1em auto' }}
      >
        <Grid container gap={0}>
          <Grid item xs={12} sx={{ padding: '4px', flexGrow: '1' }}>
            <FeaturedArticle
              editable={canEdit}
              featured
              news
            />
          </Grid>
          <Grid item xs={12} sx={{ flexGrow: '2', padding: '4px' }}>
            <TertiaryArticles
              editable={canEdit}
              news
            />
          </Grid>
        </Grid>

      </Paper>
    </Page>
  )
}