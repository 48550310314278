import { useContext, useEffect, useState } from 'react'
import { Warning, DeleteForever } from '@mui/icons-material'
import { Backdrop, CircularProgress, CardContent, Box, Card, CardActions, Dialog, DialogContent, DialogTitle, DialogTitleCheckbox, Checkbox, ToggleButtonGroup, ToggleButton, Autocomplete, Button, Chip, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import { handleFieldChange } from '../form/Form'
import { ROLES, getRoleById } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import eventBus from './eventBus';

export default function UserCard(props) {
  const roleDetails = useContext(RoleContext)
  const {
    user,
    setDirty: propsSetDirty,
    manufacturerList,
    ownershipList,
    getOwnershipOptionLabel,
  } = props

  const setUser = props.onChange
  const [loading, setLoading] = useState(false)
  const [isDirty, _setDirty] = useState(false)

  const setDirty = (val) => {
    if (val === undefined) {
      _setDirty(true)
    } else {
      _setDirty(val)
    }
    propsSetDirty(val)
  }

  useEffect(() => {
    const handleLoading = (loading) => {
      setLoading(loading)
    }
    eventBus.on(`${user.email}-loading`, handleLoading)
    return () => {
      eventBus.removeListener(`${user.email}-loading`, handleLoading)
    }
  }, [user])

  const handleRoleSelectChange = async (event) => {
    const rid = event.target.value
    const _role = getRoleById(rid)
    const _user = { ...user }
    _user.manufacturerUuids = []
    _user.ownershipIds = []
    const errors = _user?.errors?.filter(e => e.prop === 'role' || e.prop === 'save')
    if (errors?.length) {
      const newErrors = user?.errors?.filter(e => e.prop !== 'role' && e.prop !== 'save')
      _user.errors = newErrors
    }

    if (_role) {
      setUser({ ..._user, role: { id: _role.id, name: _role.name } })
    } else {
      setUser({ ..._user, role: { id: 0, name: '' } })
    }
    if (rid === ROLES.BOTTLER.id && !user.manufacturerUuids?.length) {
      setDirty(false)
    } else if (rid === ROLES.BOTTLER_CORP.id && !user.ownershipIds?.length) {
      setDirty(false)
    } else setDirty()
  }

  const CustomField = (props) => {
    const { label, value, md = 12 } = props
    return <Grid item xs={12} md={md} style={{ marginTop: "-13px", overflow: 'hidden' }}>
      <Typography fontSize='0.75rem' color="textSecondary" variant="caption" gutterBottom>{label}</Typography>
      <br />
      <Typography fontSize='.9em' variant="body1" component="span">{value}</Typography>
    </Grid>
  }

  const CardActionItem = (props) => {
    const styles = {
      container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center', // if you want to fill rows left to right
        marginBottom: '0.2em',
      },
      label: {
        paddingRight: '0.5em',
      },
      action: {
        margin: 0,
        padding: 0,
      }
    }

    return <div style={styles.container} >
      <Typography style={styles.label} color="textSecondary" variant="caption">{props.label}</Typography>
      <div style={styles.action}>
        {props.children}
      </div>
    </div>
  }

  return <RoleContext.Consumer>
    {roleCtx => (
      <div key={user.email} style={{ position: 'relative' }}>
        <Backdrop style={{ color: '#fff', zIndex: 2, position: 'absolute' }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Card style={{ marginBottom: '1em' }}>
          <Grid container spacing={2}>
            {props.editMode && <Grid item xs={1.2} style={{
              borderRight: "solid 1px rgb(200 200 200)",
              display: 'flex',
              flexWrap: 'nowrap',
              flexDirection: 'row',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <CardActions
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CardActionItem label="Clear">
                  <DeleteForever
                    color="primary"
                    style={{ cursor: 'pointer', fontSize: 28 }}
                    onClick={() => {
                      props.onDelete()
                    }}
                  />
                </CardActionItem>
                {user.newUser && <CardActionItem label="Notify">
                  <Checkbox
                    checked={user.notify}
                    style={{ margin: '0px', padding: '0px' }}
                    onChange={(e) => {
                      const _user = { ...user }
                      _user.notify = e.target.checked
                      setUser(_user)
                    }}
                  />
                </CardActionItem>}
                {user?.errors?.length > 0 &&
                  <CardActionItem label="Error">
                    <Warning
                      onClick={() => props.showErrors(user.errors?.map(e => e.message))}
                      color="error"
                      style={{ marginLeft: "8px" }}
                    />
                  </CardActionItem>
                }
              </CardActions>
            </Grid>}
            <Grid item xs={props.editMode ? 10.8 : 12}>
              <CardContent style={{ padding: '1em' }}>
                <Grid container spacing={2}>
                  <CustomField md={2.5} label="Email" value={(user?.email || "").toLowerCase()} />
                  <CustomField md={1.2} label="KO ID" value={user?.koId} />
                  <CustomField md={1.8} label="Name" value={`${user?.firstName} ${user?.lastName}`} />
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth size='small' >
                      <InputLabel>Role: </InputLabel>
                      <Select size='small' fullWidth id={`role_select`} value={`${user?.role?.id}`} onChange={
                        handleRoleSelectChange
                      } >
                        <MenuItem value={0}>Select A Role</MenuItem>
                        {roleCtx.hasRole([ROLES.ADMIN]) && <MenuItem value={ROLES.ADMIN.id}>{ROLES.ADMIN.name}</MenuItem>}
                        {roleCtx.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN]) && <MenuItem value={ROLES.MFI_ADMIN.id}>{ROLES.MFI_ADMIN.name}</MenuItem>}
                        {roleCtx.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN]) && <MenuItem value={ROLES.QSE_MGR.id}>{ROLES.QSE_MGR.name}</MenuItem>}
                        {roleCtx.hasAnyRole() && !roleCtx.hasRole([ROLES.BOTTLER_CORP, ROLES.BOTTLER]) && <MenuItem value={ROLES.BOTTLER.id}>{ROLES.BOTTLER.name}</MenuItem>}
                        {roleCtx.hasAnyRole() && !roleCtx.hasRole([ROLES.BOTTLER_CORP, ROLES.BOTTLER]) && <MenuItem value={ROLES.BOTTLER_CORP.id}>{ROLES.BOTTLER_CORP.name}</MenuItem>}
                        
                        {roleCtx.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.IM_MANAGER]) && <MenuItem value={ROLES.IM_MANAGER.id}>{ROLES.IM_MANAGER.name}</MenuItem>}
                        {roleCtx.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.IM_MANAGER]) && <MenuItem value={ROLES.IM_VIEWER.id}>{ROLES.IM_VIEWER.name}</MenuItem>}
                      </Select>
                    </FormControl>
                    {user && (user?.role?.id > 0 && user?.role?.id < roleDetails.role?.id) &&
                      <Typography>Role: {getRoleById(user?.role?.id)?.name ?? ''}</Typography>
                    }

                  </Grid>

                  {user?.role?.id === 20 && manufacturerList.length &&
                    <Grid item xs={6} md={3.5}>
                      <FormControl fullWidth size='small' >
                        <Autocomplete
                          multiple
                          id="mfr_profile_autocomplete"
                          size='small'
                          options={manufacturerList.filter(m => !user?.manufacturerUuids?.includes(m.value))}
                          value={user?.manufacturerUuids}
                          openOnFocus
                          onChange={(_event, values) => {
                            const _user = { ...user }
                            const mfrUuids = values.map((v) => {
                              if (typeof v === 'object' && v.value) {
                                return v.value
                              } else {
                                return v
                              }
                            })
                            if (!mfrUuids.length) {
                              setDirty(false)
                            } else {
                              setDirty()
                            }
                            const errors = _user?.errors?.filter(e => e.prop === 'manufacturerUuids' || e.prop === 'save')
                            if (errors?.length) {
                              // remove these from the array
                              const newErrors = _user?.errors?.filter(e => e.prop !== 'manufacturerUuids' && e.prop !== 'save')
                              const newUser = { ..._user, errors: newErrors }
                              handleFieldChange("manufacturerUuids", mfrUuids, newUser, setUser)
                            } else {
                              handleFieldChange("manufacturerUuids", mfrUuids, _user, setUser)
                            }
                          }}
                          renderTags={(value, getTagProps) => {
                            return value.map((option, index) => {
                              const mfrUuid = manufacturerList.find((m) => m.value === option)
                              return (
                                <Chip variant="filled" label={mfrUuid.label} {...getTagProps({ index })} />)
                            }
                            )
                          }
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required={user?.role?.id === 20}
                              label="Associated Facilities"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                        {
                          user?.role?.id === 20 && (!user?.manufacturerUuids || user?.manufacturerUuids.length === 0) &&
                          <FormHelperText error={true}>This field is required</FormHelperText>
                        }
                      </FormControl>
                    </Grid>
                  }

                  {user?.role?.id === 19 && ownershipList.length &&
                    <Grid item xs={6} md={3.5}>
                      <FormControl fullWidth size='small' >
                        <Autocomplete
                          multiple
                          id="ownership_autocomplete"
                          size='small'
                          options={ownershipList.filter(o => !user?.ownershipIds?.includes(o.value))}
                          getOptionLabel={(option) => getOwnershipOptionLabel(option)}
                          value={user?.ownershipIds}
                          openOnFocus
                          onChange={(_event, values) => {
                            const _user = { ...user }
                            const errors = _user?.errors?.filter(e => e.prop === 'ownershipId' || e.prop === 'save')
                            if (errors?.length) {
                              const newErrors = _user?.errors?.filter(e => e.prop !== 'ownershipId' && e.prop !== 'save')
                              _user.errors = newErrors
                            }
                            const ownershipIds = values.map((v) => {
                              if (typeof v === 'object' && v.value) {
                                return v.value
                              } else {
                                return v
                              }
                            })
                            handleFieldChange("ownershipIds", ownershipIds, _user, setUser, setDirty)
                          }}
                          renderTags={(value, getTagProps) => {
                            return value.map((option, index) => {
                              const ownership = ownershipList.find((o) => o.value === option)
                              return (
                                <Chip variant="filled" label={ownership?.label} {...getTagProps({ index })} />)
                            }
                            )
                          }
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required={user?.role?.id === 19}
                              label="Associated Ownerships"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                        {
                          user?.role?.id === 19 && (!user?.ownershipIds || user?.ownershipIds.length === 0) &&
                          <FormHelperText error={true}>This field is required</FormHelperText>
                        }
                      </FormControl>
                    </Grid>
                  }

                </Grid>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </div>
    )}
  </RoleContext.Consumer>
}