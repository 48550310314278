import { domain, azureClientId, azureTenantId, apiKey, stage, featureFlags } from './config-current'
export { SafetyHubSharepoint }from './config-current'

export const Stage = stage
export const FeatureFlags = featureFlags
export const ApiGateway =  {
  apiKey,
  domain,
  facilityManagement: `${domain}/facility-management`,
  authorizations: `${domain}/authorizations`,
  authorization: `${domain}/authorization`,
  employee: `${domain}/employees`,
  profile: `${domain}/profile`,
  user: `${domain}/users`,
  ownership: `${domain}/ownerships`,
  brand: `${domain}/brands`,
  upc: `${domain}/upcs`,
  cpk: `${domain}/cpk-submissions`,
  imLogs: `${domain}/im-logs`,
  fitnessIndexes: `${domain}/fitness-indexes`,
  manufacturer: `${domain}/manufacturers`,
  manufacturerProfile: `${domain}/manufacturers/profiles`,
  assessments: `${domain}/assessments`,
  correctiveAction: `${domain}/corrective-action`,
  complaintTrends: `${domain}/complaint-trends`,
  auth: ''
}

export const AzureAdClient = {
  info: {
    clientId: `${azureClientId}`,
    tenantId: `${azureTenantId}`
  },
  auth: {
    clientId: `${azureClientId}`,
    authority: `https://login.microsoftonline.com/${azureTenantId}`
  }
}
