import React, { useState, useEffect, useCallback } from "react";
import { Grid, IconButton, Card, CardContent, Typography, Box, CardActionArea } from "@mui/material";
import { ChevronLeft, ChevronRight, Edit, Delete, Add } from "@mui/icons-material";
import ArticleControls from './ArticleControls'
import { getArticleDetail, getArticles } from "./data/index.js";
import { Stack } from "@mui/system";
import { SafetyHubSharepoint } from "../config/config-current.js";
import { editArticle, createArticle } from './utils.js'
import ArticleCard from "./ArticleCard.js";
import CreateArticleDialog from "./CreateArticleDialog.js";
import DeleteArticleDialog from "./DeleteArticleDialog.js";
import { default as EventBus } from '../utils/EventBus'
import Event from '../utils/EventNames.js';

export default function SecondaryArticles(props = {}) {
  const {
    layout,
    articles: providedArticles,
    maxWidth = '100%',
    editable,
    news,
    featured,
    ouCorner,
    highlighted,
    ouCornerCategory
  } = props

  const [hovering, setHovering] = useState()
  const [sectionHovering, setSectionHovering] = useState(false)
  const [articles, setArticles] = useState([])
  const [articleDialogOpen, setArticleDialogOpen] = useState(false)
  const [deleteArticleDialogOpen, setDeleteArticleDialogOpen] = useState(false)
  const [articleToDelete, setArticleToDelete] = useState()

  const initialize = useCallback(async () => {
    if (providedArticles) {
      setArticles(providedArticles)
    } else {
      const pages = await getArticles()
      const _articles = []
      for (const newsPost of pages.filter(r => r.promotionKind === 'newsPost' || r['@odata.type'] === '#microsoft.graph.newsLinkPage')) {
        if (newsPost['@odata.type'] === '#microsoft.graph.newsLinkPage') {
          _articles.push({
            title: newsPost.title,
            author: newsPost.createdBy.user.displayName,
            date: new Date(newsPost.createdDatehighlightedArticlesTime).toLocaleDateString(),
            image: newsPost.bannerImageWebUrl
          })
        } else {
          const articleDetail = await getArticleDetail(newsPost.id)
          _articles.push(articleDetail)
        }
      }
      setArticles(_articles)
    }
  }, [providedArticles])

  useEffect(() => {
    EventBus.on(Event.REFRESH_PAGE, initialize)
    return () => {
      EventBus.removeListener(Event.REFRESH_PAGE, initialize)
    }
  }, [initialize])

  useEffect(() => {
    initialize()
  }, [initialize])

  const containerClass = editable ? "secondary-articles article-section" : "secondary-articles"
  return (<>
    <DeleteArticleDialog
      open={deleteArticleDialogOpen}
      article={articleToDelete}
      onClose={() => {
        setDeleteArticleDialogOpen(false)
        setArticleToDelete()
      }}
    />
    <CreateArticleDialog
      open={articleDialogOpen}
      onClose={() => setArticleDialogOpen(false)}
      availableTags={['hi', 'dog']}
      featured={featured}
      news={news}
      ouCorner={ouCorner}
      highlighted={highlighted}
      ouCornerCategory={ouCornerCategory}
    />
    <Box
      onMouseOver={() => setSectionHovering(true)}
      onMouseOut={() => setSectionHovering(false)}
      className={containerClass}>

      <Grid container gap={0} sx={{ position: 'relative' }} className="fit-height">
        {editable && <Grid item xs={12} className="add-icon">
          <div className="article-controls-backdrop">
            <IconButton size='medium' className="icon-button">
              <Add onClick={() => setArticleDialogOpen(true)} />
            </IconButton>
          </div>
        </Grid>}

        <Grid item xs={12} className="fit-height">
          <Grid container gap={1} className="fit-height">
            {articles.map((article, index) => (
              <ArticleCard
                editable={editable}
                key={index}
                article={article}
                maxWidth='33%'
              />
            ))}
          </Grid>
        </Grid>
      </Grid>


    </Box>
  </>);
}
