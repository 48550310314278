import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useIsMounted } from '../utils/ReactUtils.js'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import {
  TextField,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
  Grid,
  Paper,
  Button,
  FormControl,
  Select,
  MenuItem
} from '@mui/material'
import { Option } from '@mui/base/Option';
import axios from 'axios'
import { Form, Link } from 'react-router-dom';
import { getRequestConfig } from '../auth/auth.js'
import { ApiGateway } from '../config/config.js';
import CustomTabPanel from '../form/CustomTabPanel.js'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Edit, EditOutlined, SelectAllOutlined } from '@mui/icons-material'
import ReferencesTable from './ReferencesTable.js'
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ListContacts(props = { searchFilter: undefined, rowsPerPage: 15, pageIndex: 0 }) {
  const isMounted = useIsMounted()
  const desktop = useMediaQuery('(min-width:1000px)');

  const [dataRows, setDataRows] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [currentItem, setCurrentItem] = useState(null)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [categories, setCategories] = useState([])

  const listColumns = useMemo(() => {
    const cols = [
      {
        accessorKey: 'category',
        header: 'Category',
        editable: false,
        filterVariant: 'text',
        grow: true,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        grow: true,
        editable: false,
        filterVariant: 'text',
      },
      {
        accessorFn: (val) => {
          return val.isInternal ? 'Internal' : 'External'
        },
        header: 'Organization',
        editable: false,
        filterVariant: 'text',
        grow: true,
        // Cell: ({ row }) => row.original.isInternal ? 'Internal' : 'External'
      }
    ]
    if (props.editMode) cols.push({
      id: 'actions',
      header: '',
      editable: false,
      size: 40,
      Cell: ({ row }) => {
        return <Edit sx={{ cursor: 'pointer' }} onClick={() => onClickEdit(row.original)} />
      }
    })
    return cols
  }, [props.editMode])

  const onClickEdit = (row) => {
    setCurrentItem(row)
    setEditDialogOpen(true)
  }

  const getContactTypeList = async (force = false) => {
    if (!force && props.cachedContactTypes && props.cachedContactTypes?.length) {
      setDataRows(props.cachedContactTypes)
    } else {
      if (props.startLoading) props.startLoading()

      const requestConfig = await getRequestConfig()
      let basePath, response
      basePath = `${ApiGateway.facilityManagement}/contact-types`
      response = await axios.get(basePath, requestConfig)
      setDataRows(response.data.results)
      if (props.cacheContactTypes) {
        props.cacheContactTypes(response.data.results)
      }
      if (props.stopLoading) props.stopLoading()
    }
  }

  useEffect(() => {
    const categories = dataRows.map(r => r.category)
    const deduped = Array.from(new Set(categories))
    setCategories(deduped)
  }, [dataRows])

  useEffect(() => {
    if (isMounted()) {
      getContactTypeList()
    }
  }, [])

  const table = useMaterialReactTable({
    layoutMode: 'grid',
    columnFilterModeOptions: [
      'fuzzy',
      'greaterThan',
      'lessThan'
    ],
    columns: listColumns,
    data: dataRows,
    enableColumnActions: false,
    enableColumnFilters: props.showFilters,
    initialState: {
      showColumnFilters: true,
      pagination: {
        pageIndex: props.pageIndex ?? 0,
        pageSize: props.rowsPerPage ?? 15
      }
    },
    enablePagination: true,
    enableSorting: true,
    enableBottomToolbar: true,
    enableEditing: false,
    muiTablePaperProps: {

    },
    muiTableContainerProps: {
      sx: { minHeight: 300, width: '100%', maxHeight: 600, overflowY: 'auto' }
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.82rem',
        padding: '4px !important'
      }
    },
    renderTopToolbar: false,
  });

  const onSave = async () => {

    if (props.startLoading) props.startLoading()

    const requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.facilityManagement}/contact-types/${currentItem.id}`
    const response = await axios.put(basePath, currentItem, requestConfig)
    await getContactTypeList(true)
    if (props.stopLoading) props.stopLoading()

    setCurrentItem(null)
    setEditDialogOpen(false)
    props.onCloseAddContactTypesDialog()
  }

  return (
    <div className='App'>
      <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '98%', margin: '1em auto' }}>
        <MaterialReactTable
          table={table}
        />
      </Paper>

      <Dialog
        open={editDialogOpen || props.addContactTypeDialogOpen}
        onClose={() => {
          setCurrentItem(null)
          props.onCloseAddContactTypesDialog()
          setEditDialogOpen(false)
        }}
      >
        <DialogTitle>{props.addContactTypeDialogOpen ? 'Add' : 'Edit'} Contact Type</DialogTitle>
        <DialogContent
          sx={{
            width: desktop ? '600px' : '50vw'
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>

              <Stack direction='column' spacing={2} sx={{ paddingTop: '6px' }}>
                <FormControl>
                  <InputLabel size='small'>Category</InputLabel>
                  <Select
                    value={currentItem?.category}
                    onChange={(e) => {
                      const newItem = { ...currentItem }
                      newItem.category = e.target.value
                      setCurrentItem(newItem)
                    }}
                  >
                    {categories.map(c => {
                      return <MenuItem key={c} value={c}>{c}</MenuItem>
                    })}
                  </Select>
                </FormControl>

                <FormControl>
                {/* <InputLabel size='small'>Name</InputLabel> */}
                  <TextField
                    label='Name'
                    size='small'
                    value={currentItem?.name}
                    onChange={(e) => {
                      const newItem = { ...currentItem }
                      newItem.name = e.target.value
                      setCurrentItem(newItem)
                    }}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel size='small'>Internal?</InputLabel>
                  <Select
                    value={currentItem?.isInternal === true ? 'true'
                      : currentItem?.isInternal === false ? 'false' : ''}
                    onChange={(e) => {
                      const newItem = { ...currentItem }
                      newItem.isInternal = e.target.value === 'true'
                      setCurrentItem(newItem)
                    }}
                  >
                    <MenuItem key='true' value='true'>Internal (Coca-Cola)</MenuItem>
                    <MenuItem key='true' value='false'>External (Bottler)</MenuItem>
                  </Select>
                </FormControl>


              </Stack>

            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => {
            setCurrentItem(null)
            setEditDialogOpen(false)
            props.onCloseAddContactTypesDialog()
          }}>Cancel</Button>
          <Button variant='outlined' onClick={onSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  )

}