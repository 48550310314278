// import { debounce } from '@mui/material/utils';
import { useEffect, useState, useCallback } from "react"
import { getRequestConfig } from '../auth/auth'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import { Autocomplete, Grid, TextField, Typography, } from '@mui/material'

export default function UserSearch(props = {}) {

  const {
    havingRole = null,
    label = '',
    onChange,
    selected,
    readOnly,
    getOptionLabel = (option) => option ? `${option.firstName} ${option.lastName} (${option.koId})` : ""
  } = props

  const [search, setSearch] = useState('')
  const [userList, setUserList] = useState([])
  const [usersSelected, setUsersSelected] = useState()
  const [debounceTimeout, setDebounceTimeout] = useState()
  const [searching, setSearching] = useState(false)

  const debounce = useCallback((func, wait) => {
    clearTimeout(debounceTimeout);
    const _debounceTimeout = setTimeout(() => func(), wait);
    setDebounceTimeout(_debounceTimeout)
  }, [search])

  useEffect(() => {
    const getUserByTokens = async (tokens = [], requestConfig) => {
      const cleanTokens = tokens.filter(t => t !== 'undefined' && t !== undefined && t !== '' && t)
      if (cleanTokens.length === 0) return []

      if (!requestConfig) {
        requestConfig = await getRequestConfig()
      }
      const basePath = `${ApiGateway.user}/search/advanced`
      try {
        const search = {
          tokens: cleanTokens
        }
        if (havingRole !== null) search.hasRole = havingRole
        search.isActive = true
        const response = await axios.post(basePath, search, requestConfig)
        return response.data
      } catch (error) {
        throw error
      }
    }

    debounce(async () => {
      if (search) {
        setSearching(true)
        setUserList([])
        const tokens = search.replace(/[\(\)]/g, '').split(' ')
        getUserByTokens(tokens)
          .then(users => {
            setUserList(users)
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            setSearching(false)
          })
      }
    }, 500)

  }, [search, havingRole])

  return <Autocomplete
    id="managedBy"
    disabled={readOnly}
    size='small'
    getOptionLabel={getOptionLabel}
    filterOptions={(x) => x}
    options={userList}
    autoComplete
    includeInputInList
    filterSelectedOptions
    value={selected ?? usersSelected}
    noOptionsText="No results"
    onChange={(event, newValue) => {
      if(onChange) {
        onChange(newValue)
      } else {
        setUsersSelected(newValue)
      }
      setSearch(`${newValue?.firstName} ${newValue?.lastName} (${newValue?.koId})`)
    }}
    onInputChange={(event, newInputValue) => {
      if (!newInputValue) setUserList([])
      setSearch(newInputValue);
    }}
    renderInput={(params) => (
      <TextField {...params} label={label} fullWidth placeholder='Start typing to search...' />
    )}
    loading={searching}
    renderOption={(renderProps, option) => {
      return (<li {...renderProps}>
        <Grid container alignItems="center">
          <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
            {option && <Typography variant="body2" color="text.secondary">
              {getOptionLabel(option)}
            </Typography>}
          </Grid>
        </Grid>
      </li>
      );
    }}
  />

}