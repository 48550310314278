import React from "react";
import { IconButton, Typography, Box } from "@mui/material";
import { Edit, Delete, Add } from "@mui/icons-material";

export default function ArticleControls(props = {}) {

  const {
    hovering,
    onClickAdd,
    onClickEdit,
    onClickDelete,
    layout = 'horizontal',
    isDraft,
    showAdd,
  } = props

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: layout === 'vertical' ? 'column' : 'row',
      alignItems: layout === 'vertical' ? 'flex-end' : 'flex-start',
      gap: 1
    }} className="top-controls">
      {isDraft && <Box className="draft-label">
        <Typography variant="body2">
          DRAFT
        </Typography>
      </Box>}
      <Box sx={{ display: hovering ? 'flex' : 'none' }}
        className="edit-delete-icons">
        <div className="article-controls-backdrop">
          {showAdd && <IconButton
            onMouseDown={event => event.stopPropagation()}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onClickAdd()
            }}
            sx={{ display: hovering ? 'flex' : 'none' }}
            size='small'
            className="icon-button">
            <Add />
          </IconButton>}
          <IconButton
            onMouseDown={event => event.stopPropagation()}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onClickEdit()
            }}
            size='small'
            className="icon-button">
            <Edit />
          </IconButton>
          <IconButton
            onMouseDown={event => event.stopPropagation()}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onClickDelete()
            }}
            size='small'
            className="icon-button">
            <Delete />
          </IconButton>
        </div>
      </Box>

    </Box>
  )
}