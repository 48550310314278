import { default as React, useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { OutlinedInput, Grid, Paper, FormControl, InputLabel, Select, MenuItem, Typography, Button } from '@mui/material'
import RoleContext from '../auth/RoleContext'
import { getRequestConfig } from '../auth/auth'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import { toFormattedDate, DataItem, WorkflowStatuses, getLastPublishedLabel, HIGHLIGHT_HEX } from './common'

const approvalStatuses = [
  { label: "Full", value: 'FULL', },
  { label: "Conditional", value: 'CONDITIONAL', },
  { label: "Deauthorized", value: 'DEAUTHORIZED', },
]
export default function AuthorizationDetailCard(props = {}) {

  const { mfrProfile, authorization, readOnly } = props

  const [parent, setParent] = useState({})

  const authorizationLoaded = useMemo(() => {
    return authorization.id ? true : false
  }, [authorization])

  let navigate = useNavigate()

  const loadFilesPage = () => {
    navigate(`/facility-authorizations/${authorization.id}/files`)
  }

  useEffect(() => {
    const getAuthorizationById = async (id) => {
      const requestConfig = await getRequestConfig()
      const search = {
        id: `${id}`
      }
      const basePath = `${ApiGateway.authorizations}/search`
      const response = await axios.post(basePath, search, requestConfig)
      if (response.data?.results?.length) {
        const _parent = response.data?.results[0]
        setParent(_parent)
      }
    }
    if (authorization.parentId) {
      getAuthorizationById(authorization.parentId)
    }
    setParent({})
  }, [authorization.parentId])

  const visibilityFlags = useMemo(() => {
    const parentLoaded = authorization?.parentId && parent.id ? true : false
    const hasParent = authorization?.parentId ? true : false
    const isDraft = authorization.workflowStatus === WorkflowStatuses.DRAFT
    const isPublished = authorization.workflowStatus === WorkflowStatuses.PUBLISHED
    const isHistorical = authorization.isHistorical
    const showLastPublished = hasParent && isDraft ? true :
      isPublished ? true : false
    const lastPublished = hasParent && isDraft ? getLastPublishedLabel(parent.publishedDate, parent) :
      !hasParent && isDraft ? 'Never' :
        isPublished ? getLastPublishedLabel(authorization.publishedDate, authorization) : ''
    const lastPublishedLoaded = hasParent && isDraft ? parentLoaded :
      !hasParent && isDraft && authorization.id ? true :
        isPublished && authorization.id ? true : false
    return {
      parentLoaded,
      hasParent,
      isDraft,
      isPublished,
      isHistorical,
      showLastPublished,
      lastPublished,
      lastPublishedLoaded,
    }
  }, [authorization, parent])

  const {
    parentLoaded,
    hasParent,
    isDraft,
    isPublished,
    isHistorical,
    showLastPublished,
    lastPublished,
    lastPublishedLoaded,
  } = visibilityFlags

  return (
    <RoleContext.Consumer>
      {roleCtx => (


        <Paper elevation={1} sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', margin: '1em auto', padding: "1em" }}>
          <Grid container columnGap={1} rowGap={2} spacing={1}>

            {/* Facility */}
            <Grid item xs={3}>
              <DataItem
                label='Facility'
                value={<>
                  <Typography fontWeight='regular'>{authorization?.Manufacturer?.manufacturerName}</Typography>
                  <Typography fontWeight='regular'>{authorization?.Manufacturer?.manufacturerAddr}</Typography>
                  <Typography fontWeight='regular'>{authorization?.Manufacturer?.manufacturerCity}, {authorization?.Manufacturer?.manufacturerState} {authorization?.Manufacturer?.manufacturerZip}</Typography>
                  <Typography fontWeight='regular'>{mfrProfile?.manufacturerPhone}</Typography>
                </>}
                layout='vertical'
                loading={!authorization?.id}
              />
            </Grid>

            {/* Plant Manager */}
            <Grid item xs={4}>
              <DataItem
                label='Plant Manager'
                value={<>
                  <Typography fontWeight='regular'>{mfrProfile?.plantManagerName}</Typography>
                  <Typography fontWeight='regular'>{mfrProfile?.plantManagerEmail}</Typography>
                  <Typography fontWeight='regular'>{mfrProfile?.plantManagerPhone}</Typography>
                </>}
                layout='vertical'
                loading={!mfrProfile?.id}
              />

            </Grid>

            {/* Workflow */}
            <Grid item xs={4}>
              <DataItem
                fontWeight='bold'
                label='Workflow Status'
                value={authorization.workflowStatus}
                layout='horizontal'
                loading={!authorizationLoaded}
                sx={{
                  marginBottom: '10px'
                }}
              />

              {/* Issued date */}
              <DataItem
                label='Issued Date'
                value={authorization?.issuedDate ? toFormattedDate(authorization?.issuedDate) : 'Not Issued'}
                layout='horizontal'
                loading={!authorizationLoaded}
              />

              {/* Last Published */}
              {showLastPublished && <DataItem
                label='Last Published'
                value={lastPublished}
                layout='horizontal'
                loading={!lastPublishedLoaded}
              />}

              {/* Is Historical */}
              {authorization.isHistorical && <DataItem
                label='Historical Letter'
                value={'Yes'}
                layout='horizontal'
                loading={!authorizationLoaded}
              />}

            </Grid>

            {/* Final approval status */}

            {/* approvalStatusHighlighted */}

            <Grid sx={{ maxHeight: '70px' }} item xs={3}>
              {readOnly &&
                <DataItem
                  label='Final Approval Status'
                  value={authorization.approvalStatus}
                  layout='vertical'
                  loading={!authorization?.id}
                  sx={{
                    '& div:nth-child(2)': {
                      backgroundColor: props.approvalStatusHighlighted === true ? `${HIGHLIGHT_HEX} !important` : 'white',
                      borderRadius: '6px',
                      paddingTop: '4px',
                      paddingBottom: '4px',
                      maxWidth: '160px'
                    },
                  }}
                />}

              {!readOnly && authorization?.id && <FormControl fullWidth size='small'>
                <InputLabel>Final Approval Status</InputLabel>
                {authorization?.id && <Select
                  disabled={readOnly}
                  variant='outlined'
                  sx={{
                    '& ': {
                      backgroundColor: props.approvalStatusHighlighted === true ? `${HIGHLIGHT_HEX} !important` : 'white',
                    },
                    "& fieldset": readOnly ? { border: 'none' } : undefined,
                    height: '34px', fontSize: '0.875rem'
                  }}
                  fullWidth
                  size='small'
                  name="approvalStatus"
                  value={props.approvalStatus || ''}
                  onChange={props.onChangeApprovalStatus}
                  input={readOnly ? <OutlinedInput sx={{ fontSize: '0.875rem', }} /> : undefined}
                  IconComponent={readOnly ? () => null : undefined}
                >
                  {approvalStatuses.map(a =>
                    <MenuItem key={a.value} value={a.value}>
                      <span style={{ fontSize: '0.875rem' }}>{a.label}</span>
                    </MenuItem>)}
                </Select>}
              </FormControl>}
            </Grid>

            {/* Authorization Letter */}
            {(authorization.AuthorizationFile?.id || (isDraft && isHistorical) || isPublished) && !props.hideLetterLink && <Grid item xs={3}>
              <Button variant='text' onClick={loadFilesPage} ><Typography fontWeight='regular' textTransform={'initial'}> Authorization Letter</Typography></Button>
            </Grid>}
          </Grid>
        </Paper>
      )}
    </RoleContext.Consumer>
  )
}