import { default as React, useContext, useEffect, useState, useMemo, useCallback } from 'react'
import { Skeleton, IconButton, Button, Stack, Paper, Grid, Typography } from '@mui/material'
import { Factory, Add } from '@mui/icons-material'
import Page from '../layout/Page'
import { useParams, useLocation, useNavigate, useBlocker } from 'react-router-dom'
import RoleContext from '../auth/RoleContext';
import { ROLES } from '../auth/role';
import EventBus from '../utils/EventBus';
import SecondaryArticles from './SecondaryArticles'
import TertiaryArticles from './TertiaryArticles'
import { getPageListCategories, getArticles, getArticleDetail } from './data'
import { default as bus } from '../utils/EventBus'
import Event from '../utils/EventNames.js';

export default function OUCornerPage(props = {}) {
  const roleDetails = useContext(RoleContext)
  const canEdit = roleDetails.hasRole([ROLES.QSE_SAFETY_MANAGER, ROLES.ADMIN])

  const [isLoading, setIsLoading] = useState(true)
  const [loadingHighlightedArticles, setLoadingHighlightedArticles] = useState(true)
  const [loadingArticles, setLoadingArticles] = useState(true)
  const [loadingArticlesByCateogory, setLoadingArticlesByCateogory] = useState(true)
  const [editMode, setEditMode] = useState(true)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [categories, setCategories] = useState([])
  const [articlesByCategory, setArticlesByCategory] = useState({})
  const [highlightedArticles, setHighlightedArticles] = useState([])
  const [isValidForSave, setIsValidForSave] = useState(false)

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    EventBus.on(Event.START_PAGE_LOADING, startLoading)
    EventBus.on(Event.STOP_PAGE_LOADING, stopLoading)
    return () => {
      EventBus.removeListener(Event.START_PAGE_LOADING, startLoading)
      EventBus.removeListener(Event.STOP_PAGE_LOADING, stopLoading)
    }
  }, [])

  const initializeArticlesByCateogry = useCallback(async (_categories = []) => {
    setLoadingArticlesByCateogory(true)
    const _articles = {}
    for (const category of _categories) {
      _articles[category] = _articles[category] || []
      const pages = await getArticles({
        ouCorner: true,
        ouCornerCategory: category
      })
      for (const newsPost of pages.filter(r => r.promotionKind === 'newsPost' || r['@odata.type'] === '#microsoft.graph.newsLinkPage')) {
        if (newsPost['@odata.type'] === '#microsoft.graph.newsLinkPage') {
          _articles[category].push({
            title: newsPost.title,
            author: newsPost.createdBy.user.displayName,
            date: new Date(newsPost.createdDateTime).toLocaleDateString(),
            image: newsPost.bannerImageWebUrl,
            isDraft: newsPost.publishingState.level
          })
        } else {
          const articleDetail = await getArticleDetail(newsPost.id)
          _articles[category].push(articleDetail)
        }
      }
    }

    setArticlesByCategory(_articles)
    setLoadingArticlesByCateogory(false)
  }, [])
  
  const initializeHighlightedArticles = useCallback(async () => {
    setLoadingHighlightedArticles(true)
    const pages = await getArticles({
      highlighted: true,
      ouCorner: true
    })
    const _articles = []
    for (const newsPost of pages.filter(r => r.promotionKind === 'newsPost' || r['@odata.type'] === '#microsoft.graph.newsLinkPage')) {
      if (newsPost['@odata.type'] === '#microsoft.graph.newsLinkPage') {
        _articles.push({
          title: newsPost.title,
          author: newsPost.createdBy.user.displayName,
          date: new Date(newsPost.createdDateTime).toLocaleDateString(),
          image: newsPost.bannerImageWebUrl,
          isDraft: newsPost.publishingState.level
        })
      } else {
        const articleDetail = await getArticleDetail(newsPost.id)
        _articles.push(articleDetail)
      }
    }
    setHighlightedArticles(_articles)
    setLoadingHighlightedArticles(false)
  }, [])

  useEffect(() => {
    (async () => {
      const _categories = await getPageListCategories('QSEPortalOUCornerCategory')
      setCategories(_categories)
    })()
  }, [])

  useEffect(() => {
    if(categories.length) {
      initializeArticlesByCateogry(categories)
    }
  }, [initializeArticlesByCateogry, categories])

  useEffect(() => {
    initializeHighlightedArticles()
  }, [initializeHighlightedArticles])

  useEffect(() => {
    const init = () => initializeArticlesByCateogry(categories)
    EventBus.on(Event.REFRESH_PAGE, init)
    EventBus.on(Event.REFRESH_PAGE, initializeHighlightedArticles)
    return () => {
      EventBus.removeListener(Event.REFRESH_PAGE, init)
      EventBus.removeListener(Event.REFRESH_PAGE, initializeHighlightedArticles)
    }
  }, [initializeHighlightedArticles, initializeArticlesByCateogry, categories])

  const breadcrumb = <div><span>Safety OU Corner</span></div>

  const initializePage = async () => {
    startLoading()
    // 
    stopLoading()
  }

  useEffect(() => {
    initializePage()
  }, [])


  const Heading = (props = {}) => {

    return (
      <Grid container gap={0}>
        <Grid item xs={8}>
          <Typography variant='h6'>{props.title}</Typography>
        </Grid>
        {/* <Grid item xs={4}>
          <Stack direction="row-reverse" gap={1}>
            <Button size='small'>Sort By</Button>
            <Button size='small'>Filter By</Button>
          </Stack>
        </Grid> */}
      </Grid>
    )

  }

  const ArticlesSkeletons = () => {
    return <div style={{ display: 'flex', gap: '8px' }} className='fit-height'>
      {[1, 2, 3].map(i => <Skeleton className='fit-height' sx={{ flexGrow: 1 }} />)}
    </div>
  }

  return (
    <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading}>

      <Paper
        component="form"
        sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', margin: '1em auto' }}
      >
        <Grid container gap={1}>
          <Grid item xs={12}>
            <Heading title='Highlighted' />
          </Grid>

          <Grid item xs={12} sx={{ height: '200px' }}>
            {loadingHighlightedArticles && <ArticlesSkeletons />}
            {!loadingHighlightedArticles && <SecondaryArticles
              editable={canEdit}
              maxWidth='33%'
              articles={highlightedArticles}
              layout='horizontal'
              ouCorner
              highlighted
            />}
          </Grid>

          {categories.map(category => {
            return <>
              <Grid item xs={12}>
                <Heading title={category} />
              </Grid>
              <Grid item xs={12} sx={{
                height: articlesByCategory[category]?.length > 0 || loadingArticlesByCateogory ? '200px' : undefined
              }}>
                {loadingArticlesByCateogory && <ArticlesSkeletons />}
                {!loadingArticlesByCateogory && <SecondaryArticles
                  editable={canEdit}
                  maxWidth='33%'
                  articles={articlesByCategory[category]}
                  layout='horizontal'
                  ouCorner
                  ouCornerCategory={category}
                />}
              </Grid>
            </>
          })}
        </Grid>
      </Paper>
    </Page>
  )
}