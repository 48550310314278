import { SafetyHubSharepoint } from "../config/config-current"
import { createArticleFromTemplate } from "./data"

export const createArticle = async (name, template, fields, beforeHook, afterHook) => {
  beforeHook()
  const body = {
    "@odata.type": "#microsoft.graph.sitePage",
    "description": name,
    "name": name,
    "title": name,
    "pageLayout": "article",
    "promotionKind": "newsPost",
    "showComments": false,
    "showRecommendedPages": false,
  }
  const article = await createArticleFromTemplate(template, body, fields)
  afterHook()
  editArticle(article)
}

export const editArticle = (article) => {
  window.open(article?.webUrl + '?Mode=Edit', '_blank')
}

export const viewArticle = (article) => {
  window.open(article?.webUrl + '?env=Embedded', '_blank')
}

export function removeODataContext(obj) {
  if (Array.isArray(obj)) {
    return obj.map(removeODataContext);
  } else if (obj && typeof obj === 'object') {
    const result = {};
    for (const [key, value] of Object.entries(obj)) {
      if (!key.endsWith('@odata.context') && key !== 'serverProcessedContent') {
        result[key] = removeODataContext(value);
      }
    }
    return result;
  }
  return obj; // primitive value, return as-is
}

export function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}