export const domain = 'https://mfi-api.dev.tis.coke.com'
export const azureClientId = 'c42a14e9-49d7-4f22-9eca-f28e500d270b'
export const azureTenantId = 'eb36d020-2c35-44de-bc7a-554cc979b26d'
export const apiKey = 'SyvAFAUua29HhpuJCtRRt7xwYNAIAp9o4hApYNLB'
export const stage = 'dev'
export const featureFlags = {
  '764709': true,
  '792789': true,
  '1896027': true,
  authorization: true,
  cpkBottledWater: true,
  mfi: true,
  facman: true,
  facAuthQseUpdates: true,
  contactMgmtAdmin: true,
  contactMgmtBottler: true,
  exposeAuthorizationLetter: true,
  safetyHub: true,
}
export const SafetyHubSharepoint = {
  siteId: 'groups.coca-cola.com,18f2d0a2-e373-4c4a-b16f-64378e8812b8,fceaa800-42ab-44b0-8014-8f7a0d40e45c',
  urls: {
    baseSite: 'https://groups.coca-cola.com/sites/NAOUQSE/safety_nonprod',
    createArticle: 'https://groups.coca-cola.com/sites/NAOUQSE/safety_nonprod/_layouts/15/CreatePageFromTemplate.aspx?source=FromWebpart&promotedState=1',
  },
  listIds: {
    pages: '80848ccd-dbe1-42f2-8b31-1af655fc2940',
    documents: 'e1031f5f-9507-444b-84a6-690bd6630e4c',
    documentLibrary: 'e1031f5f-9507-444b-84a6-690bd6630e4c'
  },
  templateIds: {
    news: 'ac8be6d2-658a-42ab-bb22-bccec11c8d47'
  }
}