import React from 'react';
import { Card, CardContent, Typography, Box, Avatar, Button, ButtonGroup, Grid } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Link} from 'react-router-dom'
import { Stack } from '@mui/system';
import { getFileTypeIconAsUrl } from '@fluentui/react-file-type-icons';
import { Image } from '@mui/icons-material';


const getFileIcon = (type = "") => {
  switch (type.toLowerCase()) {
    case 'docx':
      return <Avatar src={getFileTypeIconAsUrl({extension: '.docx'})} variant='square' />;
    case 'pdf':
      return <Avatar src={getFileTypeIconAsUrl({extension: '.pdf'})} variant='square' />;
    default:
      return <InsertDriveFileIcon />;
  }
};

function formatBytes(bytes) {
  if (!bytes) return '0 Bytes';

  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  const value = bytes / Math.pow(1024, i);
  const rounded = value.toFixed(i === 0 ? 0 : 1); // No decimals for Bytes

  return `${rounded} ${sizes[i]}`;
}

function openInNew(url) {
  window.open(url, '_blank')
}

const DocumentCard = (props = {}) => {
  const {
    title,
    size,
    preview,
    fileType,
    openUrl,
    downloadUrl,
    createdByUser
  } = props
  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', padding: 2, width: 300 }}>
      <Stack spacing={1}>
      <Typography variant="body1" fontWeight="bold" gutterBottom noWrap>
        {title}
      </Typography>
      
        <Grid container>
          <Grid item xs={8} display="flex" alignItems="center" columnGap={1}>
            {getFileIcon(fileType)}
            <Typography variant="body2" color="text.secondary">          
              {formatBytes(size)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <ButtonGroup orientation='horizontal'>
              { openUrl && <Button onClick={() => openInNew(openUrl)} title='Open in New Window' ><OpenInNewIcon /></Button>}
              {downloadUrl && <Button onClick={() => openInNew(downloadUrl)} title='Save...'><DownloadIcon /></Button>}
            </ButtonGroup>
          </Grid>
        </Grid>
      <Typography variant="body2" noWrap>
      Created By: {createdByUser.displayName}
    </Typography>
      <Typography variant="body2" color="text.secondary" whiteSpace={'pre-wrap'}>
        {preview}
      </Typography>
      </Stack>
    </Card>
  );
};

export default DocumentCard;
