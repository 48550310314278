import { default as React, useEffect, useState, useContext } from 'react'
import { getRequestConfig } from '../auth/auth'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'

//facility-management/manufacturer/{{manufacturerUuid}}/contacts/search
export default function TrendNotificationsCard(props = { manufacturerUuid: undefined, ownershipUuid: undefined }) {

  const [manufacturerContacts, setManufacturerContacts] = useState(null)
  const [ownershipContacts, setOwnershipContacts] = useState(null)

  const initializeContacts = async () => {

    const { manufacturerUuid, ownershipUuid } = props

    const murl = `${ApiGateway.facilityManagement}/manufacturer/${manufacturerUuid}/contacts/search`
    const ourl = `${ApiGateway.facilityManagement}/ownership/${ownershipUuid}/contacts/search`

    const requestConfig = await getRequestConfig()

    try {
      const results = await Promise.all([
        axios.post(murl, { manufacturerUuid }, requestConfig),
        axios.post(ourl, { ownershipUuid }, requestConfig)
      ])
      const mResults = results[0].data?.results ?? []
      const mTrendContacts = mResults.reduce((p, c) => {
        if (c.ManufacturerContactNotifications.some((n) => n.notificationType === 'trend')) {
          return [ ...p, c]
        }
        return p
      }, [])
      console.log(mTrendContacts)
      setManufacturerContacts(mTrendContacts)

      const oResults = results[1].data?.results ?? []
      const oTrendContacts = oResults.reduce((p, c) => {
        if (c.OwnershipContactNotifications.some((n) => n.notificationType === 'trend')) {
          return [ ...p, c]
        }
        return p
      }, [])
      setOwnershipContacts(oTrendContacts)

    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (props.manufacturerUuid && props.ownershipUuid) {
      initializeContacts()
    }
  }, [props.manufacturerUuid, props.ownershipUuid])

  return (
    <>
    {manufacturerContacts && ownershipContacts && 
    <Grid container>
      <Grid item xs={12} padding={'3px'}>
        <Typography fontWeight='bold' color="text.secondary" >Full List</Typography>
        <Box>
        <Typography variant='body2' padding='3px'>
          {
            Array.from(new Set([...manufacturerContacts, ...ownershipContacts].map((c) => {
              if (c.name) return `${c.name} <${c.email}>`
              return c.email
            }))).join('; ')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} padding={'3px'}>
        <Typography fontWeight='bold' color="text.secondary" >Facility Contacts</Typography>
        <Box>
        <Typography variant='body2' padding='3px'>
          {manufacturerContacts.filter(c => c.isInternal === 0).map((c) => {
            if (c.name) return `${c.name} <${c.email}>`
            return c.email
          }).join('; ')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} padding={'3px'}>
        <Typography fontWeight='bold' color="text.secondary" >Coca-Cola Contacts - Facility</Typography>
        <Box>
          <Typography variant='body2' padding='3px'>
          {manufacturerContacts.filter(c => c.isInternal === 1).map((c) => {
            if (c.name) return `${c.name} <${c.email}>`
            return c.email
          }).join('; ')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} padding={'3px'}>
        <Typography fontWeight='bold' color="text.secondary" >Ownership Contacts</Typography>
        <Box>
        <Typography variant='body2' padding='3px'>
          {ownershipContacts.filter(c => c.isInternal === 0).map((c) => {
            if (c.name) return `${c.name} <${c.email}>`
            return c.email
          }).join('; ')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} padding={'3px'}>
        <Typography fontWeight='bold' color="text.secondary" >Coca-Cola Contacts - Ownership</Typography>
        <Box>
          <Typography variant='body2' padding='3px'>
          {ownershipContacts.filter(c => c.isInternal === 1).map((c) => {
            if (c.name) return `${c.name} <${c.email}>`
            return c.email
          }).join('; ')}
          </Typography>
        </Box>
      </Grid>
    </Grid>
    }
    </>
  )
}