import { default as React, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { LoadingButton } from '@mui/lab';
// import ExportCsv from '../Utils/ExportCsv.js';
import {FilterList, EditOutlined, Cancel} from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import DownloadIcon from '@mui/icons-material/Download'
import HelpIcon from '@mui/icons-material/Help'
import RestoreIcon from '@mui/icons-material/Restore'
import { Link } from 'react-router-dom';
import HelpDialog from '../help/HelpDialog';
import ComplaintTrendsHelp from '../help/pages/complaint-trends/ComplaintTrendsHelp';

export default function ListViewCommandBar (props = {}) {
  const navigate = useNavigate()
  const [filterButtonLabel, setFilterButtonLabel] = useState('Show Filters')

  const toggleFilters = () => {
    props.toggleFilters()
    //props.listView.toggleFilters()
    setFilterButtonLabel(filterButtonLabel === 'Show Filters' ? 'Hide Filters' : 'Show Filters')
  }

  const toggleHelp = () => {
    navigate(props.helpPage)
  }

  const helpPageDialog = () => {

  }

  return (
    <div className="tis-commandbar tis-commandbar-listview">
      {props.helpPage && <LoadingButton loading={props.loading} onClick={toggleHelp}><HelpIcon/></LoadingButton>}
      {props.helpPageDialog}
      {(props.showFilters === undefined || props.showFilters) && <LoadingButton loading={props.loading} variant="contained" onClick={toggleFilters}>{filterButtonLabel}&nbsp;&nbsp;<FilterList/></LoadingButton>}
      {/* {(props.showAdd === undefined || props.showAdd) && props.listView && <Link to={props.listView.getAddItemUrl()}>
        <Button variant="outlined">Add&nbsp;&nbsp;<AddIcon/></Button>
      </Link>} */}
      {props.showEdit && <LoadingButton loading={props.loading} variant="outlined" onClick={props.onEdit} endIcon={<EditOutlined />}>Edit</LoadingButton>}
      {(props.showCancel === undefined || props.showCancel) && <LoadingButton loading={props.loading} variant="outlined" onClick={props.onCancel} endIcon={<Cancel />}>Cancel</LoadingButton>}
      {(props.showAdd === undefined || props.showAdd) && <LoadingButton loading={props.loading} variant="outlined" onClick={props.addRecord}>{props.addRecordLabel ?? 'Add'}&nbsp;&nbsp;<AddIcon/></LoadingButton>}
      {(props.showResetList) && <LoadingButton loading={props.loading} variant="contained" onClick={props.onClickReset}>Reset Table&nbsp;&nbsp;<RestoreIcon/></LoadingButton>}
      {props.showExport && <LoadingButton loading={props.loading} variant="outlined" onClick={props.onExport}>Export&nbsp;&nbsp;<DownloadIcon /></LoadingButton>}
    </div>
  )
}
